import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Files from "react-butterfiles";
import _ from "lodash";
import { uploadReceipt } from "./Axios";

const UploadReceiptForm = ({ purchase }) => {
  const [modal, setModal] = useState(false);
  const [receipt, setReceipt] = useState({});
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setErrors([]);
    setSubmitting(false);
    setReceipt({});
  };

  const handleSubmit = () => {
    setSubmitting(true);
    let formData = new FormData();
    formData.append("purchase[transfer_receipt]", receipt.src.file);
    formData.append("purchase[id]", purchase.id);

    uploadReceipt(formData, response => {
      if (response.status == "200") {
        window.location.reload();
      }
    });
  };

  const handleReceipt = file => {
    setErrors([]);
    setReceipt(file);
  };

  const drawFileErrorText = error => {
    switch (error) {
      case "multipleMaxCountExceeded":
        return "Excedió el máximo de archivos permitidos!";
      case "multipleMaxSizeExceeded":
        return "Exedió el tamaño max. permitido entre todos los archivos!";
      case "maxSizeExceeded":
        return "Excedió el tamaño max. del archivo!";
      case "unsupportedFileType":
        return "Este formato no está permitido!";
      default:
        break;
    }
  };

  return (
    <div>
      <Button color="primary" onClick={toggle} size="sm" id="purchaseFinalStep">
        Subir comprobante
      </Button>
      <Modal isOpen={modal} toggle={toggle} id="voucherLoadModal">
        <ModalHeader toggle={toggle}>
          Sube tu comprobante de transferencia!
        </ModalHeader>
        <ModalBody>
          <Files
            maxSize="2mb"
            accept={["application/pdf", "image/jpg", "image/jpeg"]}
            onSuccess={files => handleReceipt(files[0])}
            onError={errors => setErrors(errors)}
          >
            {({ browseFiles, getDropZoneProps, getLabelProps }) => (
              <>
                <div
                  {...getDropZoneProps({
                    style: {
                      maxWidth: 720,
                      minHeight: 80,
                      border: "2px lightgray dashed",
                    },
                  })}
                >
                  {_.isEmpty(receipt) ? (
                    <>
                      <p className="text-center">
                        Agrega o Arrastra el comprobante aquí
                      </p>
                      <p className="small text-center text-muted">
                        (jpg/jpeg/pdf - max. 2mb)
                      </p>
                    </>
                  ) : (
                    <p className="text-center">{receipt.name}</p>
                  )}
                </div>
                <div>
                  <ol>
                    {_.take(errors, 10).map((error, index) => {
                      return error.file ? (
                        <li key={error.file.name}>
                          {error.file.name} - {drawFileErrorText(error.type)}
                        </li>
                      ) : (
                        <li key={`error-${index}`}>
                          {drawFileErrorText(error.type)}
                        </li>
                      );
                    })}
                  </ol>
                </div>
                <div>
                  <Button
                    size="sm"
                    color="info"
                    outline
                    className="mt-2"
                    disabled={submitting}
                    onClick={browseFiles}
                  >
                    <i className="fas fa-plus" /> Agregar Comprobante
                  </Button>
                </div>
              </>
            )}
          </Files>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={submitting || _.isEmpty(receipt)}
            id="purchaseVoucherUpload"
          >
            {submitting ? "Enviando..." : "Enviar"}
          </Button>{" "}
          <Button color="danger" outline onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadReceiptForm;
