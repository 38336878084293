import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import FieldWrapper from "../commons/FiledWrapper";
import { saveSignatureCertificate } from "./Axios";

const EcertCertificateForm = ({ user, ecertCertificate }) => {
  const [modal, setModal] = useState(false);
  const [identificationNumber, setIdentificationNumber] = useState(user.number || "");
  const [firstName, setFirstName] = useState(ecertCertificate?.first_name || "");
  const [firstSurname, setFirstSurname] = useState(
    ecertCertificate?.first_surname || ""
  );
  const [lastSurname, setLastSurname] = useState(
    ecertCertificate?.last_surname || ""
  );
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (user.name) {
      setUserName();
    }
  }, []);

  const toggleModal = () => setModal(!modal);

  const setUserName = () => {
    if (ecertCertificate) return;
    const data = user.name.split(" ");
    if (data.length === 1) {
      setFirstName(data[0]);
    } else if (data.length === 2) {
      setFirstName(data[0]);
      setFirstSurname(data[1]);
    } else if (data.length === 3) {
      setFirstName(data[0]);
      setFirstSurname(data[1]);
      setLastSurname(data[2]);
    } else if (data.length === 4) {
      setFirstName(data[0]);
      setFirstSurname(data[2]);
      setLastSurname(data[3]);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if ([identificationNumber, firstName, firstSurname, lastSurname].includes("")) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    let formData = new FormData();
    if (ecertCertificate) {
      formData.append("id", ecertCertificate.id);
    }
    formData.append("ecert_signature_certificate[user_email]", user.email);
    formData.append("ecert_signature_certificate[identification_number]", identificationNumber);
    formData.append("ecert_signature_certificate[first_name]", firstName);
    formData.append("ecert_signature_certificate[first_surname]", firstSurname);
    formData.append("ecert_signature_certificate[last_surname]", lastSurname);

    saveSignatureCertificate(formData, response => {
      if ([200, 201].includes(response.status)) {
        window.location.reload();
      } else {
        setFormErrors(response.data);
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Button color="primary" size="sm" className="mr-1 mb-1" onClick={toggleModal}>
        <i className="fas fa-cart-plus mr-2"></i>
        Enrolar Ecert
      </Button>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal} className="bg-secondary text-white">
          <small className="d-block">
            Firma Electrónica Avanzada | FEA
          </small>
          <i className="fas fa-signature mr-2" />
          <span className="font-weight-bold">Enrolar Usuario en Ecert</span>
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <Alert color="info">
              <i className="fas fa-cart-plus fa-2x fa-pull-left"></i>
              <p>
                <strong>Enrolar a un usuario en Ecert tiene un costo adicional</strong>.{" "}
                Al hacer clic en enviar, estarás{" "}
                <strong>contratando un certificado por un año de FEA para el miembro</strong>.
              </p>
              <hr/>
              <p className="mb-0">
                Comunícate al correo{" "}
                <a href="mailto:ventas@controldoc.cl" className="font-weight-bold">ventas@4talent.cl</a>{" "}
                para consultar valores y formas de pago.
              </p>
            </Alert>
            {error && (
              <Alert color="danger">Todos los campos son obligatorios!</Alert>
            )}
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="required" for="number">
                    RUT
                  </Label>
                  <FieldWrapper errors={formErrors.identification_number}>
                    <Input
                      id="number"
                      name="number"
                      placeholder="RUT"
                      type="text"
                      onChange={e => setIdentificationNumber(e.target.value)}
                      value={identificationNumber}
                    />
                  </FieldWrapper>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="required" for="firstName">
                    Nombre
                  </Label>
                  <FieldWrapper errors={formErrors.first_name}>
                    <Input
                      id="firstName"
                      name="firstName"
                      placeholder="Nombre"
                      type="text"
                      onChange={e => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </FieldWrapper>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="required" for="firstSurname">
                    Apellido Paterno
                  </Label>
                  <FieldWrapper errors={formErrors.first_surname}>
                    <Input
                      id="firstSurname"
                      name="firstSurname"
                      placeholder="Apellido Paterno"
                      type="text"
                      onChange={e => setFirstSurname(e.target.value)}
                      value={firstSurname}
                    />
                  </FieldWrapper>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="required" for="lastSurname">
                    Apellido Materno
                  </Label>
                  <FieldWrapper errors={formErrors.last_surname}>
                    <Input
                      id="lastSurname"
                      name="lastSurname"
                      placeholder="Apellido Materno"
                      type="text"
                      onChange={e => setLastSurname(e.target.value)}
                      value={lastSurname}
                    />
                  </FieldWrapper>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal} color="secondary" outline>
              Cerrar
            </Button>
            <Button color="success" type="submit" disabled={submitting}>
              {submitting ? "Enviando..." : "Enviar"}
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default EcertCertificateForm;
