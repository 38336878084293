import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";

const AasmBagde = ({ aasmState, text, pill }) => {
  const [color, setColor] = useState("");

  useEffect(() => {
    handleBadgeColor();
  }, []);

  const handleBadgeColor = () => {
    switch (aasmState) {
      case "waiting_payment":
        setColor("warning");
        break;
      case "procesing_payment":
        setColor("primary");
        break;
      case "active":
        setColor("success");
        break;
      case "canceled":
      case "expired":
        setColor("danger");
        break;
      default:
        setColor("warning");
        break;
    }
  };

  return (
    <Badge color={color} pill={pill} className="text-white">
      {text}
    </Badge>
  );
};

export default AasmBagde;
