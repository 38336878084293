import React, { Component } from "react";
import ButtonColor from "./button_color";
import LazyComponent from "../lazy_component";
import ButterToast, { Cinnamon, POS_BOTTOM, POS_RIGHT } from "butter-toast";

export default class BrandConfigurationForm extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = {
      brand_color: props.brand_color,
      logo_url: props.logo_url,
      banner_url: props.banner_url,
      email: props.email,
      company_name: props.company_name,
      images: props.images
    };
  }

  handleNotification(data) {
    var { message, type } = data;
    var _title = type == "success" ? "¡Excelente!" : "¡Ha ocurrido en error!";
    var _type =
      type == "success"
        ? Cinnamon.Crisp.SCHEME_BLUE
        : Cinnamon.Crisp.SCHEME_RED;
    var icon = <i className="fas fa-info" />;

    ButterToast.raise({
      content: (
        <Cinnamon.Crisp
          scheme={_type}
          content={() => <span>{message}</span>}
          title={_title}
          icon={icon}
        />
      )
    });
  }

  handlerColor(color, target) {
    this.setState({ target: color, brand_color: color });
  }

  handleImageChange(event) {
    const target = event.target.id;
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        [target]: file,
        [`${target}_url`]: reader.result
      });
    };
    if (file != undefined) reader.readAsDataURL(file);
  }

  handlerSubmit(e) {
    const states = Object.keys(this.state);
    let formData = new FormData();
    let _this = this;

    states.forEach(function(state, index) {
      formData.append(`customer[${state}]`, _this.state[state]);
    });
    e.preventDefault();

    reqwest({
      url: `update_branding`,
      type: "json",
      method: "PATCH",
      headers: {
        "X-CSRF-TOKEN": $('[name="csrf-token"]')[0].content,
        enctype: "multipart/form-data"
      },
      data: formData,
      processData: false,
      success(response) {
        _this.handleNotification({
          message: "Cambios guardados correctamente",
          type: "success"
        });
      },
      error(response, status, err) {
        _this.handleNotification({
          message: "Por favor vuelve a intentar",
          type: "error"
        });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <ButterToast
            position={{ vertical: POS_BOTTOM, horizontal: POS_RIGHT }}
          />
        </div>
        <div>
          <div className="col-sm-12 col-md-9 d-flex justify-content-left align-items-center">
            <h3 className="text-primary font-weight-bold">Branding</h3>
          </div>

          <div className="card bg-white mb-3 mt-3">
            <div className="card-body">
              <h5 className="font-weight-bold mb-1">
                <i className="fas fa-images mr-2" />
                <span>Sugerencias</span>
              </h5>

              {/*
                <p className="text-muted mb-0 small">
                  <i className="fas fa-info-circle mr-2" />
                  <span>Banner: Dimensiones 600x148px</span>
                </p>
              */}

              <p className="text-muted mb-0 small">
                <i className="fas fa-info-circle mr-2" />
                <span>Logo: Dimensiones 250x250px</span>
              </p>

              <p className="text-muted mb-0 small">
                <i className="fas fa-info-circle mr-2" />
                <span>Formatos permitidos: png, jpg, jpeg</span>
              </p>
            </div>
          </div>
          <form onSubmit={this.handlerSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-7">
                <input
                  name="user[brand_color]"
                  type="hidden"
                  value={this.state.brand_color}
                />
                <div className="card profile-card">
                  {/*
                    <div className="card-img-block">
                      <img
                        className="img-fluid"
                        src={this.state.banner_url}
                        alt="banner"
                        width="100%"
                      />
                    </div>
                  */}
                  <div className="card-body">
                    {/*
                      <div className="image-cropper">
                        <img
                          src={this.state.logo_url}
                          alt="logo"
                          className="profile-pic"
                        />
                      </div>
                      <div className="circle" />
                    */}
                    <div className="image-cropper">
                      <img
                        src={this.state.logo_url}
                        alt="logo"
                        className="profile-pic"
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <h5 className="text-left font-weight-bold mt-2">
                        Estimado(a) {this.state.company_name}
                      </h5>
                      <p className="mt-3">
                        Esta previsualización corresponde al formato de los
                        correos que saldrán desde la plataforma{" "}
                        <span style={{ color: this.state.brand_color }}>
                          Controldoc.legal
                        </span>
                      </p>
                      <p className="mb-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </div>
                  </div>
                  <div
                    className="pt-4 pb-3"
                    style={{
                      color: "white",
                      backgroundColor: this.state.brand_color
                    }}
                  >
                    <p className="text-center pt-2 pb-1">
                      Este mensaje ha sido generado por un sistema de correos
                      automáticos.
                      <br />
                      No debe responderlo.
                    </p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 text-center">
                    <input
                      className="inputfile input-primary"
                      type="file"
                      name="user[logo]"
                      id="logo"
                      onChange={this.handleImageChange}
                    />
                    <label
                      className="fileLabel"
                      style={{ width: "100%" }}
                      htmlFor="logo"
                    >
                      <i className="fas fa-upload mr-2" />
                      <span>Cambiar Logo</span>
                    </label>
                  </div>
                  {/*
                    <div className="col-6 text-center">
                      <input
                        className="inputfile input-primary"
                        type="file"
                        name="user[banner]"
                        id="banner"
                        onChange={this.handleImageChange}
                      />
                      <label
                        className="fileLabel"
                        style={{ width: "100%" }}
                        htmlFor="banner"
                      >
                        <i className="fas fa-upload mr-2" />
                        <span>Cambiar Banner</span>
                      </label>
                    </div>
                  */}
                </div>
              </div>
              <div className="col-sm-12 col-md-5">
                <div className="card pb-3">
                  <ButtonColor
                    target="user[brand_color]"
                    color={this.state.brand_color}
                    onChange={this.handlerColor}
                    handlerColor={this.handlerColor}
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              <button className="btn btn-success btn-block" type="submit">
                Guardar
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
