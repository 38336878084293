/** @format */

import React, { useState } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import _ from "lodash";
import DocumentTypeItem from "./Item";
import DocumentTypesForm from "./Form";

const DocumentTypesIndex = props => {
  const { documentCategories, companySigners, canAddNewType, groupOptions } =
    props;

  const [documentTypes, setDocumentTypes] = useState(props.documentTypes);

  const handleAddDocumentType = data => {
    const newDocumentTypes = [...documentTypes, data];
    setDocumentTypes(newDocumentTypes);
  };

  return (
    <Card className="mt-3">
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Categoría</th>
              <th>Orden de firma por defecto?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_.map(documentTypes, documentType => (
              <DocumentTypeItem
                type={documentType}
                key={`document-type-${documentType.id}`}
                documentCategories={documentCategories}
                companySigners={companySigners}
                groupOptions={groupOptions}
              />
            ))}
          </tbody>
        </Table>
        {canAddNewType && (
          <Row>
            <Col md={{ size: "auto", offset: 9 }}>
              <DocumentTypesForm
                buttonColor="success"
                buttonLabel="Nuevo tipo de documento"
                modalTitle="Nuevo tipo de documento"
                documentCategories={documentCategories}
                handleAddDocumentType={handleAddDocumentType}
                companySigners={companySigners}
                groupOptions={groupOptions}
              />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default DocumentTypesIndex;
