/** @format */

import React from "react";
import Documents from "documents-controldoc";
import _ from "lodash";

const HiringDocuments = (props) => {
  const {
    documents,
    document_types,
    signer_types,
    person_email,
    company_email,
    companySigners,
    canAddDocuments,
    formFor,
    form_name,
  } = props;
  const _documents = [];

  for (var key in documents) {
    if (documents[key]["_destroy"] != undefined) {
      documents[key]["_destroy"] = JSON.parse(documents[key]["_destroy"]);
    }
    _documents.push(documents[key]);
  }

  return (
    <Documents
      documents={_documents}
      document_types={document_types}
      signer_types={signer_types}
      person_email={person_email}
      company_email={company_email}
      form_name={form_name}
      formFor={formFor}
      companySigners={companySigners}
      canAddDocuments={canAddDocuments}
    />
  );
};

export default HiringDocuments;
