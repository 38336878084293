import axios from "axios";

export const enableCustomPin = (customPinEnabled, data, callback) => {
  var promise = axios({
    method: "POST",
    url: `/users/${customPinEnabled ? "disable" : "enable"}_custom_pin.json`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
