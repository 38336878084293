/** @format */

import React, { useState, useCallback, Fragment } from "react";
import Select from "react-select";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import update from "immutability-helper";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
} from "reactstrap";
import Switch from "react-switch";
import { saveDocumentType } from "./Axios";
import FieldWrapper from "../commons/FiledWrapper";
import SignerForm from "./SignerForm";

const DocumentTypesForm = props => {
  const {
    buttonColor,
    buttonLabel,
    modalTitle,
    buttonSize,
    documentType,
    documentCategories,
    handleAddDocumentType,
    companySigners,
    outline,
    updateItem,
    groupOptions,
  } = props;

  const [modal, setModal] = useState(false);
  const [name, setName] = useState(documentType ? documentType.name : "");
  const [category, setCategory] = useState(
    documentType
      ? {
          value: documentType.document_category.id,
          label: documentType.document_category.name,
        }
      : documentCategories[0]
  );
  const [defaultSignersOrder, setDefaultSignersOrder] = useState(
    documentType ? documentType.default_signers_order : false
  );
  const [errors, setErrors] = useState({});
  const [signers, setSigners] = useState(
    documentType ? documentType.signers_order : []
  );

  const [groups, setGroups] = useState(
    _.filter(groupOptions, function (option) {
      return _.includes(
        _.map(documentType?.groups, "id").map(String),
        String(option.value)
      );
    })
  );

  const [groupExclusivity, setGroupExclusivity] = useState(
    documentType?.group_exclusivity || false
  );

  const [tooltipExclusivity, setTooltipExclusivity] = useState(false);

  const toggleTooltipExclusivity = () =>
    setTooltipExclusivity(!tooltipExclusivity);

  const toggle = () => setModal(!modal);

  const cleanForm = () => {
    setName("");
    setCategory(documentCategories[0]);
    setDefaultSignersOrder(false);
    setSigners([]);
  };

  const handleSubmit = () => {
    if (defaultSignersOrder && signers.length < 2) {
      const newErrors = { ...errors };
      newErrors.signers = [
        "Debe agregar al menos un firmante más aparte del Trabajador!",
      ];
      setErrors(newErrors);
      return;
    }

    let data = new FormData();
    if (documentType) {
      data.append("document_type[id]", documentType.id);
    }
    data.append("document_type[name]", name);
    data.append("document_type[document_category_id]", category.value);
    data.append("document_type[default_signers_order]", defaultSignersOrder);
    data.append("document_type[group_exclusivity]", groupExclusivity);

    if ((groups || []).length > 0 && category.label === "Trabajador") {
      _.forEach(groups, function (_group) {
        data.append("document_type[group_ids][]", _group.value);
      });
    } else {
      data.append("document_type[group_ids][]", "");
    }

    if (defaultSignersOrder) {
      _.map(signers, (signer, index) => {
        data.append(`document_type[signers_order][][email]`, signer.email);
        data.append(`document_type[signers_order][][type]`, signer.type);
        data.append(`document_type[signers_order][][rol]`, signer.rol);
        data.append(`document_type[signers_order][][id]`, signer.id);
        data.append(`document_type[signers_order][][order]`, index);
      });
    }

    saveDocumentType(data, response => {
      if (response.status === 201) {
        handleAddDocumentType(response.data);
        cleanForm();
        toggle();
        setErrors([]);
      } else if (response.status === 200) {
        updateItem(response.data);
        toggle();
        setErrors([]);
      } else if (response.status === 422) {
        setErrors(response.data);
      }
    });
  };

  const handleChangeDefaultSignersOrder = e => {
    if (e) {
      handleAddDefaultSigners();
    } else {
      setSigners([]);
    }
    setDefaultSignersOrder(e);
  };

  const handleChangeCategory = e => {
    if (e.label === "Empresa") {
      handleChangeDefaultSignersOrder(false);
    }
    setCategory(e);
  };

  // ------------------ React Dnd -----------------------
  const handleMoveSigner = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSigner = signers[dragIndex];
      setSigners(
        update(signers, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragSigner],
          ],
        })
      );
    },
    [signers]
  );

  const handleAddSigner = () => {
    const newSigners = [...signers];
    newSigners.push({
      email: "",
      type: "company",
      rol: "Firmante",
      index: Math.random() * 100,
      id: null,
    });
    setSigners(newSigners);
  };

  const handleAddDefaultSigners = () => {
    if (signers.length === 0) {
      const newSigners = [
        {
          email: companySigners[0].value,
          type: "company",
          rol: "Firmante",
          index: Math.random() * 100,
          id: companySigners[0].id,
        },
        {
          email: "",
          type: "person",
          rol: "Firmante",
          index: Math.random() * 100,
          id: null,
        },
      ];
      setSigners(newSigners);
    }
  };

  const handleUpdateSigner = (index, field, e) => {
    const newSigners = [...signers];
    switch (field) {
      case "email":
        newSigners[index].email = e.value;
        newSigners[index].id = e.id;
        break;
      case "rol":
        newSigners[index].rol = e.target.value;
        break;
      default:
        break;
    }

    setSigners(newSigners);
  };

  const handleRemoveSigner = index => {
    const newSigners = [...signers];
    newSigners.splice(index, 1);
    setSigners(newSigners);
  };

  const drawSigners = () => {
    return (
      <Fragment>
        <p className="mb-3">
          Nota: Aquí puede ordenar los firmantes según su necesida y agregar
          más.
        </p>
        <FieldWrapper errors={errors.signers}>
          <DndProvider backend={HTML5Backend}>
            {_.map(signers, (signer, index) => (
              <SignerForm
                key={`signer-${signer.index}`}
                signer={signer}
                signerIndex={index}
                handleMoveSigner={handleMoveSigner}
                companySigners={companySigners}
                handleUpdateSigner={handleUpdateSigner}
                handleRemoveSigner={handleRemoveSigner}
              />
            ))}
          </DndProvider>
        </FieldWrapper>
        <Button
          type="button"
          color="dark"
          size="sm"
          name="add_signer"
          onClick={handleAddSigner}
        >
          <i className="fas fa-user" /> &nbsp; Añadir firmante
        </Button>
      </Fragment>
    );
  };

  const handleGroups = e => {
    if ((e || []).length == 0) {
      setGroupExclusivity(false);
    }

    setGroups(e);
  };

  const drawGroupSelect = () => {
    if (category.label === "Trabajador") {
      return (
        <Row className="mt-1 align-middle">
          <Col md="12">
            <Label className="label-bold ml-1">Grupos</Label>

            <Select
              className="basic-single"
              classNamePrefix="select"
              // defaultValue={documentCategories[0]}
              value={groups}
              name="group_ids"
              options={groupOptions}
              isMulti
              onChange={e => handleGroups(e)}
            />
          </Col>
        </Row>
      );
    }
  };

  const drawGroupExclusivitySwitch = () => {
    return (
      <Row>
        <Col>
          <Switch
            checked={groupExclusivity}
            onChange={e => setGroupExclusivity(e)}
            onColor="#5a3ee0"
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={35}
            className="react-switch mt-4"
            id="small-radius-switch"
            disabled={(groups || []).length == 0}
          />
          <Label className="label-bold ml-1">
            {I18n.t("activerecord.attributes.document_type.group_exclusivity")}
          </Label>

          <i
            className="far fa-question-circle text-warning ml-4"
            style={{ fontSize: 25, cursor: "pointer" }}
            id="tooltipExclusivity"
          ></i>

          <Tooltip
            placement="right"
            isOpen={tooltipExclusivity}
            target="tooltipExclusivity"
            toggle={toggleTooltipExclusivity}
          >
            {I18n.t("document_types.form.group_exclusivity.tooltip")}
          </Tooltip>
        </Col>
      </Row>
    );
  };

  // ------------------------------------------------------

  return (
    <div>
      <Button
        color={buttonColor}
        onClick={toggle}
        size={buttonSize}
        outline={outline}
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md="6">
                <FieldWrapper errors={errors.name}>
                  <Label className="required">Nombre</Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FieldWrapper>
              </Col>
              <Col md="6">
                <Label className="required">Categoría</Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={documentCategories[0]}
                  value={category}
                  name="document_category"
                  options={documentCategories}
                  onChange={e => handleChangeCategory(e)}
                />
              </Col>
            </Row>

            {drawGroupSelect()}
            {drawGroupExclusivitySwitch()}

            {category.label === "Trabajador" && (
              <Row className="mt-1 align-middle">
                <Col md="6">
                  <Switch
                    checked={defaultSignersOrder}
                    onChange={e => handleChangeDefaultSignersOrder(e)}
                    onColor="#5a3ee0"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={20}
                    width={35}
                    className="react-switch mt-4"
                    id="small-radius-switch"
                  />
                  <Label className="label-bold ml-1">
                    ¿Requiere un orden de firma por defecto?
                  </Label>
                </Col>
              </Row>
            )}
            {defaultSignersOrder && (
              <Row className="mt-3">
                <Col md="12">{drawSigners()}</Col>
              </Row>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={toggle}>
            Cerrar
          </Button>
          <Button color="success" onClick={handleSubmit}>
            Guardar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentTypesForm;
