import axios from "axios";

export const indexCostCenter = (company_id, callback) => {
  var promise = axios({
    method: 'get',
    url: `/cost_centers.json?company_id=${company_id}`,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}

export const saveCostCenter = (data, callback) => {
  let method, url;

  method = "post";
  url = `/cost_centers.json`;

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
