/** @format */

import React, { useState } from "react";
import DocumentTypesForm from "./Form";

const DocumentTypeItem = ({ type, documentCategories, companySigners, groupOptions }) => {
  const [documentType, setDocumentType] = useState(type);

  const {
    id,
    name,
    document_category,
    default_signers_order,
    signers_order,
  } = documentType;

  const updateItem = (data) => {
    setDocumentType(data);
  };

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{document_category.name}</td>
      <td>{default_signers_order ? "Si" : "No"}</td>
      <td>
        <DocumentTypesForm
          buttonColor="secondary"
          buttonLabel="Editar"
          modalTitle="Editar tipo de documento"
          buttonSize="sm"
          outline
          documentCategories={documentCategories}
          companySigners={companySigners}
          documentType={documentType}
          updateItem={updateItem}
          groupOptions={ groupOptions }
        />
      </td>
    </tr>
  );
};

export default DocumentTypeItem;
