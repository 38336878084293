import React, { useState, useEffect } from "react";
import Cleave from "cleave.js/react";
import { FormGroup, Label, Input, FormFeedback, Col, Card, CardBody, Row } from "reactstrap";
import Select from "react-select";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { handleGroupByDocumentTypeHelper } from "../../groups/helper";
import FieldWrapper from "../../commons/FiledWrapper";
import SignerModalRequireIdentityVerification from '../../signers/modal_require_identity_verification';
import SignersList from "./SignersList";

function DocumentBulkAssignmentForm({
  documentBulkAssignment,
  data,
  documentBulkAssignmentErrors,
  documentBulkData,
  setDocumentBulkData,
}) {

  const [errors, setErrors] = useState(documentBulkAssignmentErrors);
  const [documentTypeId, setDocumentTypeId] = useState(
    documentBulkAssignment.document_type_id || ""
  );
  const [isTemplate, setIsTemplate] = useState(
    documentBulkAssignment.is_template || false
  );
  const [templateId, setTemplateId] = useState(
    documentBulkAssignment.template_id || ""
  );
  const [file, setFile] = useState();
  const [selectByPeople, setSelectByPeople] = useState(
    !documentBulkAssignment.select_by_distribution_list
  );
  const [selectByDistributionList, setSelectByDistributionList] = useState(
    documentBulkAssignment.select_by_distribution_list || false
  );
  const [onlyForPerson, setOnlyForPerson] = useState(
    documentBulkAssignment.only_for_person || false
  );
  const [onlyForCompany, setOnlyForCompany] = useState(
    documentBulkAssignment.only_for_company || false
  );
  const [companySignerIds, setCompanySignerIds] = useState(
    documentBulkAssignment.company_signer_ids || []
  );
  const [personSignerIds, setPersonSignerIds] = useState(
    documentBulkAssignment.person_ids || []
  );
  const [distributionListIds, setDistributionListIds] = useState(
    documentBulkAssignment.destribution_list_ids || []
  );
  const [exclude, setExclude] = useState(
    documentBulkAssignment.exclude || false
  );
  const [groupIds, setGroupIds] = useState(
    documentBulkAssignment?.group_ids || []
  );
  const [consent, setConsent] = useState(
    String(documentBulkAssignment?.document_type_id) == String(data?.consent)
  );
  const [signatureExpiresRequired, setSignatureExpiresRequired] = useState(
    documentBulkAssignment.signature_expires_required || false
  );
  const [signers, setSigners] = useState([
    { type: "person", value: null, label: "Trabajador", rol: "Firmante" }
  ])
  const [signersOrderRequired, setSignersOrderRequired] = useState(false)
  const [companySigners, setCompanySigners] = useState([])

  const [requireIdentifyVerification, setRequireIdentifyVerification] = useState(false);
  const [requireIdentityVerificationModalWasShown, setRequireIdentityVerificationModalWasShown] = useState(false);
  const [openRequireIdentityVerificationModal, setOpenRequireIdentityVerificationModal] = useState(false);

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const handleFile = (event) => {
    const newFile = event.target.files[0];
    setFile(newFile);
    setDocumentBulkData({
      ...documentBulkData,
      file: newFile,
    });
  };

  const handleIsTemplate = (event) => {
    let checked = event.target.checked;
    setIsTemplate(checked);
    setDocumentBulkData({
      ...documentBulkData,
      isTemplate: checked,
      file: "",
    });

    if (checked) {
      setFile("");
    } else {
      setTemplateId("");
    }
  };

  const handleTemplate = (e) => {
    const value = e.target.value;
    const index = event.target.selectedIndex;
    const text = event.target.options[index].text;

    setTemplateId(value);
    setDocumentBulkData({
      ...documentBulkData,
      template: text,
    });
  };

  const handleDocumentType = (event) => {
    let value = event.target.value;
    const index = event.target.selectedIndex;
    const text = event.target.options[index].text;
    const dt = getDocumentTypeById(value)

    let oldDocumentTypeId = documentTypeId;

    setDocumentTypeId(value);
    setConsent(String(value) == data?.consent);
    setDocumentBulkData({
      ...documentBulkData,
      documentType: text,
      consent: String(value) == data?.consent,
    });
    handleGroupByDocumentTypes(value, oldDocumentTypeId);

    if (dt.default_signers_order) {
      setOnlyForPerson(false)
      setOnlyForCompany(false)
      setSignersOrderRequired(true)
      handleDefaultSigners(dt)
    }
  };

  const handleDefaultSigners = d => {
    console.log(d);
    const signersOrder = d.signers_order;
    const newSigners = []
    const newCompanySigners = []

    _.map(signersOrder, signer => {
      if (signer.type === "company") {
        const companySigner = _.find(data.companySigners, cs => cs.id == signer.id)
        const label = `${companySigner.name} - (${companySigner.email})`

        newSigners.push({
          type: "company",
          value: companySigner.id,
          label,
          rol: signer.rol
        })

        newCompanySigners.push({
          type: "company",
          value: companySigner.id,
          label,
          rol: signer.rol
        })
      } else {
        newSigners.push({ type: "person", value: null, label: "Trabajador", rol: "Firmante" })
      }
    })

    setSigners(newSigners)
    setCompanySigners(newCompanySigners)
    setCompanySignerIds(_.map(newCompanySigners, "value"))
    setDocumentBulkData({
      ...documentBulkData,
      companySigner: newCompanySigners,
      signersList: newSigners,
      onlyForCompany: false,
      onlyForPerson: false,
      signersOrderRequired: true
    })
  }

  const getDocumentTypeById = (id) => {
    return _.find(data?.documentTypes, { id: parseInt(id) });
  };

  const handleGroupByDocumentTypes = (_documentTypeId, oldDocumentTypeId) => {
    let _groupIds = handleGroupByDocumentTypeHelper(
      groupIds,
      getDocumentTypeById(_documentTypeId),
      getDocumentTypeById(oldDocumentTypeId)
    );

    setGroupIds(_groupIds);
  };

  const handleSelectByPeople = (event) => {
    let checked = event.target.checked;
    setSelectByPeople(checked);

    setSelectByDistributionList(!checked);
    setDocumentBulkData({
      ...documentBulkData,
      selectedByPeople: checked,
      selectedByDistributionList: !checked,
    });
  };

  const handleSelectByDistributionList = (event) => {
    let checked = event.target.checked;
    setSelectByDistributionList(checked);

    setSelectByPeople(!checked);
    setDocumentBulkData({
      ...documentBulkData,
      selectedByPeople: !checked,
      selectedByDistributionList: checked,
    });
  };

  const handlePersonSigners = (data) => {
    let selected = _.map(data, (option) => {
      return option.value;
    });
    setPersonSignerIds(selected);
    setDocumentBulkData({
      ...documentBulkData,
      personSigners: data,
    });
  };

  const handleSigners = newSigners => {
    setSigners(newSigners)
    setDocumentBulkData({
      ...documentBulkData,
      signersList: newSigners
    })
  }

  const handleRequireIdentityVerificationModal = (show) => {
    if(!requireIdentityVerificationModalWasShown || !show){
      setOpenRequireIdentityVerificationModal(show)
    }
  }

  const handleInputSwitchVerification = (e) => {
    setRequireIdentityVerificationModalWasShown(true)
    handleRequireIdentityVerificationModal(true)
  }

  const handleCompanySigners = (data) => {
    let newSigners = [...signers]
    let selected = _.map(data, (option) => {
      return option.value;
    });
    setCompanySignerIds(selected);
    setCompanySigners(data)

    newSigners = _.filter(signers, signer => signer.type !== "company")
    newSigners = _.union(newSigners, data)
    setSigners(newSigners);
    setDocumentBulkData({
      ...documentBulkData,
      companySigners: data,
      signersList: newSigners
    });
  };

  const handleDistributionLists = (data) => {
    let selected = _.map(data, (option) => {
      return option.value;
    });
    setDistributionListIds(selected);
    setDocumentBulkData({
      ...documentBulkData,
      distributionLists: data,
    });
  };

  const handleOnlyForPerson = checked => {
    setOnlyForPerson(checked);
    if (checked) {
      setOnlyForCompany(false);
      setCompanySignerIds([]);
      setSignersOrderRequired(false)
    }
    setDocumentBulkData({
      ...documentBulkData,
      onlyForPerson: checked,
      onlyForCompany: false,
      signersOrderRequired: false,
      companySigners: [],
    });
  };

  const handleOnlyForCompany = checked => {
    setOnlyForCompany(checked);

    if (checked) {
      setOnlyForPerson(false);
      setSignersOrderRequired(false)
    }
    setDocumentBulkData({
      ...documentBulkData,
      onlyForPerson: false,
      onlyForCompany: checked,
      signersOrderRequired: false,
      personSigners: [],
    });
  };

  const handleExclude = (e) => {
    setExclude(e.target.checked);
    setDocumentBulkData({
      ...documentBulkData,
      exclude: e.target.checked,
    });
  };

  const handleExpiresAt = (e) => {
    setDocumentBulkData({
      ...documentBulkData,
      expiresAt: e.target.value
    })
  };

  const handleSignatureExpiresRequired = (e) => {
    setSignatureExpiresRequired(e.target.checked);
    setDocumentBulkData({
      ...documentBulkData,
      signatureExpiresRequired: e.target.checked,
    });
  };

  const handleSignatureExpiresAt = (e) => {
    setDocumentBulkData({
      ...documentBulkData,
      signatureExpiresAt: e.target.value,
    });
  };

  const handleSignersOrderRequired = checked => {
    setSignersOrderRequired(checked)
    setDocumentBulkData({
      ...documentBulkData,
      signersOrderRequired: checked
    })
  }

  const drawOptionsPeopleSelect = () => {
    return _.map(data?.people || [], (person) => {
      return { label: `${person.name}`, value: person.id };
    });
  };

  const getPersonSignerSelected = () => {
    return _.filter(drawOptionsPeopleSelect(), function(person){
      return personSignerIds.map(String).includes(String(person.value));
    })
  }

  const drawPeopleList = () => {
    if (selectByPeople) {
      return (
        <div className="row">
          <div className="col-12 mb-3">
            <FormGroup>
              <Label
                className="required"
                for="document_bulk_assignment_person_ids"
              >
                {I18n.t(
                  "activerecord.attributes.document_bulk_assignment.person_ids"
                )}
              </Label>

              <Select
                onChange={handlePersonSigners}
                options={drawOptionsPeopleSelect()}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="-- Seleccione Trabajadores --"
                name={`document_bulk_assignment[person_ids][]`}
                id="document_bulk_assignment_person_ids"
                value={ getPersonSignerSelected() }
              />

              {drawErrors("person_ids")}

              <small className="form-text text-muted ml-2 mt-2">
                {I18n.t("document_bulk_assignments.form.people_alert")}
              </small>
            </FormGroup>
          </div>
        </div>
      );
    }
  };

  const drawOptionsDistributionListSelect = () => {
    return _.map(data?.distributionLists, function (distributionList) {
      return {
        label: distributionList?.name,
        value: distributionList?.id,
      };
    });
  };

  const drawDistributionList = () => {
    if (selectByDistributionList) {
      return (
        <div className="row">
          <div className="col-12 mb-3">
            <FormGroup>
              <Label
                className="required"
                for="document_bulk_assignment_distribution_list_ids"
              >
                {I18n.t(
                  "activerecord.attributes.document_bulk_assignment.distribution_list_ids"
                )}
              </Label>

              <Select
                options={drawOptionsDistributionListSelect()}
                onChange={handleDistributionLists}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="-- Seleccione Lista de Distribución --"
                name={`document_bulk_assignment[distribution_list_ids][]`}
                id="document_bulk_assignment_distribution_list_ids"
              />
              {drawErrors("distribution_list_ids")}

              <small className="form-text text-muted ml-2 mt-2">
                {I18n.t(
                  "document_bulk_assignments.form.distribution_list_alert"
                )}
              </small>
            </FormGroup>
          </div>
        </div>
      );
    }
  };

  const drawOptionsGroupIds = () => {
    return _.map(data?.groups || [], function (group) {
      return {
        value: group.id,
        label: group.name,
      };
    });
  };

  const drawValuesGroupIds = () => {
    return _.filter(drawOptionsGroupIds(), function (option) {
      return groupIds.map(String).includes(String(option.value));
    });
  };

  const drawValuesCompanySignerIds = () => {
    return _.filter(drawOptionsCompanySignerSelect(), function (option) {
      return companySigners.map(String).includes(String(option.value));
    });
  };

  const drawValuesPersonSignerIds = () => {
    return _.filter(drawOptionsGroupIds(), function (option) {
      return groupIds.map(String).includes(String(option.value));
    });
  };

  const drawOptionsCompanySignerSelect = () => {
    // return _.map(data?.companySigners, function (signer) {
    //   return { label: signer.name, value: signer.id };
    // });
    return _.map(data?.companySigners, signer => {
      return {
        type: "company",
        value: signer.id,
        label: signer.name,
        rol: "Firmante"
      }
    })
  };

  const handleSignerRol = (e, index) => {
    const newSigners = [...signers]
    newSigners[index]["rol"] = e.target.value
    setSigners(newSigners)
    // const newCompanySigners = documentBulkData.companySigners;
    // newCompanySigners[index].rol = e.target.value;
    // setDocumentBulkData({
    //   ...documentBulkData,
    //   companySigners: newCompanySigners
    // })
  }

  const drawCompanySignersSelect = () => {
    return (
      <Row>
        <Col sm="12" lg="12" className="mb-3">
          <FormGroup>
            <Label
              className="required"
              for="document_bulk_assignment_company_signer_ids"
            >
              {I18n.t(
                "activerecord.attributes.document_bulk_assignment.company_signer_ids"
              )}
            </Label>

            <Select
              options={drawOptionsCompanySignerSelect()}
              onChange={handleCompanySigners}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="-- Seleccione Empleador --"
              id="document_bulk_assignment_company_signer_ids"
              isDisabled={onlyForPerson || consent}
              value={companySigners}
            />

            {/* <small className="form-text text-muted ml-2 mt-2">
              {I18n.t("document_bulk_assignments.form.company_signers_alert")}
            </small> */}
            {drawErrors("company_signer_ids")}
          </FormGroup>
        </Col>
      </Row>
    );
  };

  const drawCompanySignersList = () => {
    return _.map(documentBulkData.companySigners, (signer, index) => (
      <Card className="mt-1 signer-container" key={signer.value}>
        <CardBody>
          <Row>
            <Col md="6">
              <Label>Nombre o Email</Label>
              <br />
              <strong>{signer.label}</strong>
            </Col>
            <Col md="6">
              <Label>Rol</Label>
              <br />
              <Input
                defaultValue="Firmante"
                name={`document_bulk_assignment[bulk_users_attributes][${index}][label]`}
                onChange={e => handleChangeSignerRol(e, index)}
              />
              <Input
                type="hidden"
                defaultValue={signer.value}
                name={`document_bulk_assignment[bulk_users_attributes][${index}][user_id]`}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    ))
  }

  const drawErrors = (key) => {
    if (_.has(errors, key)) {
      return _.map(errors[key], function (error) {
        return <FormFeedback key={error}>{error}</FormFeedback>;
      });
    }
  };

  const handleGroupIds = (data) => {
    let values = _.map(data, "value");
    setGroupIds(values);
    setDocumentBulkData({
      ...documentBulkData,
      groups: data,
    });
  };

  const drawGroupSelect = () => {
    if (getDocumentTypeById(documentTypeId)?.group_exclusivity || false) {
      return (
        <ul>
          {_.map(groupIds, function (id) {
            let _group = _.find(data?.groups, { id: parseInt(id) });
            return (
              <li key={_group.id}>
                {_group.name}
                <input
                  type="hidden"
                  name={`document_bulk_assignment[group_ids][]`}
                  value={_group.id}
                />
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <Select
          options={drawOptionsGroupIds()}
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="-- Seleccione Grupos --"
          name={`document_bulk_assignment[group_ids][]`}
          id="document_bulk_assignment_group_ids"
          value={drawValuesGroupIds()}
          onChange={(value) => handleGroupIds(value)}
        />
      );
    }
  };

  const drawModalRequireIdentityVerification = () => {
    if(true){
      return(
        <SignerModalRequireIdentityVerification
          openModal={ openRequireIdentityVerificationModal || false }
          callbackOpenRequireIdentityVerificationModal={ handleRequireIdentityVerificationModal }
        />
      )
    }
  }

  const showIdentityVerificationBanner = () => {
    if(requireIdentityVerificationModalWasShown && !onlyForCompany){
      return(
        <div className="gradient-border-box mt-2">
          <div className="alert alert-light bg-white p-3 d-flex align-items-md-center material-shadow border-0">
            <div className="row w-100">
              <div className="col-md-9">
                <i className="fas fa-id-badge fa-2x fa-pull-left gradient-text-4t"></i>
                <div>
                  <span className="small d-block">Potencia la Verificación de Identidad de tus firmantes.</span>
                  Desbloquea la <strong>Verificación de Identidad Avanzada</strong>.
                </div>
              </div>
              <div className="col-md-3 text-right">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={ e => setOpenRequireIdentityVerificationModal(true) }
                >
                  Más info
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="row" style={{ overflowY: "auto" }}>
      <div className="col-12">
        <div className="row">
          <div className="col-sm-12 col-lg-6 mb-3">
            <FormGroup>
              <Label
                htmlFor="document_bulk_assignment_document_type_id"
                for="required"
                className="required"
              >
                {I18n.t(
                  "activerecord.attributes.document_bulk_assignment.document_type_id"
                )}
              </Label>

              <Input
                type="select"
                name="document_bulk_assignment[document_type_id]"
                id="document_bulk_assignment_document_type_id"
                className="form-control"
                value={documentTypeId}
                onChange={handleDocumentType}
                invalid={_.has(errors, "document_type")}
              >
                <option value="">-- Seleccionar un tipo --</option>
                {_.map(data.documentTypes, function (record, index) {
                  return (
                    <option
                      value={record.id}
                      key={`document-type-option-${record.id}`}
                    >
                      {record.name}
                    </option>
                  );
                })}
              </Input>
              {drawErrors("document_type")}
            </FormGroup>
          </div>

          <div className="col-sm-12 col-lg-6 mb-3">
            <FormGroup>
              <div className="custom-control custom-switch mt-4">
                <Input
                  className="custom-control-input"
                  type="checkbox"
                  value="1"
                  name="document_bulk_assignment[is_template]"
                  id="document_bulk_assignment_is_template"
                  onChange={handleIsTemplate}
                  checked={isTemplate}
                  invalid={_.has(errors, "is_template")}
                />
                <Label
                  className="custom-control-label label-bold"
                  for="document_bulk_assignment_is_template"
                >
                  {I18n.t(
                    "activerecord.attributes.document_bulk_assignment.is_template"
                  )}
                </Label>
              </div>
              {drawErrors("is_template")}
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <FormGroup>
              <Label
                for="document_bulk_assignment_template_id"
                className="required"
              >
                {I18n.t(
                  "activerecord.attributes.document_bulk_assignment.template_id"
                )}
              </Label>
              <Input
                type="select"
                className="form-control"
                name="document_bulk_assignment[template_id]"
                id="document_bulk_assignment_template_id"
                disabled={!isTemplate}
                value={templateId}
                onChange={handleTemplate}
                invalid={_.has(errors, "template_id")}
              >
                <option value="">-- Selecciona una plantilla --</option>
                {_.map(data.templates, function (record, index) {
                  return (
                    <option
                      value={record.id}
                      key={`template-option-${record.id}`}
                    >
                      {record.name}
                    </option>
                  );
                })}
              </Input>
              {drawErrors("template_id")}
            </FormGroup>
            <FormGroup>
              <Label
                htmlFor='expires_at'
              >
                Fecha de Vencimiento
              </Label>

              <Cleave
                placeholder="DD/MM/AAAA"
                options={{
                  date: true,
                  delimiter: "/",
                  datePattern: ["d", "m", "Y"]
                }}
                name="document_bulk_assignment[expires_at]"
                id="expires_at"
                className="form-control"
                onChange={handleExpiresAt}
              />
            </FormGroup>
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <FormGroup>
              <Label for="document_bulk_assignment_file" className="required">
                {I18n.t(
                  "activerecord.attributes.document_bulk_assignment.file"
                )}
              </Label>
              <div className="custom-file">
                <Input
                  type="file"
                  name="document_bulk_assignment[file]"
                  id="document_bulk_assignment_file"
                  className="custom-file-input"
                  disabled={isTemplate || consent}
                  invalid={_.has(errors, "file")}
                  onChange={handleFile}
                />
                {drawErrors("file")}
                <Label
                  for="document_bulk_assignment_file"
                  className="custom-file-label"
                >
                  Seleccione un Archivo
                </Label>
              </div>
            </FormGroup>
            <small className="form-text text-muted ml-2 mt-2">
              Solo archivos en formato PDF podrán ser previsualizados en el paso 3.
            </small>
            <small className="form-text text-muted ml-2 mt-2">
              Usar este archivo base sólo en caso de anexo de teletrabajo:&nbsp;
              <a href="/files/anexo_multiple_teletrabajo.docx">
                {I18n.t("document_bulk_assignments.form.remote_work_file")}
              </a>
            </small>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-sm-12 col-lg-6 mb-3">
            <FormGroup>
              <div className="custom-control custom-switch mt-4">
                <Input
                  type="hidden"
                  name="document_bulk_assignment[select_by_people]"
                  value="0"
                ></Input>

                <Input
                  className="custom-control-input"
                  type="checkbox"
                  value="1"
                  name="document_bulk_assignment[select_by_people]"
                  id="document_bulk_assignment_select_by_people"
                  checked={selectByPeople}
                  onChange={(e) => handleSelectByPeople(e)}
                  invalid={_.has(errors, "select_by_people")}
                />
                <Label
                  className="custom-control-label label-bold"
                  for="document_bulk_assignment_select_by_people"
                >
                  {I18n.t(
                    "activerecord.attributes.document_bulk_assignment.select_by_people"
                  )}
                </Label>
              </div>
              {drawErrors("select_by_people")}
            </FormGroup>
          </div>
          <div className="col-sm-12 col-lg-6 mb-3 mb-3">
            <FormGroup>
              <div className="custom-control custom-switch mt-4">
                <Input
                  className="custom-control-input"
                  type="checkbox"
                  value="1"
                  name="document_bulk_assignment[select_by_distribution_list]"
                  id="document_bulk_assignment_select_by_distribution_list"
                  checked={selectByDistributionList}
                  onChange={(e) => handleSelectByDistributionList(e)}
                  invalid={_.has(errors, "select_by_distribution_list")}
                />

                <Label
                  className="custom-control-label label-bold"
                  for="document_bulk_assignment_select_by_distribution_list"
                >
                  {I18n.t(
                    "activerecord.attributes.document_bulk_assignment.select_by_distribution_list"
                  )}
                </Label>
              </div>
              {drawErrors("select_by_distribution_list")}
            </FormGroup>
          </div>
        </div>

        {drawPeopleList()}

        {drawDistributionList()}

        <Row className="mt-2">
          <Col className="mb-3">
            <FormGroup>
              <div className="custom-control custom-switch mt-4">
                <Input
                  className="custom-control-input"
                  type="checkbox"
                  value="1"
                  name="document_bulk_assignment[only_for_person]"
                  id="document_bulk_assignment_only_for_person"
                  checked={onlyForPerson}
                  onChange={e => handleOnlyForPerson(e.target.checked)}
                  invalid={_.has(errors, "only_for_person")}
                />
                <Label
                  className="custom-control-label label-bold"
                  for="document_bulk_assignment_only_for_person"
                >
                  {I18n.t(
                    "activerecord.attributes.document_bulk_assignment.only_for_person"
                  )}
                </Label>
              </div>
              {drawErrors("only_for_person")}
            </FormGroup>
          </Col>
          {
            onlyForPerson && !data?.enableFAO && !onlyForCompany && (
              <Col className="mb-3">
                <FormGroup>
                  <div className="custom-control custom-switch mt-4">
                    <Input
                      type="checkbox"
                      className="custom-control-input signs_require_identity_verification"
                      id="require_identity_verification"
                      checked={requireIdentifyVerification}
                      onChange={e => handleInputSwitchVerification(e.target.checked)}
                    />
                    <Label
                      for="require_identity_verification"
                      className="custom-control-label label-bold"
                    >
                      <i className="fas fa-lock gradient-text-4t mr-2"></i>
                      { I18n.t("activerecord.attributes.signer.require_identity_verification") }
                      <span className="badge badge-primary ms-2 bg-gradient-4t">{ I18n.t('actions.new') }</span>
                    </Label>
                  </div>
                </FormGroup>
              </Col>
            )
          }
          <Col className="mb-3">
            <FormGroup>
              <div className="custom-control custom-switch mt-4">
                <Input
                  className="custom-control-input"
                  type="checkbox"
                  value="1"
                  name="document_bulk_assignment[only_for_company]"
                  id="document_bulk_assignment_only_for_company"
                  checked={onlyForCompany}
                  onChange={e => handleOnlyForCompany(e.target.checked)}
                  invalid={_.has(errors, "only_for_company")}
                />
                <Label
                  className="custom-control-label label-bold"
                  for="document_bulk_assignment_only_for_company"
                >
                  {I18n.t(
                    "activerecord.attributes.document_bulk_assignment.only_for_company"
                  )}
                </Label>
              </div>
            </FormGroup>
          </Col>
          {!onlyForPerson && !onlyForCompany && (
            <Col className="mb-3">
              <FormGroup>
                <div className="custom-control custom-switch mt-4">
                  <Input
                    className="custom-control-input"
                    type="checkbox"
                    value="1"
                    name="document_bulk_assignment[signers_order_required]"
                    id="document_bulk_assignment_signers_order_required"
                    checked={signersOrderRequired}
                    onChange={e => handleSignersOrderRequired(e.target.checked)}
                    invalid={_.has(errors, "signers_order_required")}
                  />
                  <Label
                    className="custom-control-label label-bold"
                    for="document_bulk_assignment_signers_order_required"
                  >
                    ¿Requiere órden de firma?
                  </Label>
                </div>
              </FormGroup>
            </Col>
          )}
        </Row>

        {drawCompanySignersSelect()}
        { showIdentityVerificationBanner() }
        { drawModalRequireIdentityVerification() }
        <Label className="label-bold">Listado de Firmantes</Label>
        <DndProvider backend={HTML5Backend}>
          <SignersList
            signers={signers}
            handleSigners={handleSigners}
            signersOrderRequired={signersOrderRequired}
            handleSignerRol={handleSignerRol}
          />
        </DndProvider>

        <div className="row mt-2">
          <div className="col-sm-12 col-lg-6 mb-3">
            <FormGroup>
              <div className="custom-control custom-switch mt-4">
                <Input
                  className="custom-control-input"
                  type="checkbox"
                  value="1"
                  name="document_bulk_assignment[signature_expires_required]"
                  id="document_bulk_assignment_signature_expires_required"
                  checked={signatureExpiresRequired}
                  onChange={handleSignatureExpiresRequired}
                />
                <Label
                  className="custom-control-label label-bold"
                  for="document_bulk_assignment_signature_expires_required"
                >
                  {I18n.t(
                    "activerecord.attributes.document_bulk_assignment.signature_expires_required"
                  )}
                </Label>
              </div>
              {drawErrors("signature_expires_required")}
            </FormGroup>
          </div>
          <div className="col-sm-12 col-lg-6 mb-3">
            {signatureExpiresRequired && (<FormGroup>
              <Label
                htmlFor='signature_expires_at'
                className="required"
              >
                Fecha y Hota límite para firmar
              </Label>
              <FieldWrapper errors={errors["signature_expires_at"] || []}>
                <Cleave
                  placeholder="DD/MM/AAAA hh:mm"
                  options={{
                    delimiters: ["/", "/", " ", ":"],
                    blocks: [2, 2, 4, 2, 2]
                  }}
                  name="document_bulk_assignment[signature_expires_at]"
                  id="signature_expires_at"
                  className="form-control"
                  onChange={handleSignatureExpiresAt}
                />
              </FieldWrapper>
            </FormGroup>)}
          </div>
        </div>

        <div className="row absolute">
          <div className="col-12 mb-3">
            <FormGroup>
              <Label className="" for="document_bulk_assignment_group_ids">
                Grupos
              </Label>

              {drawGroupSelect()}
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-6 mb-3">
            <FormGroup>
              <div className="custom-control custom-switch mt-4">
                <Input
                  className="custom-control-input"
                  type="checkbox"
                  value="1"
                  name="document_bulk_assignment[exclude]"
                  id="document_bulk_assignment_exclude"
                  checked={exclude}
                  onChange={handleExclude}
                />
                <Label
                  className="custom-control-label label-bold"
                  for="document_bulk_assignment_exclude"
                >
                  {I18n.t(
                    "activerecord.attributes.document_bulk_assignment.exclude"
                  )}
                </Label>
              </div>
              {drawErrors("exclude")}
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

// const DocumentBulkAssignmentForm = () => {
//   return (
//     <div>
//       Formulario
//     </div>
//   )
// }

export default DocumentBulkAssignmentForm;
