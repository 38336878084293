import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { saveCostCenter, indexCostCenter } from "../cost_centers/Axios";
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Col
} from "reactstrap";

// {
//   companyId,
//   companyName,
//   costCenterId,
//   canCreate,
//   errors,
//   groups,
//   assignGroupToCostCenter
// }

const CostCenterSelect = (props) => {

  const placeholder = "-- Seleccionar centro de costo --";
  const inputName = "person_sending[cost_center_id]";
  const [costCenterErrors, setCostCenterErrors] = useState(props?.costCenterErrors)

  const costCenter = _.find(props?.costCenters, function(_costCenter){
    return String(_costCenter.id) == String(props?.costCenterId)
  })

  const costCenterValue = costCenter ? { label: costCenter?.name, value: costCenter?.id } : ""

  const costCenterOptions = _.map(props?.costCenters, function(_costCenter){
    return {
      value: _costCenter.id,
      label: _costCenter.name
    }
  })

  const handleCreateCostCenter = newValue => {
    let data = new FormData();
    data.append("cost_center[name]", newValue);
    data.append("cost_center[company_id]", props?.companyId)

    saveCostCenter(data, response => {
      if(response.status == 201 || response.status == 200){ //created

        let newCostCenter = response.data;

        if(typeof props?.setCompanies === 'function'){
          let _companies = [ ... props?.companies ];
          let _company = _.find(_companies, function(_com){
            return String(_com.id) == String(newCostCenter.company_id)
          })

          _company.cost_centers.push(newCostCenter)

          props.setCompanies(_companies)
        }


        if(typeof props?.callbackHandlePersonSending === 'function'){
          props.callbackHandlePersonSending('cost_center_id', { target: { value: newCostCenter.id  } } )
        }
      } else {
        handleErrors(response.data)
      }
    });
  };

  const handleErrors = (error) => {
    let errors = _.map(error, function(message, key){
      return message
    })
    setCostCenterErrors(errors)
  }

  // const handleGroupIds = (name) => {
  //   if(companyName && name && assignGroupToCostCenter){
  //     // Formamos el nombre del grupo
  //     let groupName = `${ companyName } - ${ name }`;
  //     // Buscmaos el grupo dentro del arreglo
  //     let _group = _.find(groups, function(group){
  //       return group.name == groupName
  //     });

  //     // Obtenemos los valores que se encuentran seleccionado
  //     let groupSelectedIds = $("select[name='person_sending[group_ids][]']").val() || [];


  //     // buscamos si el nuevo centro de costo tiene un grupo con el nombre
  //     let groupIsSelected = _.find(groupSelectedIds, function(selectedId){
  //       return String(selectedId) ==  _group?.id
  //     });

  //     let currentGroupSelected = _.find(groups, { name: currentGroup } )

  //     if(currentGroupSelected != _group){
  //       let data = _.remove(groupSelectedIds, function(g){
  //         return String(g) == String(currentGroupSelected?.id)
  //       })
  //     }

  //     if(_group){
  //       groupSelectedIds.push(_group.id)
  //     }

  //     $("select[name='person_sending[group_ids][]']").val(groupSelectedIds)
  //     $("select[name='person_sending[group_ids][]']").trigger('change.select2');
  //     setCurrentGroup(_group?.name);
  //   }
  // }

  const handleCostCenter = (data) => {
    if(typeof props?.callbackHandlePersonSending === 'function'){
      props.callbackHandlePersonSending('cost_center_id', { target: { value: data?.value } } )
    }
  }

  const drawCostCenterSelect = () => {
    if(props?.canCreate) {
      return(
        <CreatableSelect
          isClearable
          placeholder={ placeholder }
          options={ costCenterOptions }
          name={ inputName }
          value={ costCenterValue }
          onChange={ (newValue) => handleCostCenter(newValue) }
          onCreateOption={ newValue => handleCreateCostCenter(newValue) }
          formatCreateLabel={ inputValue => "Crear nuevo: " + inputValue }
        />
      )
    } else {
      return (
        <Select
          className="basic-single1"
          classNamePrefix="select"
          name={ inputName }
          onChange={ (newValue) => handleCostCenter(newValue) }
          options={ costCenterOptions }
          value={ costCenterValue }
          placeholder={ placeholder }
        />
      )
    }
  }

  const drawErrors = () => {
    if(props?.costCenterErrors.length > 0){
      return(
        <ul className='errors_list clearfix'>
          { _.map(props.costCenterErrors, function(error, index ){
            return(
              <li
                key={ `error-${ index }`}
              >
                { error }
              </li>
            )
          })}
        </ul>
      )
    }

    return (props?.costCenterErrors || []).map(error => (
      <div
        key={ error }
        style={{ display: "block" }}
        className="invalid-feedback"
      >
        { error }
      </div>
    ));
  };

  return(
    <Fragment>
      <Label
        for="person_sending_cost_center_id"
        className="required"
      >
        { I18n.t("activerecord.attributes.cost_center.name") }
      </Label>
      { drawCostCenterSelect() }
      { drawErrors() }
    </Fragment>
  )
}

export default CostCenterSelect;
