import axios from "axios";

export const getPersonSendings = (data, callback) => {
  const promise = axios({
    method: "POST",
    url: "/document_bulk_uploads/get_person_sendings.json",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const saveBulk = (data, callback) => {
  const promise = axios({
    method: "post",
    url: "/document_bulk_uploads.json",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const getDocumentsFromAdp = (data, callback) => {
  const promise = axios({
    method: "post",
    url: "/document_bulk_uploads/adp_documents.json",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
