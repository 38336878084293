import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import CostCenterSelect from './CostCenterSelect';
import { saveCompany } from "../company/Axios";
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Col,
  Button,
  Row
} from "reactstrap";

const CompanySelect = (props) => {

  const placeholder = "-- Seleccionar razón social --";
  const inputName = "person_sending[company_id]";

  const [companies, setCompanies] = useState(props?.companies);
  const canCreate = props?.canCreate || false;

  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [companyNewCostCenter, setCompanyNewCostCenter] = useState("");

  const [showCompanyForm, setShowCompanyForm] = useState(false);
  const [companyId, setCompanyId] = useState(props?.companyId)
  const [company, setCompany] = useState({})
  const [formErrors, setFormErrors] = useState([])

  useEffect(() => {
    setCompanyId(props?.companyId);
  }, [props?.companyId]);

  useEffect(() => {
    setCompanies(props?.companies);
  }, [props?.companies]);

  const handleCompanyId = data => {
    const value = data.value;

    setCompanyId(value)

    if(typeof props?.callbackHandlePersonSending === 'function'){
      props.callbackHandlePersonSending('company_id', { target: { value: value } } )
    }
  }

  const getValueForCompanySelect = () => {
    let _company = _.find(companies, function(__c){
      return String(__c.id) == String(companyId)
    })

    if(_company){
      return { value: _company.id, label: _company.name }
    }
  }

  const getOptionsForCompanySelect = () => {
    return _.map(companies, function(_com){
      return {
        value: _com.id,
        label: _com.name
      }
    })
  }

  const handleCompanyForm = (name) => {
    setCompanyName(name);

    setShowCompanyForm(true)
  }

  const drawFormErrors = (key) => {
    if(_.has(formErrors || {}, key)){
      return(
        _.map(formErrors[key], function(error){
          return(
            <FormFeedback key={error}>{error}</FormFeedback>
          )
        })
      )
    }
  }

  const drawCompanyForm = () => {
    if(showCompanyForm && canCreate){
      return(
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <h5 className="panel-header">Crear Razón Social</h5>
                <FormGroup>
                  <Label htmlFor='company_name' required>
                    { I18n.t("activerecord.attributes.company.name") }
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="company[name]"
                    id="company_name"
                    value={ companyName }
                    onChange={ e => setCompanyName(e?.target?.value) }
                    invalid={ _.has(formErrors, "name") }
                  />

                  { drawFormErrors("name") }
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="company_name" required>
                    { I18n.t("activerecord.attributes.company.number") }
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="company[number]"
                    id="company_number"
                    value={ companyNumber }
                    onChange={ e => setCompanyNumber(e?.target?.value) }
                    invalid={ _.has(formErrors, "number") }
                  />

                  { drawFormErrors("number") }
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="company_cost_centers_attributes_0_name">
                    { I18n.t("activerecord.attributes.cost_center.name") }
                  </Label>

                  <Input
                    className="form-control"
                    type="text"
                    name="company[cost_centers_attributes][0][name]"
                    id="company_cost_centers_attributes_0_name"
                    value={ companyNewCostCenter }
                    onChange={ e => setCompanyNewCostCenter(e.target.value) }
                    invalid={ _.has(formErrors, "cost_centers.name") }
                  ></Input>

                  { drawFormErrors("cost_centers.name") }
                </FormGroup>

                <div className="text-right">
                  <Button
                    color="btn btn-outline-secondary"
                    onClick={ () => setShowCompanyForm(false) }
                  >
                    { I18n.t('actions.close') }
                  </Button>
                  <Button
                    color="btn btn-success"
                    type="button"
                    onClick={ handleCreateCompany }
                  >
                    { I18n.t("helpers.submit.create", { model: "Razón Social"}) }
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )
    }
  }

  const handleCreateCompany = () => {
    let _formData = new FormData();

    _formData.append("company[name]", companyName);
    _formData.append("company[number]", companyNumber);
    _formData.append("company[cost_centers_attributes][0][name]", companyNewCostCenter)

    saveCompany(_formData, response => {
      if(response.status == 201){
        setShowCompanyForm(false)
        setFormErrors([])

        let newCompany = response.data;
        let newCostCenter = _.find(newCompany.cost_centers, { name: companyName } )

        if(typeof props?.setCompanies === 'function'){
          let _companies = companies
          _companies.push(newCompany)
          props.setCompanies(_companies)
        }

        // if(typeof props?.callbackHandlePersonSending === 'function'){
        //   props.callbackHandlePersonSending('company_id', { target: { value: newCompany.id } } )
        // }

        if(newCostCenter && typeof props?.callbackHandlePersonSending === 'function'){
          props.callbackHandlePersonSending('cost_center_id', { target: { value: newCostCenter.id } } )
        }

        setCompanyName("")
        setCompanyNumber("")
        setCompanyNewCostCenter("")

      } else {
        setFormErrors(response.data);
      }
    })
  }



  const drawCompanySelect = () => {
    if(canCreate) {
      return(
        <CreatableSelect
          isClearable
          placeholder={ placeholder }
          options={ getOptionsForCompanySelect() }
          name={ inputName }
          value={ getValueForCompanySelect() }
          onChange={ (newValue) => handleCompanyId(newValue) }
          onCreateOption={ newValue => handleCompanyForm(newValue) }
          // isLoading={ isLoading }
          formatCreateLabel={ inputValue => "Crear nuevo: " + inputValue }
        />
      )
    } else {
      return(
        <Select
          className="basic-single"
          classNamePrefix="select"
          // value={ companyValue }
          onChange={ (newValue) => handleCompanyId(newValue) }
          name={ inputName }
          options={ getOptionsForCompanySelect() }
          placeholder={ placeholder }
        />
      )
    }
  }

  const drawErrors = () => {
    return (props?.companyErrors || []).map(error => (
      <div
        key={ error }
        style={{ display: "block" }}
        className="invalid-feedback"
      >
        {error}
      </div>
    ));
  };

  return (
    <div className="row mb-3">
      <Col xs="12">
        <FormGroup>
          <Label
            for="person_sending_company_id"
            className="required"
          >
            { I18n.t("activerecord.attributes.company.name") }
          </Label>

          { drawCompanySelect() }
          { drawErrors() }
        </FormGroup>

        { drawCompanyForm() }
      </Col>
    </div>
  );
};

export default CompanySelect;
