import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";

const SignerForm = props => {
  const { signer, signersOrderRequired, signerIndex, handleMoveSigner, handleSignerRol } = props
  const style = {
    border: '1px dashed gray',
    cursor: 'move'
  }

  const signerRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'signer-container-2',
    hover(item, monitor) {
      if (!signerRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = signerIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = signerRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      handleMoveSigner(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'signer-container-2',
      id: signer.order,
      index: signerIndex
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(drop(signerRef));

  const signerOrderInputName = () => {
    if (signer.type === "company") {
      return `document_bulk_assignment[bulk_users_attributes][${signerIndex}][order]`
    } else {
      return `document_bulk_assignment[person_signer_order]`
    }
  }

  return (
    <div ref={signerRef}>
      <Card className="mt-1 signer-container" style={signersOrderRequired ? style : {}} >
        <CardBody>
          {signersOrderRequired && (<Row>
            <Col xs="12">
              <span className="text-muted">
                {`${parseInt(signerIndex) + 1} ° Firma`}
              </span>
            </Col>
          </Row>)}
          <Row>
            <Col md="6">
              <Label>Nombre</Label>
              <br />
              <strong>{signer.label}</strong>
            </Col>
            <Col md="6">
              <Label>Rol</Label>
              <br />
              <Input
                value={signer.rol || "Firmante"}
                onChange={e => handleSignerRol(e, signerIndex)}
                disabled={signer.type === "person"}
                name={signer.type == "company" ? `document_bulk_assignment[bulk_users_attributes][${signerIndex}][label]` : ""}
              />
              <Input
                type="hidden"
                value={signer.value || ""}
                name={signer.type == "company" ? `document_bulk_assignment[bulk_users_attributes][${signerIndex}][user_id]` : ""}
              />
              <Input
                type="hidden"
                value={signerIndex}
                name={signerOrderInputName()}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default SignerForm;