import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import _ from "lodash";
import PurchaseItem from "./Item";

const PurchasesIndex = ({ purchases }) => {
  return (
    <Card>
      <CardBody className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Plan Adquirido</th>
              <th>Precio</th>
              <th>Fecha de Compra</th>
              <th>Fecha de Activación</th>
              <th>Fecha de Expiración</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {_.map(purchases, purchase => (
              <PurchaseItem key={purchase.id} purchase={purchase} />
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default PurchasesIndex;
