import React from "react";
import axios from "axios";
import Joyride from "react-joyride";
import LazyComponent from "../lazy_component";
import ButterToast, { Cinnamon, POS_TOP, POS_RIGHT } from "butter-toast";
import _ from "lodash";

class JoyRideTour extends LazyComponent {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  convertTitle(title) {
    return (
      <React.Fragment>
        <span className="text-primary">{title}</span>
      </React.Fragment>
    );
  }

  buildCustomStyles() {
    let { steps } = this.state;
    return steps.map(step => {
      let has_title = _.includes(_.keys(step), "title");
      if (has_title) step["title"] = this.convertTitle(step["title"]);
      return step;
    });
  }

  handleNotification(data) {
    var { message, type } = data;
    var _title = type == "success" ? "Excelente!" : "Error!";
    var _type =
      type == "success"
        ? Cinnamon.Crisp.SCHEME_BLUE
        : Cinnamon.Crisp.SCHEME_RED;
    var icon = <i className="fas fa-info fa-2x" />;

    ButterToast.raise({
      content: (
        <Cinnamon.Crisp
          scheme={_type}
          content={() => <span>{message}</span>}
          title={_title}
          icon={icon}
        />
      )
    });
  }

  handleTourWasSeen() {
    let _this = this;
    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": $('[name="csrf-token"]')[0].content,
        enctype: "multipart/form-data"
      }
    }

    axios
      .patch(
        `${window.location.origin}/tours/${this.props.tour_id}/seen`,
        {},
        options
      )
      .then(response => {
        _this.handleNotification({
          message: "Tour Guiado Completado",
          type: "success"
        });
      })
      .catch(error => {
        _this.handleNotification({
          message: "Vuelva a intentar",
          type: "error"
        });
      });
  }

  handleJoyrideCallback = data => {
    const { status, lifecycle } = data;
    if ((status == "finished" || status == "skipped") &&
        lifecycle == "complete")
      this.handleTourWasSeen();
  };

  render() {
    const { steps } = this.state;
    return (
      <Joyride
        steps={this.buildCustomStyles()}
        callback={this.handleJoyrideCallback}
        continuous
        showProgress
        showSkipButton
        disableOverlayClose
        disableCloseOnEsc
        styles={{
          options: { primaryColor: "#5a3ee0" }
        }}
        locale={{
          back: "Volver",
          close: "Cerrar",
          last: "Entendido!",
          next: "Siguiente",
          skip: "Saltar"
        }}
      />
    );
  }
}

export default JoyRideTour;
