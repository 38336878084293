import React, { useState, useEffect, useContext, useCallback } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from 'immutability-helper';

import DocumentFormContext from "../documents/form/context";
import SignerModalRequireIdentityVerification from './modal_require_identity_verification';
import SignerForm from "../signers/form";

const SignerList = props => {
  const document = props?.document || {}
  const defaultLabel = props?.defaultLabel || "Firmante";

  const { contextType, dataCustomer, enableFAO } = useContext(DocumentFormContext)

  const [signers, setSigners] =  useState(props?.document?.signers || []);

  const currentDocumentType = _.find( dataCustomer?.documentTypes, function(documentType){
    return documentType.id == parseInt(document.document_type_id)
  })

  const [requireIdentityVerificationModalWasShown,setRequireIdentityVerificationModalWasShown] = useState(false);

  const [openRequireIdentityVerificationModal, setOpenRequireIdentityVerificationModal] = useState(false);

  // Esto solo aplica para customer
  useEffect(() => {
    handleSignatureRequired()
  }, [props?.document?.signature_required, props?.document.for_client])

  useEffect(() => {
    if(openRequireIdentityVerificationModal){
      setRequireIdentityVerificationModalWasShown(true)
    }
  }, [openRequireIdentityVerificationModal])

  useEffect(() => {

    let _signers = document?.signers || [];

    _signers = setOrderSigner(_signers);

    setSigners(_signers);

  }, [document])

  useEffect(() => {
    let _signers = [...signers];

    _.each(_signers, function (signer) {
      if (!_.has(signer, "signer_key")) {
        signer["signer_key"] = generateSignerKey();
      }
    });
  }, []);

  useEffect(() => {
    handleChangingDocumentType()
  }, [props?.document.document_type_id])

  const handleRequireIdentityVerificationModal = (show) => {
    if(!requireIdentityVerificationModalWasShown || !show){
      setOpenRequireIdentityVerificationModal(show)
    }
  }

  const handleChangingDocumentType = () => {
    let _signers = [ ... signers ]


    if(currentDocumentType?.default_signers_order){
      _.each(_signers, signer => signer._destroy = 1);

      let signersOrdered = _.sortBy(currentDocumentType?.signers_order, signer => parseInt(signer.order));

      _.each(signersOrdered, function(_signer){

        let _signerAttributes = getSignerAttributesByType( _signer.type == 'person' ? 'Trabajador' : 'Empresa')

        _signerAttributes.email = _signer.type == 'person' ? dataCustomer?.personEmail : _signer.email;
        _signerAttributes.label = _signer.rol || defaultLabel;
        _signerAttributes.order = _signer.order;

        _signers.push(_signerAttributes);
      })

      if(_.isFunction(props?.handleSigners)){
        props?.handleSigners(_signers);
      }
    }
  }

  const setOrderSigner = (_signers) => {
    let _order = 0;

    _.each(_signers, function(_signer){
      if(_signer._destroy){
        _signer.order = 0
      } else {
        _signer.order = _order;
        _order += 1;
      }
    })

    return _signers;
  }

  const deleteSignersWithoutId = _signers => {
    _.remove(_signers, function (_signer) {
      return _signer.id == undefined || _signer.id == "";
    });

    return _signers;
  };

  const handleSignatureRequired = () => {
    let _signers = [ ...  signers ];

    _signers = deleteSignersWithoutId(_signers);

    if(!document.signature_required){
      _.each(signers, signer => signer._destroy = true);
    }

    if(contextType == 'public' && _signers.length == 0 && document.signature_required){
      let _signerExternalAttributes = getSignerAttributesByType('Externo');

      _signers = _.concat(_signers, _signerExternalAttributes);
    } else if(contextType !== 'public') {
      _signers = handleSignatureForPersonForm(_signers);
      _signers = handleSignatureForCompanyClientForm(_signers)
    }


    if(_.isFunction(props?.handleSigners)){
      props.handleSigners(_signers)
    }
  }

  const handleSignatureForPersonForm = _signers => {
    if(document.signature_required && _signers.length == 0 && !document.for_client && contextType == 'person'){
      let _signerPersonAttributes = getSignerAttributesByType('Trabajador');
      _signerPersonAttributes.email = dataCustomer?.personEmail;

      let _signerCompanyAttributes = getSignerAttributesByType('Empresa');
      _signerCompanyAttributes.email = dataCustomer?.companyEmail

      _signers = _.concat(_signers, _signerPersonAttributes, _signerCompanyAttributes)
    }
    return _signers;
  }

  const handleSignatureForCompanyClientForm = _signers => {
    if(document.signature_required && _signers.length == 0 && (contextType == 'company' || document.for_client)){
      let _signerCompanyAttributes = getSignerAttributesByType('Empresa');
      _signerCompanyAttributes.email = dataCustomer?.companyEmail
      let _signerExternalAttributes = getSignerAttributesByType('Externo');

      _signers = _.concat(_signers, _signerCompanyAttributes, _signerExternalAttributes);
    }
    return _signers;
  }

  const getSignerAttributesByType = type => {
    let signerTypeId = _.find(dataCustomer?.signerTypes, { name: type })?.id;

    return signerAttributes(signerTypeId) || ""
  }
  // Aqui termina la logica de los customer

  const generateSignerKey = () => {
    return _.uniqueId("signer_key_");
  };

  const signerAttributes = (signerTypeId) => {
    return {
      id: "",
      signer_type_id: signerTypeId || "",
      email: "",
      identification_number: "",
      first_name: "",
      first_surname: "",
      last_surname: "",
      label: defaultLabel,
      order: 0,
      signer_key: generateSignerKey(),
      _destroy: false,
    }
  }

  const handleNewSigner = () => {
    let _signers = [ ... signers ];

    let newSigner = {}

    if(contextType !== 'public'){
      newSigner = getSignerAttributesByType("Empresa");
    } else {
      newSigner= getSignerAttributesByType('Externo');
    }

    _signers = _.concat(_signers, newSigner);

    if(_.isFunction(props?.handleSigners)){
      props.handleSigners(_signers)
    }
  }

  const handleSigner = (signer) => {
    let _signers = [ ... signers ];

    let positionSigner = _.findIndex(signers, { signer_key: signer.signer_key });

    if(positionSigner > -1){
      _signers[positionSigner] = signer
    }

    if(_.isFunction(props?.handleSigners)){
      props.handleSigners(_signers);
    }
  }

  const drawButtonAddNewSigner = () => {
    if(document.signature_required){
      return(
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-dark btn-sm"
              onClick={ e => handleNewSigner(e) }
            >
              <i className="fas fa-user" /> &nbsp;{" "}
              { I18n.t("documents.form.add_signer") }
            </button>
          </div>
        </div>
      )
    }
  }

  const handleMoveSigner = (dragIndex, hoverIndex) => {
    let _signers = [ ... signers ];
    const dragSigner = signers[dragIndex];
    _signers = update(_signers, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragSigner]
      ]
    })

    _signers = setOrderSigner(_signers);

    if(_.isFunction(props?.handleSigners)){
      props.handleSigners(_signers)
    } else {
      setSigners(_signers)
    }
  }

  const drawModalRequireIdentityVerification = () => {
    if(true){
      return(
        <SignerModalRequireIdentityVerification
          openModal={ openRequireIdentityVerificationModal || false }
          callbackOpenRequireIdentityVerificationModal={ handleRequireIdentityVerificationModal }
        />
      )
    }
  }

  const showIdentityVerificationBanner = () => {
    if(requireIdentityVerificationModalWasShown){
      return(
        <div className="gradient-border-box mt-2">
          <div className="alert alert-light bg-white p-3 d-flex align-items-md-center material-shadow border-0">
            <div className="row w-100">
              <div className="col-md-9">
                <i className="fas fa-id-badge fa-2x fa-pull-left gradient-text-4t"></i>
                <div>
                  <span className="small d-block">Potencia la Verificación de Identidad de tus firmantes.</span>
                  Desbloquea la <strong>Verificación de Identidad Avanzada</strong>.
                </div>
              </div>
              <div className="col-md-3 text-right">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={ e => setOpenRequireIdentityVerificationModal(true) }
                >
                  Más info
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return(
    <div className="row mb-2">
      <div className="col-12">
        { showIdentityVerificationBanner() }
        <DndProvider backend={ HTML5Backend }>
          { _.map(signers, function(signer,index){
            return(
              <SignerForm
                index={ index }
                signer={ signer }
                key={ signer.signer_key }
                formName={`${ props?.formName }[${ index }]`}
                callbackOpenRequireIdentityVerificationModal={ handleRequireIdentityVerificationModal }
                handleSigner={ handleSigner }
                document={ document }
                handleMoveSigner={ handleMoveSigner }
              />
            )
          })}
        </DndProvider>
        { drawModalRequireIdentityVerification() }
        { drawButtonAddNewSigner() }
      </div>
    </div>
  )
};

export default SignerList;
