import axios from "axios";

export const saveSignatureCertificate = (data, callback) => {
  let method, url;

  if (data.get("id")) {
    method = "patch";
    url = `/ecert/signature_certificates/${data.get("id")}.json`;
  } else {
    method = "post";
    url = "/ecert/signature_certificates.json";
  }

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
