import WebpackerReact from "webpacker-react";
window.Routes = require("js-routes/index.js.erb");
import "./token";
import BrandConfigurationForm from "../components/brand_configuration_form";
import JoyRideTour from "../components/joy_ride_tour.js";
import HiringDocuments from "../components/hiring_documents.js";
import CodeInput from "../components/code_input.js";
import DocumentPreview from "../components/document_preview.js";
import FlowButton from "../components/purchases/flow_button.js";
import ShowNotification from "../components/show_notification";
import CompanyCostCenterSelect from "../components/person_sendings/CompanyCostCenterSelect";
import CostCenterSelect from "../components/person_sendings/CostCenterSelect";
import DocumentBulkAssignmentForm from "../components/documents/bulk_assignments/Form.js";
// import DocumentBulkUploadForm from "../components/document_bulk_uploads/Form";
// import DocumentBulkUploads from "../components/document_bulk_uploads";
import DocumentBulkUploadForm from "../components/documents/bulk_uploads/Form";
import CompanyTemplate from "../components/company/Template";
import DocumentBulkRequests from "../components/document_bulk_requests";
import NewDocumentBulkRequest from "../components/document_bulk_requests/New";
import DocumentTypesIndex from "../components/document_types";
import DocumentTypesForm from "../components/document_types/Form";
import DocumentForm from "../components/documents/form";
import PersonSendingForm from "../components/person_sendings/form";
import NotificationIndex from "../components/notification/index";
import DocumentBulkAssignmentWizard from "../components/documents/bulk_assignments/Wizard";
import AdpConfigForm from "../components/customers/AdpConfigForm";
import EcertCertificateForm from "../components/ecert/CertificateForm";
import EcertCertificateErrors from "../components/ecert/CertificateErrors";
import SignPlans from "../components/plans/SignPlans";
import PurchasesIndex from "../components/purchases";
import CustomPinForm from "../components/users/CustomPinForm";

WebpackerReact.setup({
  BrandConfigurationForm,
  JoyRideTour,
  HiringDocuments,
  CodeInput,
  DocumentPreview,
  ShowNotification,
  FlowButton,
  CompanyCostCenterSelect,
  CostCenterSelect,
  DocumentBulkAssignmentForm,
  DocumentBulkUploadForm,
  // DocumentBulkUploadForm,
  // DocumentBulkUploads,
  CompanyTemplate,
  DocumentBulkRequests,
  NewDocumentBulkRequest,
  DocumentTypesIndex,
  DocumentTypesForm,
  DocumentForm,
  PersonSendingForm,
  NotificationIndex,
  DocumentBulkAssignmentWizard,
  AdpConfigForm,
  EcertCertificateForm,
  EcertCertificateErrors,
  SignPlans,
  PurchasesIndex,
  CustomPinForm,
});
