import React from "react";
import AasmBagde from "../commons/AasmBagde";
import UploadReceiptForm from "./UploadReceiptForm";

const PurchaseItem = ({ purchase }) => {
  const {
    id,
    plan,
    created_at,
    aasm_state,
    aasm_state_es,
    activated_at,
    expired_at,
  } = purchase;

  return (
    <tr>
      <td className="small text-muted">{id}</td>
      <td>{plan.name}</td>
      <td>${plan.price}</td>
      <td>{created_at}</td>
      <td>{activated_at}</td>
      <td>{expired_at}</td>
      <td>
        <AasmBagde aasmState={aasm_state} text={aasm_state_es} pill />
      </td>
      <td>
        {aasm_state == "waiting_payment" && (
          <UploadReceiptForm purchase={purchase} />
        )}
      </td>
    </tr>
  );
};

export default PurchaseItem;
