import React, { useState } from "react";
import { Row, Col, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import DocumentBulkRequest from "./Show";

const DocumentBulkRequestItem = ({ documentBulkRequest }) => {
  const [bulkRequest, setBulkRequest] = useState(documentBulkRequest);

  const updateBulkRequest = data => {
    setBulkRequest(data);
  };

  return (
    <Row key={bulkRequest.id}>
      <Col md="12">
        <Card className="bg-white mb-3 clip">
          <CardHeader
            className="text-left bg-white"
            style={{ borderBottom: "none" }}
          >
            <Row>
              <Col
                sm="12"
                md="9"
                className="d-flex align-items-center flex-wrap flex-sm-nowrap"
              >
                <h6 className="text-dark font-weight-bold d-block text-capitalize mb-0">
                  {bulkRequest.documentType.name}
                </h6>
                <small className="text-primary ml-1 mb-0 d-flex align-items-center">
                  <span className="badge badge-pill badge-danger">
                    Con errores
                  </span>
                </small>
                <small className="text-primary ml-1 mb-0 d-flex align-items-center">
                  <span
                    className={`badge badge-pill badge-${
                      bulkRequest.reviewed ? "success" : "danger"
                    }`}
                  >
                    {bulkRequest.reviewed ? "Revisado" : "No revisado"}
                  </span>
                </small>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </CardHeader>
          <CardBody className="pt-0 pb-0" />
          <CardFooter
            className="text-muted bg-white pt-1 pb-2 pr-0"
            style={{ borderTop: "none" }}
          >
            <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
              <div className="w-100 d-inline mb-2 mb-sm-0">
                <small>
                  Solicitado el <strong>{bulkRequest.createdAt}</strong>
                </small>
                <small>
                  {" "}
                  - Por <strong>{bulkRequest.requestedBy.name}</strong>
                </small>
              </div>
              <div className="flex-shrink-1 d-flex ml-auto">
                <DocumentBulkRequest
                  bulkRequest={bulkRequest}
                  updateBulkRequest={updateBulkRequest}
                />
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default DocumentBulkRequestItem;
