import React, { useState, useEffect } from "react";
import useSound from 'use-sound';
import TimeAgo from 'timeago-react'; // var TimeAgo = require('timeago-react');
import * as timeago from 'timeago.js';
import es from 'timeago.js/lib/lang/es';

import ActionCable from 'actioncable';
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';


const NotificationIndex = (props) => {
  timeago.register('es', es )
  const notificationSoundPath = props?.notificationSoundPath;
  const current_user = props?.current_user;
  const customer = props?.customer;
  const notificationsLimit = 4;

  const [notifications, setNotifications] =  useState(props?.notifications || []);

  const handleAddNotification = (data) => {
    let _notifications = [...notifications];
    setNotifications([data, ..._notifications]);
    playNotificationSound()
  }

  const [playNotificationSound] = useSound(
    notificationSoundPath, { volume: 0.5 }
  )

  const getNotificationsNotReviewed = () => {
    return _.filter(notifications, notification => !notification.reviewed).length
  }

  const drawNotification = (notification) => {
    return(
      <a
        href={ Routes.notification_path(notification?.id) }
        className={ `dropdown-item border-bottom pt-2 pb-2 ${ notification.reviewed ? '' : 'bg-light' }`}
        key={ `notification-list-${ notification.id }` }
      >
        <div className="media">
          <div className="media-body">
            <div className="row d-flex align-items-center">
              <div className="col-md-2">
                <span
                  className={ `mr-3` }
                  style={{ fontSize: 'xx-large'}}
                  dangerouslySetInnerHTML={{ __html: notification.icon }}
                />
              </div>
              <div className="col-md-10">
                <h3 className="dropdown-item-title text-wrap">
                  { notification?.title }
                {/*<span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>*/}
                </h3>
                <p className="text-sm text-muted">{ notification.message }</p>
                <small className="text-muted">
                  <i className="far fa-clock mr-1"></i>
                  <TimeAgo
                    datetime={ notification.created_at }
                    locale={ 'es' }
                  />
                </small>
              </div>
            </div>
          </div>
        </div>
      </a>
    )
  }

  const drawNotificationList = () => {
    let _notifications = _.take(notifications, notificationsLimit);
    if(_notifications.length > 0){
      return _.map(_notifications, notification => drawNotification(notification) )
    } else {
      return(
        <div className="row">
          <div className="col-12">
            <div className="alert alert-info text-center mr-3 ml-3 mt-3">
              No tienes notificaciones sin leer.
            </div>
          </div>
        </div>
      )
    }
  }

  // const drawNotificationListOrLabel = () => {

  // }

  const drawAllViewNotificationLink = () => {
    if(notifications.length > 0){
      return(
        <a
          href={ Routes.notifications_path() }
          className="dropdown-item dropdown-footer"
        >
          Ver todas las notificaciones
        </a>
      )
    }
  }

  const cable = ActionCable.createConsumer(App.cable.url);

  const drawNotificationNotReviewedCounter = () => {
    if(getNotificationsNotReviewed() > 0){
      return(
        <span className="badge badge-danger navbar-badge">
          { getNotificationsNotReviewed() }
        </span>
      )
    }
  }

  return(
    <ActionCableProvider cable={ cable }>
      <li className="nav-item dropdown">
        <a className="nav-link" data-toggle="dropdown" href="#">
          <i className="far fa-bell fa-lg text-warning"></i>
          { drawNotificationNotReviewedCounter() }
        </a>
        <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right" id="notification-dropdown">
          <ActionCableConsumer
            channel={{
              channel: 'NotificationChannel',
              user_id: props?.current_user?.id,
              customer_id: props?.customer?.id
            }}
            onReceived={ handleAddNotification }
        />
          { drawNotificationList() }
          {/*<div className="dropdown-divider"></div>*/}
          { drawAllViewNotificationLink() }
        </div>
      </li>
    </ActionCableProvider>

  )
}

export default NotificationIndex;
