import React, { useState } from "react";
import Select from "react-select";
import Cleave from "cleave.js/react";
import _ from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Input,
  FormGroup,
} from "reactstrap";
import FieldWrapper from "../commons/FiledWrapper";
import { saveDocumentBulkReuqest } from "./Axios";
import { handleGroupByDocumentTypeHelper } from "../groups/helper";
import { dateFormat } from "../commons/form";

const NewDocumentBulkRequest = ({
  documentTypes,
  people,
  distributionLists,
  groups,
}) => {
  const [documentType, setDocumentType] = useState({});
  const [workers, setWorkers] = useState([]);
  const [distribution, setDistribution] = useState([]);
  const [formErrors, setFormErrores] = useState({});
  const [_groups, setGroups] = useState([]);
  const [selectByPeople, setSelectByPeople] = useState(true);
  const [selectByDistributionList, setSelectByDistributionList] =
    useState(false);
  const [expiresAt, setExpiresAt] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!documentType.value)
      newErrors.documentType = ["Debe seleccionar el tipo de documento!"];
    setFormErrores(newErrors);
    return newErrors;
  };

  const handleSubmit = () => {
    const errors = validateForm();
    setSubmitting(true);

    if (!_.isEmpty(errors)) return;

    let data = new FormData();
    data.append("document_bulk_request[document_type_id]", documentType.value);
    _.map(workers, worker => {
      data.append(
        "document_bulk_request[person_ids][]",
        parseInt(worker.value)
      );
    });

    data.append(
      "document_bulk_request[select_by_people]",
      selectByPeople || false
    );
    data.append(
      "document_bulk_request[select_by_distribution_list]",
      selectByDistributionList || false
    );

    _.map(distribution, function (distribution) {
      data.append(
        "document_bulk_request[distribution_list_ids][]",
        distribution.value
      );
    });

    _.map(_groups, function (group) {
      data.append("document_bulk_request[group_ids][]", group.value);
    });

    if (expiresAt.trim() != "") {
      data.append("document_bulk_request[expires_at]", expiresAt);
    }

    saveDocumentBulkReuqest(data, response => {
      if (response.status === 201) {
        window.location.href = "/documents";
      } else if (response.status === 422) {
        setErrors(response.data);
        setSubmitting(false);
      }
    });
  };

  const handleSelectByPeople = event => {
    let checked = event.target.checked;
    setSelectByPeople(checked);
    setDistribution([]);
    setSelectByDistributionList(!checked);
  };

  const handleSelectByDistributionList = event => {
    let checked = event.target.checked;
    setSelectByDistributionList(checked);
    setWorkers([]);
    setSelectByPeople(!checked);
  };

  const setErrors = errors => {
    const newErrors = {};
    newErrors.expiresAt = errors.expires_at;
    setFormErrores(newErrors);
  };

  const drawSelectOptionGroup = () => {
    return _.map(groups || [], function (group) {
      return {
        value: group.id,
        label: group.name,
      };
    });
  };

  const getDocumentTypeById = id => {
    return _.find(documentTypes, { id: parseInt(id) });
  };

  const handleDocumentType = data => {
    let oldDocumentType = documentType?.value;

    setDocumentType(data);

    let _groupIds = handleGroupByDocumentTypeHelper(
      _.map(_groups, "value"),
      getDocumentTypeById(data?.value),
      getDocumentTypeById(oldDocumentType)
    );

    let newGroups = _.filter(groups, function (group) {
      return _.includes(_groupIds.map(String), String(group.id));
    });

    let newOptionsGroupsSelected = _.map(newGroups, function (g) {
      return {
        label: g.name,
        value: g.id,
      };
    });

    setGroups(newOptionsGroupsSelected);
  };

  const drawDocumentTypeOptionsSelect = () => {
    return _.map(documentTypes, function (dt) {
      return { value: dt.id, label: dt.name };
    });
  };

  const drawPeopleList = () => {
    if (selectByPeople) {
      return (
        <div className="row">
          <div className="col-12 mb-3">
            <FormGroup>
              <Label className="required">
                {I18n.t(
                  "activerecord.attributes.document_bulk_request.person_ids"
                )}
              </Label>
              <FieldWrapper errors={formErrors.workers}>
                <Select
                  isMulti
                  options={people}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={`-- ${I18n.t(
                    "activerecord.attributes.document_bulk_request.select_by_person"
                  )} --`}
                  onChange={value => setWorkers(value)}
                  name={`document_bulk_request[person_ids][]`}
                  id="document_bulk_request_person_ids"
                />
                <small className="form-text text-muted ml-2 mt-2">
                  {I18n.t("document_bulk_requests.form.people_alert")}
                </small>
              </FieldWrapper>
            </FormGroup>
          </div>
        </div>
      );
    }
  };

  const drawDistributionList = () => {
    if (selectByDistributionList) {
      return (
        <div className="row">
          <div className="col-12 mb-3">
            <FormGroup>
              <Label className="required">
                {I18n.t(
                  "activerecord.attributes.document_bulk_request.distribution_list_ids"
                )}
              </Label>
              <FieldWrapper errors={formErrors.distribution}>
                <Select
                  isMulti
                  options={distributionLists}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={`-- ${I18n.t(
                    "activerecord.attributes.document_bulk_request.select_by_distribution_list"
                  )} --`}
                  onChange={value => setDistribution(value)}
                  name={`document_bulk_request[distribution_list_ids][]`}
                  id="document_bulk_request_distribution_list_ids"
                />
                <small className="form-text text-muted ml-2 mt-2">
                  {I18n.t(
                    "document_bulk_requests.form.distribution_list_alert"
                  )}
                </small>
              </FieldWrapper>
            </FormGroup>
          </div>
        </div>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col md="12">
          <Card className="bg-white mb-3">
            <CardBody>
              <Row>
                <Col lg="12" className="d-flex align-items-center">
                  <div className="d-block">
                    <h5 className="text-primary font-weight-bold mb-1">
                      <i
                        className="fas fa-file-upload mr-2"
                        aria-hidden="true"
                      />
                      Nueva solicitud masiva de documentos
                    </h5>
                    <p className="text-muted">
                      <i className="fas fa-info-circle mr-1" />
                      Aquí encontrará la funcionalidad para solicitud masiva de
                      documentos, esta le permitirá solicitar un documento a
                      todos sus trabajadores.
                    </p>
                  </div>
                  <div className="text-right">
                    <Button type="button" href="/documents" color="secondary">
                      Volver
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col md="12" className="mb-3">
              <Label className="required">Tipo de documento</Label>
              <FieldWrapper errors={formErrors.documentType}>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={drawDocumentTypeOptionsSelect()}
                  placeholder="-- Seleccionar tipo de documento --"
                  onChange={value => handleDocumentType(value)}
                />
              </FieldWrapper>
            </Col>
          </Row>

          <Row>
            <Col className="col-sm-12 col-lg-6 mb-3">
              <FormGroup>
                <div className="custom-control custom-switch mt-4">
                  <Input
                    type="hidden"
                    name="document_bulk_request[select_by_people]"
                    value="0"
                  ></Input>

                  <Input
                    className="custom-control-input"
                    type="checkbox"
                    value="1"
                    name="document_bulk_request[select_by_people]"
                    id="document_bulk_request_select_by_people"
                    checked={selectByPeople}
                    onChange={e => handleSelectByPeople(e)}
                  />
                  <Label
                    className="custom-control-label label-bold"
                    for="document_bulk_request_select_by_people"
                  >
                    {I18n.t(
                      "activerecord.attributes.document_bulk_request.select_by_people"
                    )}
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <Col className="col-sm-12 col-lg-6 mb-3">
              <FormGroup>
                <div className="custom-control custom-switch mt-4">
                  <Input
                    className="custom-control-input"
                    type="checkbox"
                    value="1"
                    name="document_bulk_request[select_by_distribution_list]"
                    id="document_bulk_request_select_by_distribution_list"
                    checked={selectByDistributionList}
                    onChange={e => handleSelectByDistributionList(e)}
                  />

                  <Label
                    className="custom-control-label label-bold"
                    for="document_bulk_request_select_by_distribution_list"
                  >
                    {I18n.t(
                      "activerecord.attributes.document_bulk_request.select_by_distribution_list"
                    )}
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>

          {drawPeopleList()}

          {drawDistributionList()}

          <Row>
            <Col md="4">
              <FieldWrapper errors={formErrors.expiresAt}>
                <label htmlFor="expires_at">Fecha de Vencimiento</label>

                <Cleave
                  placeholder="DD/MM/AAAA"
                  options={{
                    delimiters: ["/", "/"],
                    blocks: [2, 2, 4],
                  }}
                  name="expires_at"
                  id="expires_at"
                  className="form-control"
                  value={expiresAt}
                  onChange={e => setExpiresAt(e.target.value)}
                />
              </FieldWrapper>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <Label>Grupos</Label>
              <Select
                isMulti
                options={drawSelectOptionGroup()}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="-- Seleccione Grupos --"
                onChange={value => setGroups(value)}
                value={_groups}
                isDisabled={
                  getDocumentTypeById(documentType?.value)?.group_exclusivity ||
                  false
                }
              />
            </Col>
          </Row>

          <Row className="text-center mt-3">
            <Col md="12">
              <Button
                type="button"
                onClick={handleSubmit}
                disabled={submitting}
                color="success"
              >
                {submitting
                  ? "Solicitando documentación"
                  : "Solicitar documentación"}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default NewDocumentBulkRequest;
