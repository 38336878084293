import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import _ from "lodash";
import DocumentBulkRequestItem from "./Item";

const DocumentBulkRequests = ({ title, documentBulkRequests }) => {
  return (
    <Container>
      <Row>
        <Col md="12">
          <Card className="bg-white mb-3">
            <CardBody>
              <Row>
                <Col lg="11" className="d-flex align-items-center">
                  <div className="d-block">
                    <h5 className=" text-primary font-weight-bold mb-1">
                      <i
                        className="fas fa-file-upload mr-2"
                        aria-hidden="true"
                      />
                      {title}
                    </h5>
                    <p className="text-muted">
                      <i className="fas fa-info-circle mr-1" />
                      Aqui encontrará el listado de solicitudes masivas de
                      documentos que ha realizado y se encuentran con errores.
                    </p>
                  </div>
                </Col>
                <Col md="1" lg="1">
                  <Button color="secondary" href="/documents">
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {_.map(documentBulkRequests, bulkRequest => (
        <DocumentBulkRequestItem
          key={`bulk-request-${bulkRequest.id}`}
          documentBulkRequest={bulkRequest}
        />
      ))}
    </Container>
  );
};

export default DocumentBulkRequests;
