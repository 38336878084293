import axios from "axios";

export const uploadReceipt = (data, callback) => {
  const id = data.get("purchase[id]");

  var promise = axios({
    method: "PATCH",
    url: `/purchases/${id}/upload_receipt.json`,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
