import React, { useCallback, Fragment } from "react";
import update from 'immutability-helper'
import SignerForm from "./SignerForm";

const SignersList = ({ signers, handleSigners, signersOrderRequired, handleSignerRol }) => {

  const handleMoveSigner = useCallback(
    (dragIndex, hoverIndex) => {
      let _signers = [...signers];
      const dragSigner = signers[dragIndex];

      handleSigners(
        update(_signers, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragSigner]
          ]
        })
      );
    },
    [signers]
  );

  return (
    <Fragment>
      {_.map(signers, (signer, index) => (
        <SignerForm
          key={`signer-${index}`}
          signer={signer}
          signerIndex={index}
          handleMoveSigner={handleMoveSigner}
          signersOrderRequired={signersOrderRequired}
          handleSignerRol={handleSignerRol}
        />
      ))}
    </Fragment>
  );
};

export default SignersList;