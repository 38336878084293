import React, { useState, useRef, useEffect } from "react";
import Steps, { Step } from "rc-steps";
import Slider from "react-slick";
import uuid from "uuid";
import _ from "lodash";
import DocumentBulkAssignmentForm from "./Form";
import DocumentBulkAssignmentInformation from "./Information";
import DocumentBulkAssignmentPreview from "./Preview";

let DocumentBulkAssignmentWizard = ({
  documentBulkAssignment,
  data,
  documentBulkAssignmentErrors,
}) => {
  const sliderRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [documentBulkData, setDocumentBulkData] = useState({
    documentType: null,
    isTemplate: null,
    template: null,
    file: null,
    selectedByPeople: true,
    selectedByDistributionList: null,
    onlyForPerson: false,
    onlyForCompany: false,
    companySigners: [],
    personSigners: [],
    signersList: [],
    distributionLists: [],
    consent: null,
    exclude: false,
    groups: [],
    expiresAt: null,
    signatureExpiresRequired: false,
    signatureExpiresAt: null,
    signersOrderRequired: false,
  });
  const [submitting, setSubmitting] = useState(false);

  const {
    document_type_id,
    is_template,
    template_id,
    company_signer_ids,
    exclude,
    group_ids,
    only_for_company,
    only_for_person,
    select_by_distribution_list,
    select_by_people,
    signature_expires_required,
    signature_expires_at,
    expires_at,
    person_ids,
  } = documentBulkAssignment;

  useEffect(() => {
    const documentType =
      _.find(data.documentTypes, d => d.id == document_type_id) || null;
    const template = _.find(data.templates, t => t.id == template_id) || null;
    const isTemplate = is_template;
    const onlyForPerson = only_for_person;
    const onlyForCompany = only_for_company;
    const selectedByPeople = select_by_people;
    const selectedByDistributionList = select_by_distribution_list;
    const signatureExpiresRequired = signature_expires_required;
    const signatureExpiresAt = signature_expires_at;
    const expiresAt = expires_at;

    setDocumentBulkData({
      ...documentBulkData,
      documentType: documentType?.name,
      isTemplate,
      template,
      onlyForPerson,
      onlyForCompany,
      exclude,
      selectedByPeople,
      selectedByDistributionList,
      signatureExpiresRequired,
      signatureExpiresAt,
      expiresAt,
    });
  }, []);

  const steps = [
    { title: "Ingreso Información" },
    { title: "Chequeo Información" },
    { title: "Previsualización" },
  ];

  const previous = () => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
    sliderRef.current.slickGoTo(currentStep - 1);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
    sliderRef.current.slickGoTo(currentStep + 1);
  };

  const handleSubmit = event => {
    console.log("Sending...");
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
  };

  const getDataConfirmMessageSubmit = () => {
    if (
      documentBulkData.personSigners.length == 0 &&
      documentBulkData.selectedByPeople
    ) {
      return "¿Estás seguro de enviar el documento a TODOS los trabajadores?";
    } else if (
      documentBulkData.distributionLists.length == 0 &&
      documentBulkData.selectedByDistributionList
    ) {
      return "¿Estás seguro de enviar el documento a TODAS las listas de distribución?";
    } else if (
      documentBulkData.personSigners.length > 0 &&
      documentBulkData.selectedByPeople
    ) {
      return `¿Estás seguro de enviar el documento a ${documentBulkData.personSigners.length} trabajadores?`;
    } else if (
      documentBulkData.distributionLists.length > 0 &&
      documentBulkData.selectedByDistributionList
    ) {
      return `¿Estás seguro de enviar el documento a ${documentBulkData.distributionLists.length} listas de distribución?`;
    }
  };

  return (
    <div className="react-wizard">
      <header className="react-wizard-header">
        <h3 className=" text-center text-primary font-weight-bold mt-4">
          Envío masivo de Documentos
        </h3>
        <Steps current={currentStep} className="mt-5">
          {steps.map(step => (
            <Step title={step.title} key={uuid.v4()} />
          ))}
        </Steps>
      </header>
      <Slider {...settings} ref={sliderRef}>
        <div className="mt-5 mb-5">
          <DocumentBulkAssignmentForm
            documentBulkAssignment={documentBulkAssignment}
            data={data}
            documentBulkAssignmentErrors={documentBulkAssignmentErrors}
            setDocumentBulkData={setDocumentBulkData}
            documentBulkData={documentBulkData}
          />
        </div>
        <div className="mt-5">
          <DocumentBulkAssignmentInformation
            documentBulkData={documentBulkData}
            data={data}
          />
        </div>
        <div className="mt-5">
          <DocumentBulkAssignmentPreview
            documentBulkData={documentBulkData}
            data={data}
          />
        </div>
      </Slider>
      <footer className="react-wizard-footer">
        <button
          type="button"
          className="btn btn-primary"
          disabled={currentStep === 0}
          onClick={previous}
        >
          Anterior
        </button>
        {currentStep < Object.keys(steps).length - 1 ? (
          <a type="button" href="#" className="btn btn-primary" onClick={next}>
            Siguiente
          </a>
        ) : (
          <button
            type="submit"
            className="btn btn-success"
            onClick={handleSubmit}
            data-title="Enviar documento"
            data-confirm={getDataConfirmMessageSubmit()}
            data-disable-with="Enviando..."
          >
            Enviar para firma
          </button>
        )}
      </footer>
    </div>
  );
};

export default DocumentBulkAssignmentWizard;
