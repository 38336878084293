import React, { Fragment, useState } from "react";
import Cleave from "cleave.js/react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Select from "react-select";
import Files from "react-butterfiles";
import { getDocumentsFromAdp, getPersonSendings, saveBulk } from "./axios";
import { handleGroupByDocumentTypeHelper } from "../../groups/helper";
import FieldWrapper from "../../commons/FiledWrapper";
import SignerModalRequireIdentityVerification from '../../signers/modal_require_identity_verification';
import SignersList from "./SignersList";

const DocumentBulkUploadForm = props => {
  const {
    documentTypes,
    enableAdpIntegration,
    enableFAO,
    currentUser,
    currentCustomer
  } = props;

  const maxSize = 2;
  const maxFiles = 100;

  const [bulk, setBulk] = useState(props?.bulk);
  const [documentType, setDocumentType] = useState({});
  const [expiresAt, setExpiresAt] = useState(null);
  const [signatureRequired, setSignatureRequired] = useState(false);
  const [companySignatureRequired, setCompanySignatureRequired] =
    useState(false);
  const [signatureExpiresRequired, setSignatureExpiresRequired] =
    useState(false);
  const [signersOrderRequired, setSignersOrderRequired] = useState(false);
  const [companySigners, setCompanySigners] = useState([]);
  const [signatureExpiresAt, setSignatureExpiresAt] = useState(null);
  const [groups, setGroups] = useState([]);
  const [personSendings, setPersonSendings] = useState([]);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [fileErrors, setFileErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [signers, setSigners] = useState([]);
  const [documentsFromAdp, setDocumentsFromAdp] = useState(false);

  const [requireIdentifyVerification, setRequireIdentifyVerification] = useState(false);
  const [requireIdentityVerificationModalWasShown, setRequireIdentityVerificationModalWasShown] = useState(false);
  const [openRequireIdentityVerificationModal, setOpenRequireIdentityVerificationModal] = useState(false);

  const getDocumentTypeById = id => {
    return _.find(documentTypes, type => {
      return String(type.id) == String(id);
    });
  };

  const getDocument = filename => {
    if (documentsFromAdp) {
      return _.find(files, file => file.split(/[_.]+/)[0] == filename);
    } else {
      return _.find(files, file => file.name.split(/[_.]+/)[0] == filename);
    }
  };

  const handleDocumentType = e => {
    let newGroups = [...groups];
    const groupIds = handleGroupByDocumentTypeHelper(
      _.map(groups, group => group.value),
      getDocumentTypeById(e.value),
      getDocumentTypeById(documentType.value)
    );

    setDocumentType(e);

    newGroups = _.filter(drawSelectGroupOptions(), option => {
      return (groupIds || []).map(String).includes(String(option.value));
    });
    setGroups(newGroups);
    const dt = getDocumentTypeById(e.value);

    if (dt.default_signers_order) {
      setSignatureRequired(true);
      setCompanySignatureRequired(true);
      setSignersOrderRequired(true);
      handleDefaultSigners(dt);
    }
  };

  const handleRequireIdentityVerificationModal = (show) => {
    if(!requireIdentityVerificationModalWasShown || !show){
      setOpenRequireIdentityVerificationModal(show)
    }
  }

  const handleInputSwitchVerification = (e) => {
    setRequireIdentityVerificationModalWasShown(true)
    handleRequireIdentityVerificationModal(true)
  }

  const handleDefaultSigners = d => {
    const signersOrder = d.signers_order;
    const newSigners = [];
    const newCompanySigners = [];

    _.map(signersOrder, signer => {
      if (signer.type === "company") {
        const companySigner = _.find(
          props.companySigners,
          cs => cs.id == signer.id
        );
        const label = `${companySigner.name} - (${companySigner.email})`;

        newSigners.push({
          type: "company",
          value: companySigner.id,
          label,
          rol: signer.rol,
        });

        newCompanySigners.push({
          type: "company",
          value: companySigner.id,
          label,
          rol: signer.rol,
        });
      } else {
        newSigners.push({
          type: "person",
          value: null,
          label: "Trabajador",
          rol: "Firmante",
        });
      }
    });

    setSigners(newSigners);
    setCompanySigners(newCompanySigners);
  };

  const handleSignatureRequired = checked => {
    setSignatureRequired(checked);
    let newSigners = [...signers];

    if (!checked) {
      setSignersOrderRequired(false);
      newSigners = _.filter(newSigners, signer => signer.type != "person");
      setSigners(newSigners);
    } else {
      newSigners.push({
        type: "person",
        value: null,
        label: "Trabajador",
        rol: "Firmante",
      });
      setSigners(newSigners);
    }

    if (!checked && !companySignatureRequired) {
      setSignatureExpiresRequired(false);
      setSignatureExpiresAt(null);
    }
  };

  const handleSigners = newSigners => {
    setSigners(newSigners);
  };

  const handleDeleteAllFiles = () => {
    let _bulk = { ...bulk };
    _bulk["person_sendings"] = [];

    setBulk(_bulk);
  };

  const handleFiles = responseFiles => {
    setLoading(true);
    setFiles(responseFiles);

    let formData = new FormData();
    let identifiers = [];

    if (documentsFromAdp) {
      identifiers = _.map(responseFiles, file => file.split(/[_.]+/)[0]);
    } else {
      identifiers = _.map(responseFiles, file => file.name.split(/[_.]+/)[0]);
    }

    _.map(identifiers, identifier => {
      formData.append("filename[]", identifier);
    });

    getPersonSendings(formData, response => {
      if (response.status === 200) {
        // Ordena según person sending,
        // primer lugar los documentos que no tienen una contratación
        // Segundo los que tiene más de una contratación
        // Tercero los que tienen solo una contratación
        let data = _.sortBy(response.data, function (worker) {
          if (worker.person_sendings.length == 0) {
            return -1;
          } else if (worker.person_sendings.length > 1) {
            return 0;
          } else {
            return 1;
          }
        });

        let newPersonSendings = [...personSendings];
        newPersonSendings = _.map(data, function (worker) {
          let _data = { number: worker.number, name: worker.name };
          _data["filename"] = worker.filename;

          _data["person_sendings"] = worker.person_sendings;

          if (_data["person_sendings"].length == 1) {
            _data["person_sendings"][0]["selected"] = 1;
          } else if (_data["person_sendings"].length > 1) {
            let _index = _.findIndex(
              _data["person_sendings"],
              function (sending) {
                return _.toLower(sending.code) == _.toLower(worker.filename);
              }
            );

            if (_index >= 0) {
              _data["person_sendings"][_index]["selected"] = 1;
            } else {
              _data["person_sendings"][_data["person_sendings"].length - 1][
                "selected"
              ] = 1;
            }
          }

          return _data;
        });

        setLoading(false);
        setPersonSendings(newPersonSendings);
      }
    });
  };

  const getSubmitFormData = () => {
    let formData = new FormData();

    const personIndex = _.findIndex(
      signers,
      signer => signer.type === "person"
    );

    formData.append(
      "document_bulk_upload[document_type_id]",
      documentType.value || ""
    );
    formData.append(
      "document_bulk_upload[signature_required]",
      signatureRequired || 0
    );
    formData.append(
      "document_bulk_upload[company_signature_required]",
      companySignatureRequired || 0
    );
    formData.append(
      "document_bulk_upload[signature_expires_required]",
      signatureExpiresRequired || 0
    );
    formData.append("document_bulk_upload[expires_at]", expiresAt);
    formData.append(
      "document_bulk_upload[signers_order_required]",
      signersOrderRequired
    );
    formData.append(
      "document_bulk_upload[signature_expires_at]",
      signatureExpiresAt
    );
    formData.append("document_bulk_upload[person_signer_order]", personIndex);
    formData.append(
      "document_bulk_upload[documents_from_adp]",
      documentsFromAdp
    );

    let workers = personSendings || [];

    _.each(workers, (worker, index) => {
      let sending = _.find(worker.person_sendings, { selected: 1 });

      if (sending) {
        formData.append(
          `document_bulk_upload[document_bulk_upload_person_sendings_attributes][${index}][person_sending_id]`,
          sending.id
        );

        formData.append(
          `document_bulk_upload[document_bulk_upload_person_sendings_attributes][${index}][filename]`,
          documentsFromAdp
            ? getDocument(worker.filename)
            : getDocument(worker.filename)?.name
        );
      }

      if (!documentsFromAdp) {
        formData.append(
          "document_bulk_upload[files][]",
          getDocument(worker.filename)?.src?.file
        );
      }
    });

    if (companySignatureRequired) {
      _.each(signers, (signer, index) => {
        if (signer.type !== "person") {
          formData.append(
            "document_bulk_upload[bulk_users_attributes][][user_id]",
            signer.value
          );
          formData.append(
            "document_bulk_upload[bulk_users_attributes][][label]",
            signer.rol
          );
          formData.append(
            "document_bulk_upload[bulk_users_attributes][][order]",
            index
          );
        }
      });
    } else {
      formData.append("document_bulk_upload[bulk_users_attributes]", []);
    }

    if (groups.length > 0) {
      _.each(groups, group => {
        formData.append("document_bulk_upload[group_ids][]", group.value);
      });
    }

    return formData;
  };

  const getAdpDocuments = () => {
    const newErrors = { ...errors };
    if (!documentType.value) {
      newErrors.documentTypeId = ["Debe agregar un tipo de documento."];
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setLoading(true);

    let data = new FormData();
    data.append("document_type_id", documentType.value);
    getDocumentsFromAdp(data, response => {
      setLoading(false);
      if (response.status === 200) {
        handleFiles(response.data);
      } else if (response.status === 404) {
        newErrors.enableAdpIntegration = [
          `Hubo un error al intentar conectarse con ADP, favor informa el siguiente error: ${response.data}.`,
        ];
        setErrors(newErrors);
        return;
      }
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);
    let formData = getSubmitFormData();

    saveBulk(formData, response => {
      if (response.status == 201) {
        window.location.href = "/documents";
      } else {
        let _errors = { ...errors };
        let data = response?.data;
        _errors["documentTypeId"] = data?.document_type || [];
        _errors["bulkUsers"] = data?.bulk_users || [];
        _errors["personSendings"] =
          data?.document_bulk_upload_person_sendings || [];
        _errors["expiresAt"] = data?.expires_at || [];
        _errors["signatureExpiresAt"] = data?.signature_expires_at || [];

        setErrors(_errors);
        setSubmitting(false);
      }
    });
  };

  const handleCompanySignatureRequired = checked => {
    setCompanySignatureRequired(checked);
    if (!checked) {
      setSignersOrderRequired(false);
      setCompanySigners([]);
      let newSigners = _.filter(signers, signer => signer.type !== "company");
      setSigners(newSigners);
    }

    if (!checked && !signatureRequired) {
      setSignatureExpiresRequired(false);
      setSignatureExpiresAt(null);
    }
  };

  const handleCompanySigners = value => {
    setCompanySigners(value);
    let newSigners = [...signers];
    newSigners = _.filter(signers, signer => signer.type !== "company");
    newSigners = _.union(newSigners, value);
    setSigners(newSigners);
  };

  const handleSignerRol = (e, index) => {
    const newSigners = [...signers];
    newSigners[index]["rol"] = e.target.value;
    setSigners(newSigners);
  };

  const handlePersonSending = (number, event) => {
    let value = event?.target?.value;
    let newPersonSendings = [...personSendings];

    let _index = _.findIndex(
      newPersonSendings,
      sending => _.toLower(sending.number) == _.toLower(number)
    );

    _.each(newPersonSendings[_index]["person_sendings"], function (sending) {
      if (String(sending.id) == String(value)) {
        sending["selected"] = 1;
      } else {
        sending["selected"] = 0;
      }
    });

    setPersonSendings(newPersonSendings);
  };

  const handleDeleteDocument = index => {
    let newPersonSendings = [...personSendings];

    let data = _.remove(newPersonSendings, (sending, i) => {
      return i == index;
    });

    setPersonSendings(newPersonSendings);
  };

  const drawSelectDocumentTypeOptions = () => {
    return _.map(documentTypes, function (documentType) {
      return {
        value: documentType.id,
        label: documentType.name,
        adpId: documentType.adpId,
      };
    });
  };

  const drawSelectCompanySignersOptions = () => {
    return _.map(props?.companySigners, (signer, index) => {
      return {
        type: "company",
        value: signer.id,
        label: `${signer.name} - (${signer.email})`,
        rol: "Firmante",
      };
    });
  };

  const drawSelectGroupOptions = () => {
    return _.map(props?.groups, function (group) {
      return {
        value: group.id,
        label: group.name,
      };
    });
  };

  const drawFileErrorText = error => {
    switch (error) {
      case "multipleMaxCountExceeded":
        return "Excedió el máximo de archivos permitidos!";
      case "multipleMaxSizeExceeded":
        return "Exedió el tamaño max. permitido entre todos los archivos!";
      case "maxSizeExceeded":
        return "Excedió el tamaño max. del archivo!";
      case "unsupportedFileType":
        return "Este formato no está permitido!";
      default:
        break;
    }
  };

  const drawFilesInput = () => {
    return (
      <div className="row">
        <div className="col-12">
          <Files
            multiple={true}
            maxSize={`${maxSize}mb`}
            multipleMaxSize={`${maxSize * maxFiles}mb`}
            multipleMaxCount={maxFiles}
            accept={[
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "text/rtf",
            ]}
            onError={errors => {
              setFileErrors(errors);
            }}
            onSuccess={files => {
              handleFiles(files);
            }}
          >
            {({ browseFiles, getDropZoneProps }) => {
              return (
                <Row className="mt-3">
                  <Col xs="12">
                    <span className="font-weight-bold text-muted">
                      Formatos permitidos: pdf, doc, docx, rtf
                    </span>
                    <div
                      className="text-center pt-5"
                      {...getDropZoneProps({
                        style: {
                          minHeight: 150,
                          border: "2px lightgray dashed",
                        },
                      })}
                    >
                      <span className="align-middle">
                        {`${personSendings.length} documento(s) cargado(s)`}
                      </span>
                    </div>
                    <FieldWrapper errors={errors?.personSendings} />
                  </Col>

                  {drawFilesErrors()}

                  <Col xs="12" className="mt-3">
                    {enableAdpIntegration &&
                    documentsFromAdp &&
                    documentType.adpId ? (
                      <Button
                        className="ml-2"
                        outline
                        color="success"
                        size="sm"
                        onClick={getAdpDocuments}
                        disabled={submitting || loading}
                      >
                        <i className="fas fa-download"></i>
                        &nbsp; Documentos ADP
                      </Button>
                    ) : (
                      <Button
                        outline
                        color="info"
                        size="sm"
                        onClick={browseFiles}
                        disabled={submitting || loading}
                      >
                        <i className="fas fa-plus"></i>
                        &nbsp; Agregar Archivos
                      </Button>
                    )}

                    <Button
                      className="ml-2"
                      outline
                      color="danger"
                      size="sm"
                      onClick={handleDeleteAllFiles}
                      disabled={submitting || loading}
                    >
                      <i className="fas fa-trash-alt" />
                      &nbsp; Eliminar Archivos
                    </Button>

                    <Button
                      className="ml-2"
                      color="primary"
                      size="sm"
                      onClick={handleSubmit}
                      disabled={submitting || loading}
                    >
                      <i className="fas fa-save" />
                      &nbsp;
                      {submitting ? "Enviando ..." : "Enviar Documentos"}
                    </Button>
                  </Col>
                  <Col className="mt-1">
                    <FieldWrapper errors={errors.enableAdpIntegration} />
                  </Col>
                </Row>
              );
            }}
          </Files>
        </div>
      </div>
    );
  };

  const drawFilesErrors = () => {
    if (fileErrors.length > 0) {
      return (
        <div className="col-12 mt-3">
          <ol className="text-danger">
            {_.map(_.take(fileErrors, 10), function (error, index) {
              return error.file ? (
                <li key={error.file.name}>
                  {error.file.name} - {drawFileErrorText(error.type)}
                </li>
              ) : (
                <li key={`error-file-${index}`}>
                  {drawFileErrorText(error.type)}
                </li>
              );
            })}
          </ol>
        </div>
      );
    }
  };

  const drawLoading = () => {
    if (loading || submitting) {
      return (
        <div className="spinner spinner-absolute">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      );
    }
  };

  const drawWorkerName = worker => {
    if (worker.name == null && worker.number == null) {
      return (
        <h6 className="text-danger font-weight-bold d-block text-capitalize mb-0 h6">
          {"No existe el trabajador"}
        </h6>
      );
    } else {
      return (
        <h6 className="text-dark font-weight-bold d-block text-capitalize mb-0 h6">
          {worker.name} - {worker.number}
        </h6>
      );
    }
  };

  const drawPersonSendings = (worker, person_sendings) => {
    if (person_sendings.length == 1) {
      let person_sending = person_sendings[0];
      return (
        <span className="text-muted text-small small-text">
          {person_sending.company} - {person_sending.cost_center} - { person_sending.start_date_to_s } - { person_sending.end_date_to_s }
        </span>
      );
    } else if (person_sendings.length > 1) {
      let _sending_id = _.find(
        person_sendings,
        sending => sending.selected
      )?.id;

      return (
        <FormGroup>
          <select
            name=""
            id=""
            className="form-control"
            value={_sending_id}
            onChange={e => handlePersonSending(worker.number, e)}
          >
            {_.map(person_sendings, function (person_sending, index) {
              return (
                <option
                  value={person_sending.id}
                  key={`person_sending_list_${person_sending.id}`}
                >
                  {person_sending.company} - {person_sending.cost_center} - { person_sending.start_date_to_s } - { person_sending.end_date_to_s }
                </option>
              );
            })}
          </select>
        </FormGroup>
      );
    } else {
      return (
        <span className="text-danger">No tiene una contratación vigente</span>
      );
    }
  };

  const drawModalRequireIdentityVerification = () => {
    if(true){
      return(
        <SignerModalRequireIdentityVerification
          openModal={ openRequireIdentityVerificationModal || false }
          callbackOpenRequireIdentityVerificationModal={ handleRequireIdentityVerificationModal }
        />
      )
    }
  }

  const showIdentityVerificationBanner = () => {
    if(requireIdentityVerificationModalWasShown){
      return(
        <div className="gradient-border-box mt-2 col-12">
          <div className="alert alert-light bg-white p-3 d-flex align-items-md-center material-shadow border-0">
            <div className="row w-100">
              <div className="col-md-9">
                <i className="fas fa-id-badge fa-2x fa-pull-left gradient-text-4t"></i>
                <div>
                  <span className="small d-block">Potencia la Verificación de Identidad de tus firmantes.</span>
                  Desbloquea la <strong>Verificación de Identidad Avanzada</strong>.
                </div>
              </div>
              <div className="col-md-3 text-right">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={ e => setOpenRequireIdentityVerificationModal(true) }
                >
                  Más info
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const drawDocument = (worker, index) => {
    const filename = documentsFromAdp
      ? getDocument(worker.filename)
      : getDocument(worker.filename)?.name;

    return (
      <Card className="mb-2" key={_.uniqueId("worker_")}>
        <CardBody className="text-dark">
          <div className="float-right">
            <Button
              type="button"
              color="link"
              className="text-danger"
              area-label="Borrar Documento"
              title="Borrar Documento"
              onClick={e => handleDeleteDocument(index, e)}
            >
              <i className="fas fa-trash h6" />
            </Button>
          </div>
          <Row>
            <Col md="6">
              {drawWorkerName(worker)}

              <span className="text-muted text-small small-text d-block">
                Documento: {filename}
              </span>
            </Col>
            <Col md="6">
              {drawPersonSendings(worker, worker.person_sendings)}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label for="documentType" className="required">
                      Tipo de Documento
                    </Label>
                    <FieldWrapper errors={errors?.documentTypeId}>
                      <Select
                        className="basic-select"
                        classNamePrefix="select"
                        options={drawSelectDocumentTypeOptions()}
                        placeholder="-- Selecciones un tipo de documento --"
                        onChange={handleDocumentType}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="expiresAt">Fecha de vencimiento</Label>
                    <FieldWrapper errors={errors?.expiresAt}>
                      <Cleave
                        placeholder="DD/MM/AAAA"
                        options={{
                          date: true,
                          delimiter: "/",
                          datePattern: ["d", "m", "Y"],
                        }}
                        id="expiresAt"
                        className="form-control"
                        value={expiresAt}
                        onChange={e => setExpiresAt(e.target.value)}
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div className="custom-control custom-switch">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      id="signatureRequired"
                      checked={signatureRequired}
                      onChange={e => handleSignatureRequired(e.target.checked)}
                    />
                    <Label
                      for="signatureRequired"
                      className="custom-control-label"
                    >
                      Requiere firma del Trabajador?
                    </Label>
                  </div>
                </Col>
                {
                  signatureRequired && enableFAO == false && (
                    <Col>
                      <div className="custom-control custom-switch">
                        <Input
                          type="checkbox"
                          className="custom-control-input signs_require_identity_verification"
                          id="require_identity_verification"
                          checked={requireIdentifyVerification}
                          onChange={e => handleInputSwitchVerification(e.target.checked)}
                        />
                        <Label
                          for="require_identity_verification"
                          className="custom-control-label"
                        >
                          <i className="fas fa-lock gradient-text-4t mr-2"></i>
                          { I18n.t("activerecord.attributes.signer.require_identity_verification") }
                          <span className="badge badge-primary ms-2 bg-gradient-4t">{ I18n.t('actions.new') }</span>
                        </Label>
                      </div>
                    </Col>
                  )
                }
                <Col>
                  <div className="custom-control custom-switch">
                    <Input
                      type="checkbox"
                      className="custom-control-input"
                      id="companySignatureRequired"
                      checked={companySignatureRequired}
                      onChange={e =>
                        handleCompanySignatureRequired(e.target.checked)
                      }
                    />
                    <Label
                      for="companySignatureRequired"
                      className="custom-control-label"
                    >
                      Requiere firma del Empleador?
                    </Label>
                  </div>
                </Col>
                {(signatureRequired || companySignatureRequired) && (
                  <Col>
                    <div className="custom-control custom-switch">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="signatureExpiresRequired"
                        checked={signatureExpiresRequired}
                        onChange={e =>
                          setSignatureExpiresRequired(e.target.checked)
                        }
                      />
                      <Label
                        for="signatureExpiresRequired"
                        className="custom-control-label"
                      >
                        Requiere fecha y hora límite para firmar?
                      </Label>
                    </div>
                  </Col>
                )}
                {signatureRequired && companySignatureRequired && (
                  <Col>
                    <div className="custom-control custom-switch">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="signersOrderRequired"
                        checked={signersOrderRequired}
                        onChange={e =>
                          setSignersOrderRequired(e.target.checked)
                        }
                      />
                      <Label
                        for="signersOrderRequired"
                        className="custom-control-label"
                      >
                        Requiere orden de firma?
                      </Label>
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                { showIdentityVerificationBanner() }
                { drawModalRequireIdentityVerification() }
              </Row>
              {companySignatureRequired && (
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <Label for="companySigners">Firmantes de Empresa</Label>
                      <Select
                        isMulti
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="-- Seleccione Firmantes --"
                        options={drawSelectCompanySignersOptions()}
                        onChange={handleCompanySigners}
                        value={companySigners}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs="12">
                  <DndProvider backend={HTML5Backend}>
                    <SignersList
                      signers={signers}
                      handleSigners={handleSigners}
                      signersOrderRequired={signersOrderRequired}
                      handleSignerRol={handleSignerRol}
                    />
                  </DndProvider>
                </Col>
              </Row>
              {signatureExpiresRequired && (
                <Row className="mt-3">
                  <Col md="6">
                    <FormGroup>
                      <Label for="signatureExpiresAt" className="required">
                        Fecha y hora límite de firma
                      </Label>
                      <FieldWrapper errors={errors?.signatureExpiresAt}>
                        <Cleave
                          placeholder="DD/MM/AAAA hh:mm"
                          options={{
                            delimiters: ["/", "/", " ", ":"],
                            blocks: [2, 2, 4, 2, 2],
                          }}
                          id="signatureExpiresAt"
                          className="form-control"
                          value={signatureExpiresAt}
                          onChange={e => setSignatureExpiresAt(e.target.value)}
                        />
                      </FieldWrapper>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col md="12">
                  <FormGroup>
                    <Label for="groups">Grupos</Label>
                    <FieldWrapper errors={errors?.groups}>
                      <Select
                        id="groups"
                        isMulti
                        className="basic-single"
                        classNamePrefix="select"
                        options={drawSelectGroupOptions()}
                        placeholder="-- Seleccionar Grupos --"
                        onChange={value => setGroups(value)}
                        value={groups}
                        isDisabled={
                          getDocumentTypeById(documentType.value)
                            ?.group_exclusivity || false
                        }
                      />
                    </FieldWrapper>
                  </FormGroup>
                </Col>
              </Row>
              {enableAdpIntegration && documentType.adpId && (
                <Row>
                  <Col>
                    <div className="custom-control custom-switch">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="documentsFromAdp"
                        checked={documentsFromAdp}
                        onChange={e => setDocumentsFromAdp(e.target.checked)}
                      />
                      <Label
                        for="documentsFromAdp"
                        className="custom-control-label"
                      >
                        Descargar documentos desde ADP?
                      </Label>
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col xs="12">
                  <Alert color="info">
                    {documentsFromAdp ? (
                      <div>
                        <i className="fa fa-info-circle" /> Recuerde que se
                        realizará una descarga desde ADP de un máximo de 200
                        archivos, si tiene mas archivos del mismo tipo de
                        documento seleccionado, deberá realizar una nueva carga
                        masiva una vez finalizada.
                      </div>
                    ) : (
                      <Fragment>
                        <div>
                          <i className="fa fa-info-circle" /> Para agregar
                          archivos se debe arrastrar y soltar o hacer click en
                          Agregar Archivos (max. 100 archivos de 2mb c/u).
                        </div>
                        <div>
                          <i className="fa fa-info-circle" /> Recuerde que el
                          nombre del archivo debe tener el siguiente formato
                          RUT_texto_libre, el RUT es obligatorio y texto libre
                          opcional (ej. 11222333-4_liquidación_abril.pdf) o el
                          código de contratación (ej. 1234_anexo_sueldo.pdf).
                        </div>
                      </Fragment>
                    )}
                    <div>
                      <i className="fa fa-info-circle" /> Una vez que termine,
                      presione el boton Enviar para subir todos los archivos al
                      sistema.
                    </div>
                  </Alert>
                </Col>
              </Row>
              {drawFilesInput()}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <Card>
            <CardHeader>
              <i className="fas fa-eye" /> Previsualización de los documentos
            </CardHeader>
            <CardBody>
              {drawLoading()}
              <Alert color="info">
                <i className="fas fa-info-circle mr-1"></i>
                Si aparece un resultado en rojo, es porque el trabajador no fue
                encontrado entre sus registros, no tiene los permisos para
                asociar documentos a él y/o el trabajador no tiene una
                contratación vigente creada en la plataforma, por lo tanto el
                documento no será guardado en el sistema.
              </Alert>
              {_.map(personSendings || [], (worker, index) =>
                drawDocument(worker, index)
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DocumentBulkUploadForm;
