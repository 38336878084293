import React from "react";
import LazyComponent from "../lazy_component";
import ReactCodeInput from "react-code-input";

export default class CodeInput extends LazyComponent {
  constructor(props) {
    super(props);

    this.state = {
      code: props?.pin_code || "",
    };
  }

  handleInputChange(value) {
    let { code } = this.state;
    code = value;
    this.setState({ code });
  }

  render() {
    return (
      <React.Fragment>
        <ReactCodeInput
          type={this.props.customPinEnabled ? "password" : "text"}
          fields={6}
          autoFocus={false}
          onChange={this.handleInputChange}
          value={this.state.code}
        />
        <input
          name={`${this.props.form_name}[otp_code_token]`}
          type="hidden"
          value={this.state.code}
        />
      </React.Fragment>
    );
  }
}
