import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

const DocumentPreview = ({ document }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const renderPagination = () => {
    return (
      <div>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                disabled={pageNumber == 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                aria-label="Anterior"
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="ml-1">Anterior</span>
              </button>
            </li>

            <li className="page-item">
              <button
                className="page-link"
                disabled={pageNumber == numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                aria-label="Siguiente"
              >
                <span className="mr-1">Siguiente</span>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
        <div className="text-center text-primary">
          <small>
            Página {pageNumber} de {numPages}
          </small>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="table-responsive">
        <Document
          file={document}
          onLoadSuccess={params => setNumPages(params.numPages)}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {renderPagination()}
      </div>
    </div>
  );
};

export default DocumentPreview;
