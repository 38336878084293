import React, { useState } from 'react';
import {
  Modal, ModalBody, Row, Col, Button
} from 'reactstrap';
import verification_img from 'images/verification.png'

import { sendEmailRequireIdentityVerification } from './axios';

const SignerModalRequireIdentityVerification = props => {

  const { openModal, callbackOpenRequireIdentityVerificationModal } = props;

  const buttonActionDefaultValue = { text: 'Me interesa', disabled: false };
  const [buttonAction, setButtonAction] = useState(buttonActionDefaultValue);

  const [emailSent, setEmailSent] = useState(false)

  // console.log(openModal);

  const toggle = () => {
    callbackOpenRequireIdentityVerificationModal(!openModal)
  }

  const handleSendEmail = () => {
    let formData = new FormData()

    sendEmailRequireIdentityVerification(formData, response => {

      setButtonAction({
        text: 'Enviado información', disabled: true
      })

      if(response.status == 200){
        setEmailSent(true)
      } else {
      };

      setButtonAction(buttonActionDefaultValue)
    })
  }

  const showSentSuccessfully = () => {
    if(emailSent){
      return(
        <div className="alert alert-success">
          Hemos enviado tu solicitud a nuestro equipo ControlDoc, un ejecutivo de ventas tomará tu solicitud.
        </div>
      )
    }
  }

  return(
     <Modal isOpen={ openModal } toggle={ toggle }  size="lg">
        <ModalBody className="p-md-5">
          <div className="text-end">
            <button type="button" className="close" aria-label="Close" data-bs-dismiss="modal" onClick={toggle}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Row>
            <Col lg="6" md="12">
              <h6 className="font-weight-bold">
                <i className="fas fa-lock gradient-text-4t mr-2"></i>
                Desbloquea la Verificación de Identidad Avanzada.
              </h6>
              <h1 className="font-weight-bold fs-1">
                Potencia la Verificación de Identidad de tus firmantes.
              </h1>
              <p>Verifica la identidad de tus firmantes para casos o documentos sensibles que requieran validación de identidad.</p>
              <ul>
                <li>En línea, a través de desafío de preguntas y cédula de identidad.</li>
                <li>Aumenta el nivel de seguridad.</li>
              </ul>

              { showSentSuccessfully() }
              <button
                className="btn btn-primary"
                onClick={ e => handleSendEmail() }
              >
                { buttonAction.text }
              </button>
              <Button className="d-block" color="link" onClick={toggle}>Cerrar</Button>
            </Col>
            <Col lg="6" md="6">
              <img className="img-fluid" alt="" src={verification_img} />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
  )
}

export default SignerModalRequireIdentityVerification;