export const handleGroupByDocumentTypeHelper = (
  selectedGroupIds,
  currentDocumentType,
  oldDocumentType
) => {
  let newGroupIds = []

  if( currentDocumentType.group_exclusivity ){
    newGroupIds = _.map(currentDocumentType?.groups || [] , "id")
  } else {
    let oldGroupIds = _.map(oldDocumentType?.groups || [] , "id");

    let elements = _.remove(selectedGroupIds, function(e){
      return _.indexOf(oldGroupIds.map(String), String(e)) >= 0
    })

    newGroupIds = _.union(selectedGroupIds, _.map(currentDocumentType.groups, "id"))
  }

  return newGroupIds
}
