import moment from "moment";

export const getInputId = (formName, inputName) => {
  let _formName = formName.replace(/[\[\]']+/g, "_");
  let _inputName = inputName.replace(/[\[\]']+/g, "_");

  if (_formName[_formName.length - 1] != "_") {
    _formName = _formName + "_";
  }

  return `${_formName}${_inputName}`;
};

export const getInputName = (formName, inputName) => {
  let name;
  if (["number"].includes(inputName)) {
    name = `${formName}[my_user_attributes][${inputName}]`;
  } else {
    name = `${formName}[${inputName}]`;
  }

  return name;
};

export const dateTimeFormat = value => {
  return moment(value).format("DD/MM/YYYY HH:mm");
};

export const dateFormat = value => {
  return moment(value).format("DD/MM/YYYY");
};
