import axios from "axios";

export const showCompany = (company_id, callback) => {
  var promise = axios({
    method: 'get',
    url: `/companies/${company_id}.json`,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}

export const saveCompany = (data, callback) => {
  let method, url;

  if (data.get("company[id]")) {
    method = "patch";
    url = `/companies/${data.get("company[id]")}.json`;
  } else {
    method = "post";
    url = "/companies.json";
  }

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
