import React, { Fragment, useState, useEffect } from "react";
import Cleave from "cleave.js/react";
import Select from 'react-select';
import FieldWrapper from "../commons/FiledWrapper";
import { getInputId, getInputName, dateTimeFormat } from '../commons/form';

import CompanyCostCenterSelect from './CompanyCostCenterSelect';

const PersonSendingForm = ( props ) => {
  const [personSending, setPersonSending] = useState(props?.personSending)

  const formName = props?.formName || 'person_sending'
  const {
    companyCostCenterData,
    groups,
    customFieldHiringFolder,
    customer,
    hiringCauses
  } = props

  const [ companies, setCompanies ] = useState(companyCostCenterData?.companyList || []);

  const handlePersonSending = (key, event) => {
    let value = event?.target?.value;
    let _personSending = { ... personSending }

    if(key === 'cost_center_id' && value){
      let company = _.find(companies, function(_company){
        return _.find(_company.cost_centers, { "id": value } )
      });

      _personSending['company_id'] = company?.id;

      let _groupIds = handleGroupByCostCenter(
        _personSending.group_ids || [],
        getCostCenter(value),
        getCostCenter(_personSending.cost_center_id)
      )

      _personSending['group_ids'] = _groupIds;
    }

    _personSending[key] = value;

    setPersonSending(_personSending)
  }

  const getCostCenter = costCenterId => {
    let costCenters = _.flatMap(companies, "cost_centers");

    return _.find(costCenters, { id: parseInt(costCenterId)})
  }

  const handleGroupByCostCenter = (selectedGroupIds, currentCostCenter, oldCostCenter) => {
    let oldGroupIds = oldCostCenter?.group_ids || [];

    let elements = _.remove(selectedGroupIds, function(e){
      return _.indexOf(oldGroupIds.map(String), String(e)) >= 0
    })

    let newGroupIds = _.union(selectedGroupIds, currentCostCenter?.group_ids || [])

    return newGroupIds
  }

  const handleGroupIds = ( data ) => {
    let ids = _.map(data, "value");
    let _personSending = { ... personSending }

    _personSending.group_ids = ids;
    setPersonSending(_personSending);
  }

  const drawOptionsForGroup = () => {
    return _.map(groups, function(g){
      return { value: g.id, label: g.name }
    })
  }

  const getValuesGroupIds = () => {
    let selectedGroupIds = personSending.group_ids;

    let _groups =  _.filter(groups, function(g){
      return _.includes(selectedGroupIds.map(String), String(g.id))
    })

    return _.map(_groups, g => { return { value: g.id, label: g.name } } )
  }

  const drawCompanyCostCenterForm = () => {
    return(
      <CompanyCostCenterSelect
        companies={ companies }
        callbackHandlePersonSending={ handlePersonSending }
        canCreate= { companyCostCenterData?.canCreate || false }
        setCompanies={ setCompanies }
        companyId={ personSending?.company_id }
        costCenterId={ personSending?.cost_center_id }
        companyErrors={ personSending?.errors?.company || [] }
        costCenterErrors={ personSending?.errors?.cost_center || [] }
        hasSignedDocuments = {personSending.signed_documents.length > 0}
        addresseeName = {personSending.addressee_name}
      />
    )
  }


  const drawStartDateForm = () => {
    return(
      <Cleave
        placeholder="DD/MM/AAAA"
        options={{
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y']
        }}
        name={ getInputName(formName, 'start_date') }
        name={ getInputName(formName, 'start_date') }
        id={ getInputId(formName, 'start_date') }
        className="form-control"
        value={ personSending?.start_date || '' }
        onChange={ e => handlePersonSending('start_date', e) }
      />
    )
  }

  const drawStartDateLabel = () => {
    return(
      <p>{ personSending.start_date }</p>
    )
  }

  const drawStartDateFormOrLabel = () => {
    if(personSending.signed_documents.length > 0){
      return drawStartDateLabel()
    } else {
      return drawStartDateForm()
    }
  }

  const handleCustomField = (key, event) => {
    let _personSending = { ... personSending };
    _personSending.custom_fields[key] = event?.target?.value;
    setPersonSending(_personSending);
  }

  const drawCustomFieldHiringFolder = () => {
    if(customFieldHiringFolder.length > 0){
      return(
        <div className="row">
          { _.map(customFieldHiringFolder, function(field){
            return(
              <div className="col-md-4" key={ field.name }>
                <div className="form-group">
                  <label
                    htmlFor={ `${ formName }_custom_fields_${ field.name }`}
                  >
                    { field.label }
                  </label>
                  <input
                    type="text"
                    name={ `${ formName }[custom_fields][${ field.name }]`}
                    id={ `${ formName }_custom_fields_${ field.name }`}
                    className="form-control"
                    value={ personSending?.custom_fields[field.name] }
                    onChange={ e => handleCustomField(field.name, e) }
                  />
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  const drawOptionsForHiringCause = () => {
    return _.map(hiringCauses, function(cause){
      return { label: cause.name, value: cause.id }
    })
  }

  const getValuesHiringCauseId = () => {
    let cause = _.find(hiringCauses, function(cause){
      return String(cause.id) == String(personSending.hiring_cause_id)
    });

    return cause ? { label: cause.name, value: cause.id } : null
  }

  const drawHiringCauseForm = () => {
    return(
      <div className="form-group">
        <label
          htmlFor={ getInputId(formName, 'hiring_cause_id') }
        >
          { I18n.t('activerecord.attributes.person_sending.hiring_cause') }
        </label>

        <Select
          options={ drawOptionsForHiringCause() }
          placeholder={ '-- Seleccione Causal de contratación --' }
          value={ getValuesHiringCauseId() }
          id={ getInputId(formName, 'hiring_cause_id') }
          name={ getInputName(formName, 'hiring_cause_id') }
          onChange={ e => handlePersonSending('hiring_cause_id', { target: { value: e.value}} ) }
        />
      </div>
    )
  }

  const drawHiringCauseLabel = () => {
    return (
      <div className="form-group">
        <label htmlFor="">
          { I18n.t('activerecord.attributes.person_sending.hiring_cause') }
        </label>
        <p>{ personSending?.hiring_cause?.short_name ||  'Sin Información' }</p>
      </div>
    )
  }

  const drawHiringCauseFormOrLabel = () => {
    if(customer?.est_company && personSending.signed_documents.length > 0){
      return drawHiringCauseLabel()
    } else if(customer?.est_company && personSending.signed_documents.length == 0){
      return drawHiringCauseForm()
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        { drawCompanyCostCenterForm() }

        { /* FECHA DE INICIO Y TERMINO */}
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <FieldWrapper errors={ personSending?.errors?.start_date || [] } >
                <label
                  htmlFor={ getInputId(formName, 'start_date') }
                  className="required"
                >
                  { I18n.t('activerecord.attributes.person_sending.start_date') }
                </label>
                { drawStartDateFormOrLabel() }
              </FieldWrapper>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <FieldWrapper errors={ personSending?.errors?.end_date || [] } >
              <label
                  htmlFor={ getInputId(formName, 'end_date')}>
                  { I18n.t('activerecord.attributes.person_sending.end_date') }
                </label>

                <Cleave
                  placeholder="DD/MM/AAAA"
                  options={{
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y']
                  }}
                  name={ getInputName(formName, 'end_date') }
                  name={ getInputName(formName, 'end_date') }
                  id={ getInputId(formName, 'end_date') }
                  className="form-control"
                  value={ personSending?.end_date || '' }
                  onChange={ e => handlePersonSending('end_date', e) }
                />
              </FieldWrapper>
            </div>
          </div>
        </div>

        { /* CODIGO Y SALARIO */}
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <FieldWrapper errors={ personSending?.errors?.code || [] } >
                <label htmlFor={ getInputId(formName, 'code') }>
                  { I18n.t('activerecord.attributes.person_sending.code') }
                </label>

                <input
                  type="text"
                  name={ getInputName(formName, 'code') }
                  id={ getInputId(formName, 'code') }
                  className="form-control"
                  value={ personSending?.code || '' }
                  placeholder="123456789"
                  onChange={ e => handlePersonSending('code', e) }
                />
              </FieldWrapper>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <FieldWrapper errors={ personSending?.errors?.basic_salary || [] } >
                <label
                  htmlFor={ getInputId(formName, 'basic_salary') }>
                  { I18n.t('activerecord.attributes.person_sending.basic_salary') }
                </label>

                <Cleave
                  placeholder="1.000.000"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalMark: ',',
                    delimiter: '.'
                  }}
                  name={ getInputName(formName, 'basic_salary') }
                  id={ getInputId(formName, 'basic_salary') }
                  className="form-control"
                  value={ personSending?.basic_salary || '' }
                  onChange={ e => handlePersonSending('basic_salary', e) }
                />
              </FieldWrapper>
            </div>
          </div>
        </div>

        { drawHiringCauseFormOrLabel() }

        { drawCustomFieldHiringFolder() }

        { /* GRUPOS */}
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label
                htmlFor={ getInputId(formName, 'group_ids') }>
                { I18n.t('activerecord.attributes.person_sending.group_ids') }
              </label>

              <Select
                options={ drawOptionsForGroup() }
                isMulti
                placeholder={ '-- Seleccione Grupos --' }
                value={ getValuesGroupIds() }
                onChange={ e => handleGroupIds(e) }
                id={ getInputId(formName, 'group_ids') }
                name={ `${ formName }[group_ids][]` }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonSendingForm;
