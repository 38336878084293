import React, { useReducer } from 'react';
import DocumentFormContext from './context';

const DocumentFormProvider = props => {
  return(
    <DocumentFormContext.Provider
      value={{
        contextType: props?.contextType || 'public',
        data: props?.data || {},
        dataCustomer: props?.dataCustomer || {},
        enableFAO: props?.enableFAO
      }}
    >
      { props?.children }
    </DocumentFormContext.Provider>
  )
}

export default DocumentFormProvider;
