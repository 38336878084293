/** @format */

import axios from "axios";

export const saveDocumentType = (data, callback) => {
  let method, url;

  if (data.get("document_type[id]")) {
    method = "patch";
    url = `/document_types/${data.get("document_type[id]")}.json`;
  } else {
    method = "post";
    url = "/document_types.json";
  }

  var promise = axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(err.response);
    });
};
