import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import ShowNotification from "../show_notification";

const FlowButton = ({ flow_status_code, authentication_headers }) => {
  const [loading, setLoading] = useState(false);

  const handlePayment = () => {
    setLoading(true);

    axios
      .post(
        `${window.location.href}/create_payment`,
        {},
        { authentication_headers }
      )
      .then(response => {
        ShowNotification({
          message: "Estamos generando su orden de pago!",
          type: "success"
        });

        var { url, token } = response.data.data;
        var _paymentUrl = `${url}?token=${token}`;
        window.location.href = _paymentUrl;
      })
      .catch(error => {
        setLoading(false);
        ShowNotification({
          message: error.message,
          type: "error"
        });
      });
  };

  const renderPayBtn = () => {
    return (
      <button
        type="button"
        onClick={handlePayment}
        disabled={loading}
        className="btn btn-primary mx-1"
        data-toggle="tooltip"
        data-placement="top"
        title={I18n.t("purchases.flow.create_payment")}
      >
        {loading ? "Procesando Pago" : "Paga Con FLOW"}
        {loading ? (
          <i className="fas fa-spinner fa-pulse ml-2"></i>
        ) : (
          <i className="fas fa-shopping-bag ml-2"></i>
        )}
      </button>
    );
  };

  const status = flow_status_code ? flow_status_code.en : null;
  switch (status) {
    case "paid":
      return (
        <div className="alert alert-success" role="alert">
          <b>El pago se ha procesado con éxito.</b>
        </div>
      );
    case "rejected":
      return (
        <div className="text-center">
          <div className="alert alert-danger" role="alert">
            <b>
              El pago ha sido rechazado, no se le ha aplicado ningun cargo a su
              cuenta.
            </b>
          </div>
          {renderPayBtn()}
        </div>
      );
    default:
      return renderPayBtn();
  }
};

export default FlowButton;
