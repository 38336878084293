/**
 * File generated by js-routes 2.0.7
 * Based on Rails 5.2.8.1 routes of BaseProject::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const ModuleReferences = {
        CJS: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            define: (routes) => (module.exports = routes),
            support: () => typeof module === "object",
        },
        AMD: {
            define: (routes) => 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            define([], function () {
                return routes;
            }),
            support: () => typeof define === "function" && !!define.amd,
        },
        UMD: {
            define: (routes) => {
                if (ModuleReferences.AMD.support()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.support()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            support: () => ModuleReferences.AMD.support() || ModuleReferences.CJS.support(),
        },
        ESM: {
            define: () => null,
            support: () => true,
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const DeprecatedGlobbingBehavior = false;
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !object[this.configuration.special_options_key] &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, function (str) {
                return encodeURIComponent(str);
            });
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return DeprecatedGlobbingBehavior ? result : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        has_location() {
            return this.is_not_nullable(window) && !!window.location;
        }
        current_host() {
            if (this.has_location()) {
                return window.location.hostname;
            }
            else {
                return null;
            }
        }
        current_protocol() {
            if (this.has_location() && window.location.protocol !== "") {
                return window.location.protocol.replace(/:$/, "");
            }
            else {
                return "http";
            }
        }
        current_port() {
            if (this.has_location() && window.location.port !== "") {
                return window.location.port;
            }
            else {
                return "";
            }
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].support();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            if (!name) {
                return;
            }
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.namespace(Root, null, result);
    if ("ESM") {
        Utils.define_module("ESM", result);
    }
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /users/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /purchases/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_purchase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/blocked_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_blocked_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocked_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blocked_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_blocked_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocked_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/purchases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_purchase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/purchases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_purchases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/signers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"signers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_uploads/adp_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const adp_documents_document_bulk_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_uploads"],[2,[7,"/"],[2,[6,"adp_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/company_businesses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_company_business_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"company_businesses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/company_businesses/:company_business_id/company_cost_centers(.:format)
 * @param {any} company_business_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_company_business_company_cost_centers_path = __jsr.r({"company_business_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"company_businesses"],[2,[7,"/"],[2,[3,"company_business_id"],[2,[7,"/"],[2,[6,"company_cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/company_businesses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_company_businesses_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"company_businesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/document_bulk_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_document_bulk_requests_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"document_bulk_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/document_bulk_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_document_bulk_uploads_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"document_bulk_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/person_sendings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_person_sending_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/person_sendings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_person_sendings_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"person_sendings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/sms/totem_pin_code_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_sms_totem_pin_code_requests_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[6,"totem_pin_code_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/batteries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_batteries_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"batteries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/batteries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_battery_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"batteries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/bulk/document/signs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_bulk_document_signs_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"signs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_companies_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_company_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/company/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_company_users_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/cost_centers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_cost_center_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"cost_centers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_cost_centers_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/document_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_document_categories_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"document_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_document_type_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_document_types_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_documents_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/ecert/signature_certificates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_ecert_signature_certificate_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/ecert/signature_certificates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_ecert_signature_certificates_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/hiring_folders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_hiring_folder_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"hiring_folders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/hiring_folders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_hiring_folders_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"hiring_folders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/memberships/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_membership_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_memberships_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id/notification_subscribers/:notification_subscriber_id(.:format)
 * @param {any} id
 * @param {any} notification_subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_notification_subscriber_path = __jsr.r({"id":{"r":true},"notification_subscriber_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notification_subscribers"],[2,[7,"/"],[2,[3,"notification_subscriber_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id/notification_subscribers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_notification_subscribers_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notification_subscribers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/people(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_people_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"people"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/people/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_person_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/signer_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_signer_notifications_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"signer_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/signer_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_signer_types_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"signer_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_templates_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_users_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authenticated_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /admin/admin_users/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blocked_emails/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_blocked_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocked_emails"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/documents/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/purchases/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_purchases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/signers/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /batteries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batteries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"batteries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /batteries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const battery_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"batteries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /billing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_path = __jsr.r({}, [2,[7,"/"],[6,"blazer"]]);

/**
 * Generates rails route to
 * /blazer/queries/run(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_cancel_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_tables_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"tables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/schema(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_schema_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"schema"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/docs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_docs_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"docs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_query_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id/run(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_checks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_check_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboards_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_dashboard_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"blazer"]],[7,"/"]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_api_v2_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /blocked_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blocked_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blocked_emails"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /bulk/document/signs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_document_sign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /bulk/document/signs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_document_signs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"signs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /lang/:locale(.:format)
 * @param {any} locale
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_locale_path = __jsr.r({"locale":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lang"],[2,[7,"/"],[2,[3,"locale"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/confirm_lock_or_unlock_user_account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_lock_or_unlock_user_account_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirm_lock_or_unlock_user_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contact_forms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_forms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cost_centers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cost_centers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cost_centers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/documents/create_consent_annex(.:format)
 * @param {any} person_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_consent_annex_person_documents_path = __jsr.r({"person_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"create_consent_annex"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /purchases/:id/create_payment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_payment_purchase_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signer_notifications/:document_id/:signer_id/signer_notifications(.:format)
 * @param {any} document_id
 * @param {any} signer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_signer_notifications_path = __jsr.r({"document_id":{"r":true},"signer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signer_notifications"],[2,[7,"/"],[2,[3,"document_id"],[2,[7,"/"],[2,[3,"signer_id"],[2,[7,"/"],[2,[6,"signer_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/custom_brand(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_brand_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"custom_brand"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_fields/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /dashboard_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/disable_custom_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_custom_pin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"disable_custom_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/disable_two_factor_authentication(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_two_factor_authentication_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable_two_factor_authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /distribution_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"distribution_lists"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_assignments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_bulk_assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_assignments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_bulk_assignments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_assignments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /document_bulk_requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_bulk_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_bulk_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_requests"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /document_bulk_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_bulk_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_uploads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_bulk_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_uploads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v1/person_sendings/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_types_api_v1_person_sendings_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/person_sendings/:id/document_type/:document_type_id/documents(.:format)
 * @param {any} person_id
 * @param {any} id
 * @param {any} document_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_by_type_person_person_sending_path = __jsr.r({"person_id":{"r":true},"id":{"r":true},"document_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"document_type"],[2,[7,"/"],[2,[3,"document_type_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_api_v2_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution_lists/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_distribution_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_assignments/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_document_bulk_assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_assignments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /person_bulk_assignments/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_person_bulk_assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"person_bulk_assignments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/person_sendings/:id/download(.:format)
 * @param {any} person_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_person_person_sending_path = __jsr.r({"person_id":{"r":true},"id":{"r":true},"format":{"d":"zip"}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /purchases/:id/download_receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_receipt_purchase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/bases/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_report_basis_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"bases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecert_signature_certificate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecert_signature_certificates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blocked_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_blocked_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blocked_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/purchases/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_purchase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customers/edit_adp_config(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_adp_config_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"edit_adp_config"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id/notification_subscribers/:notification_subscriber_id/edit(.:format)
 * @param {any} id
 * @param {any} notification_subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_v2_notification_subscriber_path = __jsr.r({"id":{"r":true},"notification_subscriber_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notification_subscribers"],[2,[7,"/"],[2,[3,"notification_subscriber_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /batteries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_battery_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"batteries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/edit_brand_template(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_brand_template_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_brand_template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customers/edit_branding(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_branding_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"edit_branding"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bulk/document/signs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_bulk_document_sign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution_lists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_distribution_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /document_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecert_signature_certificate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /groups/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/person_sendings/:id/edit(.:format)
 * @param {any} person_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_person_person_sending_path = __jsr.r({"person_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tours/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tour_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tours"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/enable_custom_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_custom_pin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"enable_custom_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/enable_two_factor_authentication(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_two_factor_authentication_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enable_two_factor_authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /people/export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_people_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /person_sendings/export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_person_sendings_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[6,"export"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /faq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const faq_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"faq"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /firma-avanzada(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const firma_avanzada_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"firma-avanzada"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /document_bulk_assignments/generate_template_preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_template_preview_document_bulk_assignments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_assignments"],[2,[7,"/"],[2,[6,"generate_template_preview"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_uploads/get_person_sendings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_person_sendings_document_bulk_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_uploads"],[2,[7,"/"],[2,[6,"get_person_sendings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /groups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"groups"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id/lock_or_unlock_user_account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lock_or_unlock_user_account_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lock_or_unlock_user_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memberships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /my_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_documents"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/plans/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/purchases/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_purchase_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id/notification_subscribers/new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_api_v2_notification_subscriber_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notification_subscribers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /batteries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_battery_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"batteries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bulk/document/signs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_bulk_document_sign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/documents/new_consent_annex(.:format)
 * @param {any} person_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_consent_annex_person_documents_path = __jsr.r({"person_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new_consent_annex"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contact_forms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_contact_form_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /custom_fields/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_custom_field_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /distribution_lists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_distribution_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"distribution_lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_assignments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_document_bulk_assignment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_assignments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_requests/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_document_bulk_request_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_requests"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_bulk_uploads/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_document_bulk_upload_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_bulk_uploads"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_document_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new_document(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_document_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new_document"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecert_signature_certificate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /groups/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memberships/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_membership_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /people/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_person_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /person_bulk_assignments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_person_bulk_assignment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"person_bulk_assignments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/person_sendings/new(.:format)
 * @param {any} person_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_person_person_sending_path = __jsr.r({"person_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /purchases/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_purchase_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report/documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/expired_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_expired_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"expired_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/expired_person_sendings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_expired_person_sending_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"expired_person_sendings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/people/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_person_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/person_sendings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_person_sending_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_signer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tours/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tour_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tours"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /normative(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const normative_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"normative"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /purchases/:id/payment_confirmation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_confirmation_purchase_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"payment_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /excel_reports/pending_signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_signers_excel_reports_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"excel_reports"],[2,[7,"/"],[2,[6,"pending_signers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /people(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const people_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"people"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v1/document_bulk_uploads/people_information(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const people_information_api_v1_document_bulk_uploads_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"document_bulk_uploads"],[2,[7,"/"],[2,[6,"people_information"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /person_bulk_assignments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_bulk_assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"person_bulk_assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /person_bulk_assignments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_bulk_assignments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"person_bulk_assignments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/person_sendings/:id(.:format)
 * @param {any} person_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_person_sending_path = __jsr.r({"person_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/person_sendings(.:format)
 * @param {any} person_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_person_sendings_path = __jsr.r({"person_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"person_sendings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /person_sendings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_sendings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"person_sendings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id/preview_brand_template(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_brand_template_company_path = __jsr.r({"id":{"r":true},"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview_brand_template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /privacy_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /purchases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /purchases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"purchases"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/performance
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_path = __jsr.r({}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]]);

/**
 * Generates rails route to
 * /rails/performance/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_path = __jsr.r({}, [2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]]);

/**
 * Generates rails route to
 * /rails/performance/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_requests_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/crashes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_crashes_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"crashes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/recent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_recent_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"recent"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/slow(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_slow_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"slow"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/trace/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_trace_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"trace"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/performance/summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_summary_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"summary"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/sidekiq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_sidekiq_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"sidekiq"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/delayed_job(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_delayed_job_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"delayed_job"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/grape(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_grape_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"grape"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/rake(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_rake_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"rake"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/custom(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_custom_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"custom"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/controldoc/homes/receive_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receive_user_api_v1_controldoc_homes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"controldoc"],[2,[7,"/"],[2,[6,"homes"],[2,[7,"/"],[2,[6,"receive_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/bases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_bases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"bases"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report/expired_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_expired_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"expired_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report/expired_person_sendings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_expired_person_sendings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"expired_person_sendings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report/people(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_people_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"people"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report/person_sendings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_person_sendings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"person_sendings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/reset_signature_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_signature_pin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"reset_signature_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/review_block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_block_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/review_bulk(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_bulk_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"review_bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/document_bulk_requests/:id/review_bulk_request(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_bulk_request_api_v1_document_bulk_request_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"document_bulk_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_bulk_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/document_bulk_uploads/:id/review_bulk_upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_bulk_upload_api_v1_document_bulk_upload_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"document_bulk_uploads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_bulk_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /custom_fields/:id/review_destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_destroy_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution_lists/:id/review_destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_destroy_distribution_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/review_destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_destroy_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /groups/:id/review_destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_destroy_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:id/review_destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_destroy_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:id/review_destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_destroy_person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_id/person_sendings/:id/review_destroy(.:format)
 * @param {any} person_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_destroy_person_person_sending_path = __jsr.r({"person_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_id"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/review_reject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_reject_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/review_upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const review_upload_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"review_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /api
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_api_path = __jsr.r({}, [2,[7,"/"],[6,"api"]]);

/**
 * Generates rails route to
 * /api
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_ui_path = __jsr.r({}, [2,[7,"/"],[6,"api"]]);

/**
 * Generates rails route to
 * /documents/:id/save_block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_block_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"save_block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/save_bulk_sign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_bulk_sign_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"save_bulk_sign"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/save_bulk_upload(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_bulk_upload_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"save_bulk_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/save_document(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_document_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"save_document"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/save_reject(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_reject_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"save_reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/save_upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_upload_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"save_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tours/:id/seen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const seen_tour_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tours"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"seen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signers/send_email_require_identity_verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_email_require_identity_verification_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"send_email_require_identity_verification"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/send_pin_code(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_pin_code_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_pin_code"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/send_pin_code(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_pin_code_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"send_pin_code"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/send_signature_pin_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_signature_pin_token_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"send_signature_pin_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/users/send_totem_pin_code_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_totem_pin_code_email_api_v2_users_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"send_totem_pin_code_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/show_ecert_document(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_ecert_document_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_ecert_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /documents/:id/sign(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id/signature(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signature_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/person_sendings/signer_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signer_types_api_v1_person_sendings_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"person_sendings"],[2,[7,"/"],[2,[6,"signer_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /switch_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const switch_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"switch_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /switch_user/remember_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const switch_user_remember_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"switch_user"],[2,[7,"/"],[2,[6,"remember_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"templates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /thanks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const thanks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"thanks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tours/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tour_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tours"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tours(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tours_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tours"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unauthenticated_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /block_email_requests/unsubscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsubscribe_block_email_requests_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"block_email_requests"],[2,[7,"/"],[2,[6,"unsubscribe"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customers/update_adp_config(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_adp_config_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"update_adp_config"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/update_brand_template(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_brand_template_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_brand_template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customers/update_branding(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_branding_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"update_branding"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/documents/:id/upload_ecert_document(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_ecert_document_api_v2_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_ecert_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /purchases/:id/upload_receipt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_receipt_purchase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /documents/:id/validate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/users/validate_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_pin_api_v2_users_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"validate_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /people/without_hiring(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const without_hiring_people_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"without_hiring"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /person_bulk_assignments/workers_payroll(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const workers_payroll_person_bulk_assignments_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"person_bulk_assignments"],[2,[7,"/"],[2,[6,"workers_payroll"],[1,[2,[8,"."],[3,"format"]]]]]]]);
