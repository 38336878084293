import axios from "axios";

export const generateTemplatePreview = (data, callback) => {
  var promise = axios({
    method: "post",
    url: "/document_bulk_assignments/generate_template_preview",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      callback(err.response);
    });
};
