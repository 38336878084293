import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const EcertCertificateErrors = ({ ecertCertificate }) => {
  const { enrolled, ecert_errors } = ecertCertificate;

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const getBadgeColor = () => {
    if (!enrolled && ecert_errors.length > 0) {
      return "danger";
    } else {
      return "success";
    }
  };

  const getBadgeTitle = () => {
    if (!enrolled && ecert_errors.length > 0) {
      return "Hubieron errores al intentar enrolar el usuario!";
    } else {
      return "Usuario enrolado exitosamente!";
    }
  };

  return (
    <>
      <Badge
        color={getBadgeColor()}
        title={getBadgeTitle()}
        pill
        href="#"
        size="sm"
        className="ml-1 small"
        onClick={toggleModal}
      >
        ECERT
      </Badge>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal} className="bg-danger text-white">
          <i className="fas fa-signature" /> Errores
        </ModalHeader>
        <ModalBody>
          <ul>
            {ecert_errors.map(error => (
              <li key={`error-${error}`}>{error}</li>
            ))}
          </ul>
          <Alert color="info">
            Por favor corrija los errores y vuelva a intentar enrolar el
            usuario!
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} color="secondary" outline>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EcertCertificateErrors;
