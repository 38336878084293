import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { Alert, Button, Col, Row } from "reactstrap";
import { enableCustomPin } from "./Axios";

const CustomPinForm = ({ customPinEnabled, token }) => {
  const [pin, setPin] = useState();
  const [pinConfirmation, setPinConfirmation] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (!/^\d+$/.test(pin)) {
      setErrorMessage("El PIN ingresado debe ser numérico.");
      return;
    } else if (!pin || pin.length < 6) {
      setErrorMessage("El PIN ingresado debe contener 6 dígitos.");
      return;
    } else if (pin !== pinConfirmation) {
      setErrorMessage("La confimación del PIN no coincide.");
      return;
    }

    let formData = new FormData();
    formData.append("user[signature_pin]", pin);
    formData.append("user[signature_pin_confirmation]", pinConfirmation);
    if (token) {
      formData.append("validation", token);
    }

    enableCustomPin(customPinEnabled, formData, response => {
      if (response.status == 200) {
        window.location.href = Routes.edit_users_path();
      }
    });
  };

  return (
    <>
      <Row>
        <Col md="12">
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <h3 className="h6 text-left mb-3 font-wight-lighter">
            <strong>Paso 1: </strong>
            Ingrese su PIN personalizado de 6 dígitos.
          </h3>
          <Row>
            <Col md="12">
              <ReactCodeInput
                type="password"
                fields={6}
                autoFocus={false}
                onChange={e => setPin(e)}
                value={pin}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="12">
          <h3 className="h6 text-left mb-3 font-wight-lighter">
            <strong>Paso 2: </strong>
            Confirme su PIN personalizado de 6 dígitos.
          </h3>
          <Row>
            <Col md="12">
              <ReactCodeInput
                type="password"
                fields={6}
                autoFocus={false}
                onChange={e => setPinConfirmation(e)}
                value={pinConfirmation}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          md="12"
          sm="12"
          className="text-center"
        >
          <Button
            type="button"
            color={customPinEnabled ? "danger" : "info"}
            onClick={handleSubmit}
          >
            {`${customPinEnabled ? "Deshabilitar" : "Habilitar"} verificación en dos pasos`}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CustomPinForm;
