import React, { useState, useEffect, useReducer } from "react";
// import Cleave from "cleave.js/react";
import Select from "react-select";
import { UncontrolledTooltip } from "reactstrap";

import {
  getInputId,
  getInputName,
  dateTimeFormat,
  dateFormat,
} from "../commons/form";
import { handleGroupByDocumentTypeHelper } from "../groups/helper";
import FieldWrapper from "../commons/FiledWrapper";
import SignerList from "../signers/list";

import DocumentFormProvider from "./form/provider";
import { save } from "./form/axios";

const DocumentForm = props => {
  // Customer
  const { documentTypes } = props?.dataCustomer || {};

  const { contextType } = props || {};

  const formName = props?.formName || "document";

  const [document, setDocument] = useState(props?.document || {});
  const [savingForm, setSavingForm] = useState(false);

  const file = props?.document.file;

  useEffect(() => {
    let _document = { ...document };

    _.each(_document.signers, function (signer) {
      if (!_.has(signer, "uniqueId")) {
        signer["uniqueId"] = _.uniqueId("signer_key_");
      }
    });

    setDocument(_document);
  }, [document.signers]);

  useEffect(() => {
    if (contextType == "public") {
      let _document = { ...document };
      _document.signature_required = true;
      setDocument(_document);
    }
  }, []);

  const handleDocument = (key, event) => {
    let _document = { ...document };

    let value = event.target.value;

    if (event.target.type == "checkbox") {
      value = event.target.checked;
    } else if (event.target.type == "file") {
      const fileName = event.target.files[0].name.split(".")[0];
      value = event.target.files[0];
      _document.document_type_name = fileName;
    }

    _document[key] = value;

    if (key == "upload_required" && value) {
      _document.signature_required = false;
      _document.signers_order_required = false;
      _document.signature_expires_required = false;
      _document.signers = destroyAllSigners();
      _document.fea_required = false;
    } else if (key == "signature_required") {
      _document.upload_required = false;
      if (!value) {
        _document.signers = destroyAllSigners();
      }
    } else if (key == "document_type_id") {
      _document = handleDocumentType(_document, value);
    } else if (key == "fea_required" && value) {
      _document.fea_required = true;
      _document.signature_required = true;
      _document.upload_required = false;
      _document.signers_order_required = true;
    } else if (key == "signers_order_required" && !value) {
      _document.fea_required = false;
    }

    setDocument(_document);
  };

  const getDocumentTypeById = id => {
    return _.find(props?.dataCustomer?.documentTypes, { id: parseInt(id) });
  };

  // Start Customer
  const handleDocumentType = (_document, documentTypeId) => {
    let _documentType = getDocumentTypeById(documentTypeId);
    _document.for_client = _documentType.for_client || false;

    if (documentTypeId !== document.document_type_id) {
      _document.signature_required =
        _documentType.default_signers_order || false;
      _document.signers_order_required =
        _documentType?.default_signers_order || false;
    }

    let groupIds = handleGroupByDocumentTypeHelper(
      _document.group_ids,
      getDocumentTypeById(documentTypeId),
      getDocumentTypeById(document.document_type_id)
    );

    _document.group_ids = groupIds;

    return _document;
  };

  // End Customer

  // StartDraw
  const drawLinkFile = () => {
    if (document.id) {
      let filename = _.truncate(file.filename, {
        length: 20,
        separator: "...",
      });
      return (
        <a href={file.url} target="_blank" className="ml-2">
          filename
        </a>
      );
    }
  };

  const drawDocumentTypeSelect = () => {
    if (contextType != "public") {
      return (
        <div className="form-group">
          <FieldWrapper errors={document?.errors?.document_type || []}>
            <label htmlFor={getInputId(formName, "document_type_id")}>
              {I18n.t("activerecord.attributes.document.document_type")}
            </label>

            <select
              name={getInputName(formName, "document_type_id")}
              id={getInputId(formName, "document_type_id")}
              className="form-control"
              value={document?.document_type_id || ""}
              onChange={e => handleDocument("document_type_id", e)}
            >
              <option value=""></option>
              {_.map(documentTypes, function (type, index) {
                return (
                  <option value={type.id} key={`document-type-list-${type.id}`}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          </FieldWrapper>
        </div>
      );
    }
  };

  const drawFileInput = () => {
    return (
      <div className="col-md-6">
        <div className="form-group">
          <FieldWrapper errors={document?.errors?.file || []}>
            <label htmlFor={getInputId(formName, "file")}>
              {I18n.t("activerecord.attributes.document.file")}
            </label>

            {drawLinkFile()}

            <input
              type="file"
              name={getInputName(formName, "file")}
              id={getInputId(formName, "file")}
              className="form-control"
              onChange={e => handleDocument("file", e)}
            />
          </FieldWrapper>
        </div>
      </div>
    );
  };

  const drawDocumentTypeNameInput = () => {
    return (
      <div className="col-md-6">
        <div className="form-group">
          <FieldWrapper errors={document?.errors?.document_type_name || []}>
            <label htmlFor={getInputId(formName, "document_type_name")}>
              {I18n.t("activerecord.attributes.document.document_type_name")}
            </label>

            <input
              type="text"
              name={getInputName(formName, "document_type_name")}
              id={getInputId(formName, "document_type_name")}
              className="form-control"
              value={document?.document_type_name || ""}
              onChange={e => handleDocument("document_type_name", e)}
            />
          </FieldWrapper>
        </div>
      </div>
    );
  };

  const drawExpiresAtInput = () => {
    if (contextType == "public" || contextType == "person") {
      return (
        <div className="form-group">
          <FieldWrapper errors={document?.errors?.expires_at || []}>
            <label htmlFor={getInputId(formName, "expires_at")}>
              {I18n.t("activerecord.attributes.document.expires_at")}
            </label>

            <input
              type="date"
              name={getInputName(formName, "expires_at")}
              id={getInputId(formName, "expires_at")}
              className="form-control"
              value={document?.expires_at || ""}
              onChange={e => handleDocument("expires_at", e)}
            />
          </FieldWrapper>
        </div>
      );
    }
  };

  const drawUploadRequiredSwitch = () => {
    if (contextType == "person") {
      return (
        <div className="col">
          <div className="custom-control custom-switch mb-2">
            <input
              type="hidden"
              name={getInputName(formName, "upload_required")}
              value="0"
            />
            <input
              type="checkbox"
              className="custom-control-input"
              value="1"
              name={getInputName(formName, "upload_required")}
              id={getInputId(formName, "upload_required")}
              checked={document?.upload_required == true}
              onChange={e => handleDocument("upload_required", e)}
            />
            <label
              className="custom-control-label"
              htmlFor={getInputId(formName, "upload_required")}
            >
              {I18n.t("activerecord.attributes.document.upload_required")}
            </label>
          </div>
        </div>
      );
    }
  };

  const drawSignatureRequiredSwitch = () => {
    if (contextType != "public") {
      return (
        <div className="col">
          <div className="custom-control custom-switch mb-2">
            <input
              type="hidden"
              name={getInputName(formName, "signature_required")}
              value="0"
            />
            <input
              type="checkbox"
              className="custom-control-input"
              value="1"
              name={getInputName(formName, "signature_required")}
              id={getInputId(formName, "signature_required")}
              checked={document?.signature_required == true}
              onChange={e => handleDocument("signature_required", e)}
            />
            <label
              className="custom-control-label"
              htmlFor={getInputId(formName, "signature_required")}
            >
              {I18n.t("activerecord.attributes.document.signature_required")}
            </label>
          </div>
        </div>
      );
    }
  };

  const drawFeaRequiredSwitch = () => {
    if (
      contextType !== "person" &&
      document?.signature_required &&
      props?.enableFAO
    ) {
      return (
        <div className="col">
          <div className="custom-control custom-switch mb-2">
            <input
              type="hidden"
              name={getInputName(formName, "fea_required")}
              value="0"
            />
            <input
              type="checkbox"
              className="custom-control-input"
              value="1"
              name={getInputName(formName, "fea_required")}
              id={getInputId(formName, "fea_required")}
              checked={document?.fea_required == true}
              onChange={e => handleDocument("fea_required", e)}
            />
            <label
              className="custom-control-label d-inline"
              htmlFor={getInputId(formName, "fea_required")}
            >
              {I18n.t("activerecord.attributes.document.fea_required")}
              <p className="d-inline small text-primary">
                Antes de utilizar FAO consulte los precios con nuestra área de
                ventas, ya que esto tendrá un costo adicional en su factura. Si
                ya ha comprado un plan de FAO, puede omitir este aviso.
              </p>
            </label>

            <span>
              <i
                className="far fa-question-circle text-warning ml-1"
                style={{ fontSize: 25, cursor: "pointer" }}
                id="FEATooltip"
              ></i>
              <UncontrolledTooltip placement="right" target="FEATooltip">
                Si activa esta opción el orden de firma es obligatorio!
              </UncontrolledTooltip>
            </span>
          </div>
        </div>
      );
    }
  };

  const drawSignersOrderRequiredSwitch = () => {
    if (document.signature_required) {
      return (
        <div className="col">
          <div className="custom-control custom-switch mb-2">
            <input
              type="hidden"
              name={getInputName(formName, "signers_order_required")}
              value="0"
            />
            <input
              type="checkbox"
              className="custom-control-input"
              value="1"
              name={getInputName(formName, "signers_order_required")}
              id={getInputId(formName, "signers_order_required")}
              checked={document?.signers_order_required == true}
              onChange={e => handleDocument("signers_order_required", e)}
            />
            <label
              className="custom-control-label"
              htmlFor={getInputId(formName, "signers_order_required")}
            >
              {I18n.t(
                "activerecord.attributes.document.signers_order_required"
              )}
            </label>
          </div>
        </div>
      );
    }
  };

  const drawSignatureExpiresRequiredSwitch = () => {
    if (document.signature_required) {
      return (
        <div className="col">
          <div className="custom-control custom-switch mb-2">
            <input
              type="hidden"
              name={getInputName(formName, "signature_expires_required")}
              value="0"
            />
            <input
              type="checkbox"
              className="custom-control-input"
              value="1"
              name={getInputName(formName, "signature_expires_required")}
              id={getInputId(formName, "signature_expires_required")}
              checked={document?.signature_expires_required == true}
              onChange={e => handleDocument("signature_expires_required", e)}
            />
            <label
              className="custom-control-label"
              htmlFor={getInputId(formName, "signature_expires_required")}
            >
              {I18n.t(
                "activerecord.attributes.document.signature_expires_required"
              )}
            </label>
          </div>
        </div>
      );
    }
  };

  const drawSignatureExpiresAt = () => {
    if (document.signature_expires_required) {
      return (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <FieldWrapper
                errors={document?.errors?.signature_expires_at || []}
              >
                <label htmlFor={getInputId(formName, "signature_expires_at")}>
                  {I18n.t(
                    "activerecord.attributes.document.signature_expires_at"
                  )}
                </label>

                <input
                  type="datetime-local"
                  name={getInputName(formName, "signature_expires_at")}
                  id={getInputId(formName, "signature_expires_at")}
                  className="form-control"
                  value={document?.signature_expires_at || ""}
                  onChange={e => handleDocument("signature_expires_at", e)}
                />
              </FieldWrapper>
            </div>
          </div>
        </div>
      );
    }
  };

  const drawSignerList = () => {
    if (document.signature_required) {
      return (
        <div className="row">
          <div className="col">
            {drawSignerListError()}

            <SignerList
              signerTypes={props?.dataCustomer?.signerTypes}
              document={document}
              handleSigners={handleSigners}
              formName={`${formName}[signers_attributes]`}
            />
          </div>
        </div>
      );
    }
  };

  const drawSignerListError = () => {
    if (_.has(document.errors, "signers")) {
      return (
        <div className="alert alert-danger">
          <ul className="mb-0">
            {_.map(document.errors.signers, function (error, index) {
              return <li key={`signer-error-${index}`}>{error} </li>;
            })}
          </ul>
        </div>
      );
    }
  };

  // EndDraw

  // StartHandle
  const handleSigners = signers => {
    let _document = { ...document };
    _document.signers = signers;
    setDocument(_document);
  };

  const destroyAllSigners = () => {
    let _document = { ...document };

    _.each(_document.signers, signer => {
      signer._destroy = true;
    });

    return _document.signers;
  };

  // EndHandle

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${formName}[id]`, document?.id || "");

    if (!document.upload_required || !_.isEmpty(document.file)) {
      formData.append(`${formName}[file]`, document?.file || "");
    }

    formData.append(
      `${formName}[document_type_id]`,
      document?.document_type_id || ""
    );
    formData.append(`${formName}[expires_at]`, document?.expires_at || "");
    formData.append(
      `${formName}[fea_required]`,
      document?.fea_required || false
    );
    formData.append(`${formName}[for_client]`, document?.for_client || false);
    formData.append(
      `${formName}[fea_required]`,
      document?.fea_required || false
    );

    formData.append(`${formName}[person_id]`, document?.person_id || "");
    formData.append(
      `${formName}[person_sending_id]`,
      document?.person_sending_id || ""
    );
    formData.append(
      `${formName}[signature_expires_at]`,
      document?.signature_expires_at || ""
    );
    formData.append(
      `${formName}[signature_expires_required]`,
      document?.signature_expires_required || false
    );
    formData.append(
      `${formName}[signature_required]`,
      document?.signature_required || ""
    );
    formData.append(
      `${formName}[signers_order_required]`,
      document?.signers_order_required || false
    );
    formData.append(
      `${formName}[upload_required]`,
      document?.upload_required || false
    );
    formData.append(
      `${formName}[document_type_name]`,
      document?.document_type_name || ""
    );

    _.each(document.signers, function (signer, index) {
      formData.append(
        `${formName}[signers_attributes][${index}][id]`,
        signer?.id || ""
      );
      formData.append(
        `${formName}[signers_attributes][${index}][signer_type_id]`,
        signer?.signer_type_id || ""
      );
      formData.append(
        `${formName}[signers_attributes][${index}][email]`,
        signer?.email || ""
      );
      formData.append(
        `${formName}[signers_attributes][${index}][order]`,
        signer?.order || ""
      );
      formData.append(
        `${formName}[signers_attributes][${index}][label]`,
        signer?.label || ""
      );
      formData.append(
        `${formName}[signers_attributes][${index}][_destroy]`,
        signer?._destroy || 0
      );

      if (
        signer?.signer_type_id == 4 ||
        (signer?.signer_type_id == "4" && document.fea_required)
      ) {
        formData.append(
          `${formName}[signers_attributes][${index}][[my_user_attributes][email]`,
          signer?.email || ""
        );
        formData.append(
          `${formName}[signers_attributes][${index}][number]`,
          signer?.number || ""
        );
        formData.append(
          `${formName}[signers_attributes][${index}][first_name]`,
          signer?.first_name || ""
        );
        formData.append(
          `${formName}[signers_attributes][${index}][first_surname]`,
          signer?.first_surname || ""
        );
        formData.append(
          `${formName}[signers_attributes][${index}][last_surname]`,
          signer?.last_surname || ""
        );
        formData.append(
          `${formName}[signers_attributes][${index}][identification_number]`,
          signer?.identification_number || ""
        );
      }
    });

    _.each(document.group_ids, function (group_id, index) {
      formData.append(`${formName}[group_ids][]`, group_id);
    });

    return formData;
  };

  const handleSubmit = () => {
    let formData = getFormData();
    const newDocument = { ...document };
    newDocument.errors = {};
    setDocument(newDocument);
    setSavingForm(true);

    let endpoint = {
      url: Routes.documents_path({ format: "json" }),
      method: "POST",
    };

    if (document.code) {
      endpoint = {
        url: Routes.document_path(document.code, { format: "json" }),
        method: "PUT",
      };
    }

    save(endpoint, formData, response => {
      if (response.status == 200 || response.status == 201) {
        switch (contextType) {
          case "person":
            window.location.href = Routes.person_path(
              response?.data?.person_id
            );
            break;
          case "company":
            window.location.href = Routes.documents_users_path();
            break;
          default:
            window.location.href = Routes.my_documents_path();
            break;
        }
      } else if (response.status == 422) {
        let _document = { ...document };
        _document.errors = response.data;

        _.each(_document.signers, function (_signer, index) {
          if (_.has(response.data, `signers[${index}].email`)) {
            _signer.errors = {
              email: response.data[`signers[${index}].email`],
            };
          }
        });

        setDocument(_document);
        setSavingForm(false);
      }
    });
  };

  // Start Group
  const drawOptionsGroupIds = () => {
    return _.map(props?.dataCustomer?.groups, function (group) {
      return {
        value: group.id,
        label: group.name,
      };
    });
  };

  const drawValuesGroupIds = () => {
    return _.filter(drawOptionsGroupIds(), function (option) {
      return document.group_ids.map(String).includes(String(option.value));
    });
  };

  const getGroupById = id => {
    return _.find(props?.dataCustomer?.groups, { id: parseInt(id) });
  };

  const drawGroupSelect = () => {
    if (contextType !== "public") {
      return (
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor={getInputId(formName, "group_ids")}>
                {I18n.t("activerecord.attributes.document.group_ids")}
              </label>

              {drawGroupSelectOrList()}
            </div>
          </div>
        </div>
      );
    }
  };

  const drawGroupSelectOrList = () => {
    if (
      getDocumentTypeById(document?.document_type_id)?.group_exclusivity ||
      false
    ) {
      return (
        <ul>
          {_.map(document?.group_ids, function (id) {
            let _group = getGroupById(id);
            return (
              <li key={_group.id}>
                {_group.name}
                <input
                  type="hidden"
                  name={`${formName}[group_ids][]`}
                  value={_group.id}
                />
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <Select
          options={drawOptionsGroupIds()}
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="-- Seleccione Grupos --"
          name={`${formName}[group_ids][]`}
          value={drawValuesGroupIds()}
          onChange={value => handleGroupIds(value)}
          id={getInputId(formName, "group_ids")}
          isDisabled={
            getDocumentTypeById(document?.document_type_id)
              ?.group_exclusivity || false
          }
        />
      );
    }
  };

  const handleGroupIds = data => {
    let groupIds = _.map(data, "value");

    let _document = { ...document };
    _document.group_ids = groupIds;
    setDocument(_document);
  };
  // End Group

  const drawSubmitButton = () => {
    return (
      <button
        className="btn btn-success mt-3"
        type="button "
        onClick={handleSubmit}
        disabled={savingForm}
      >
        {savingForm
          ? I18n.t("actions.saving")
          : I18n.t("helpers.submit.submit", { model: "Documento" })}
      </button>
    );
  };

  return (
    <DocumentFormProvider
      contextType={props?.contextType || "public"}
      data={props?.data || {}}
      dataCustomer={props?.dataCustomer || {}}
      enableFAO={props?.enableFAO}
    >
      <div className="row">
        <div className="col-12">
          {drawDocumentTypeSelect()}
          <div className="row">
            {drawFileInput()}
            {drawDocumentTypeNameInput()}
          </div>

          {drawExpiresAtInput()}

          <div className="row">
            {drawUploadRequiredSwitch()}
            {drawSignatureRequiredSwitch()}
          </div>

          <div className="row">
            {drawFeaRequiredSwitch()}
            {drawSignersOrderRequiredSwitch()}
            {drawSignatureExpiresRequiredSwitch()}
          </div>

          {drawSignatureExpiresAt()}

          {drawSignerList()}

          {drawGroupSelect()}
        </div>

        <div className="col-12 text-right">{drawSubmitButton()}</div>
      </div>
    </DocumentFormProvider>
  );
};

export default DocumentForm;
