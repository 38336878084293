import axios from "axios";

export const saveAdpConfig = (data, callback) => {
  var promise = axios({
    method: "patch",
    url: "/customers/update_adp_config.json",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
