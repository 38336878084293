/** @format */

import React, { useRef, memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import Select from "react-select";
import { Button, Col, Input, Label, Row } from "reactstrap";

const style = {
  border: "1px dashed gray",
  cursor: "move",
};

const SignerForm = ({
  signer,
  signerIndex,
  handleMoveSigner,
  companySigners,
  handleUpdateSigner,
  handleRemoveSigner,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = signerIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      handleMoveSigner(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", id: signer.order, index: signerIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      className="card bg-light mb-3 px-3 p-3"
      ref={ref}
      style={{ ...style, opacity }}
    >
      <Row>
        <Col md="12">
          <span className="text-muted">{signerIndex + 1}° Firma</span>
        </Col>
      </Row>
      {signer.type === "person" ? (
        <div>Trabajador - {signer.rol}</div>
      ) : (
        <Row>
          <Col md="5">
            <Label>Correo electrónico de quién autoriza</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={_.find(
                companySigners,
                (cs) => cs.value === signer.email,
              )}
              name="document_category"
              options={companySigners}
              placeholder="Seleccione una opción"
              onChange={(e) => handleUpdateSigner(signerIndex, "email", e)}
            />
          </Col>
          <Col md="5">
            <Label>Rol</Label>
            <Input
              value={signer.rol}
              onChange={(e) => handleUpdateSigner(signerIndex, "rol", e)}
            />
          </Col>
          <Col md="2">
            <Button
              tpye="button"
              size="sm"
              color="link"
              className="text-dark float-right"
              style={{ marginTop: "-40px" }}
              onClick={() => handleRemoveSigner(signerIndex)}
            >
              <i className="fas fa-times h6" />
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default memo(SignerForm);
