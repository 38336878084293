import React, { useState, useEffect, useContext, useRef } from "react";

import { useDrag, useDrop } from "react-dnd";

import FieldWrapper from "../commons/FiledWrapper";
import { getInputId, getInputName } from "../commons/form";

import DocumentFormContext from "../documents/form/context";

const SignerForm = (props) => {
  const {
    contextType,
    dataCustomer,
    enableFAO
  } = useContext(DocumentFormContext)

  const formName = props?.formName;
  const document = props?.document;

  const index = props?.index;

  const orderRequiredStyle = {
    border: "1px dashed gray",
    cursor: "move",
  };

  const [signer, setSigner] = useState(props?.signer || {} )

  const signerTypeOptions = _.filter(dataCustomer?.signerTypes, function(signerType){
    return signerType.name == 'Empresa' || signerType.name == 'Externo'
  })

  const currentSignerType = _.find(dataCustomer?.signerTypes, signerType => {
    return signerType.id == signer.signer_type_id
  })

  useEffect(() => {
    setSigner(props?.signer);
  }, [props?.signer])

  // Customer
  const drawSignerTypeSelect = () => {
    if(contextType !== 'person' && contextType != 'public'){
      return(
        <div className="col">
          <div className="form-group">
            <FieldWrapper errors={ signer?.errors?.signer_type || [] } >
              <label htmlFor={ getInputId(formName, "signer_type_id") }>
                { I18n.t("activerecord.attributes.signer.signer_type") }
              </label>

              <select
                name={ getInputName(formName, "signer_type_id") }
                id={ getInputId(formName, "signer_type_id") }
                className="form-control"
                onChange={ e => handleSigner("signer_type_id", e)}
                value={ signer?.signer_type_id || "" }
              >
                <option></option>
                { _.map(signerTypeOptions, function(signerType, index){
                  return(
                    <option
                      key={ `signerTypeOption-${ signerType.id }` }
                      value={ signerType.id }
                    >
                      { signerType.name }
                    </option>
                  )
                })}
              </select>
            </FieldWrapper>
          </div>
        </div>
      )
    }
  }

  const drawPersonEmail = () => {
    return (
      <div className="col">
        <label htmlFor="">Correo electrónico personal del Trabajador</label>

        <p>{ signer?.email }</p>
      </div>
    );
  }

  const drawCompanySignerSelect = () => {
    return(
      <div className="col">
        <div className="form-group">
          <FieldWrapper errors={ signer?.errors?.email || [] }>
            <label htmlFor={ getInputId(formName, "email") }>
              { drawCompanySignerEmailLabel() }
            </label>

            <select
              name={ getInputName(formName, "email") }
              id={ getInputId(formName, "email") }
              className="form-control"
              value={ signer?.email || "" }
              onChange={ e => handleSigner("email", e) }
            >
              <option value=""></option>
              { drawCompanySignerOptions() }
            </select>
          </FieldWrapper>
        </div>
      </div>
    )
  }

  const drawLabelExternalSigner = () => {
    if(contextType == 'public'){
      return I18n.t("activerecord.attributes.signer.signer")
    } else {
      return I18n.t("activerecord.attributes.signer.external_signer")
    }
  }

  const drawExternalSignerInput = () => {
    return(
      <div className="col">
        <div className="form-group">
          <FieldWrapper errors={ signer?.errors?.email || [] }>
            <label htmlFor={ getInputId(formName, "email") } className='required'>
              { drawLabelExternalSigner() }
            </label>

            <input
              type="email"
              className="form-control"
              placeholder="Correo Electrónico"
              aria-label="Correo Electrónico"
              aria-describedby="addon"
              onChange={ e => handleSigner("email", e) }
              value={ signer?.email || "" }
              id={ getInputId(formName, "email") }
              name={ getInputName(formName, "email") }
              required
            />
          </FieldWrapper>
        </div>
      </div>
    )
  }

  const drawCompanySignerEmailLabel = () => {
    if (contextType === "person") {
      return I18n.t("activerecord.attributes.signer.company_signer");
    } else {
      return I18n.t("activerecord.attributes.signer.internal_signer");
    }
  }

  const drawCompanySignerOptions = () => {
    if(document.fea_required){
      let companyEnrolled = _.filter(dataCustomer?.companySigners, signer => signer.ecert_enrolled)

      return _.map(companyEnrolled, function(signer, index){
        return(
          <option
            value={ signer.email }
            key={ `signer-option-${ signer.id }` }
          >
            { `${ signer.name } (${ signer.email })` }
          </option>
        )
      })
    } else {
      return _.map(dataCustomer?.companySigners, function(signer, index){
        return(
          <option
            value={ signer.email }
            key={ `signer-option-${ signer.id }` }
          >
            { `${ signer.name } (${ signer.email })` }
          </option>
        )
      })
    }
  }
  // EndCustomer

  const drawLabelInput = () => {
    if(currentSignerType?.name != 'Trabajador'){
      return(
        <div className="col-12">
          <div className="form-group">
            <FieldWrapper errors={ signer?.erros?.label || [] }>
              <label htmlFor={ getInputId(formName, "label") }>
                { I18n.t("activerecord.attributes.signer.label") }
              </label>
              <input
                type="text"
                name={ getInputName(formName, "label") }
                id={ getInputId(formName, "label") }
                className="form-control"
                value={ signer?.label || "" }
                onChange={ e => handleSigner("label", e) }
              />
            </FieldWrapper>

            <small className="text-muted">
              { I18n.t("documents.form.label_information") }
            </small>
          </div>
        </div>
      )
    }
  }

  const drawEmailInput = () => {
    if(currentSignerType?.name == 'Trabajador'){
      return drawPersonEmail();
    } else if (currentSignerType?.name == 'Empresa') {
      return drawCompanySignerSelect()
    } else if(currentSignerType?.name == 'Externo'){
      return drawExternalSignerInput()
    }
  }

  const drawDestroyButton = () => {
    return(
      <div className="col-12 text-right">
        <button
          type="button"
          className="btn btn-outline-danger btn-sm"
          onClick={ e => handleDestroySigner() }
        >
          <i className="fas fa-user-times"></i>
          &nbsp; Eliminar
        </button>
      </div>
    )
  }

  const drawOrder = () => {
    if(document.signers_order_required){
      return (
        <div className="col-6">
          <span className="text-muted">
            {`${ parseInt(signer.order) + 1 } ° Firma`}
          </span>
        </div>
      );
    }
  }

  const drawFeaInputs = () => {
    if((contextType == 'public' || (contextType == 'company' && currentSignerType?.name == 'Externo')) && document.fea_required){
      return(
        <div className='col-12'>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <FieldWrapper errors={ signer?.errors?.identification_number || [] } >
                  <label
                    className="required"
                    htmlFor={ getInputId(formName, "identification_number") }
                  >
                    RUT
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="RUT"
                      aria-label="RUT"
                      aria-describedby="addon"
                      onChange={ e => handleSigner("identification_number", e) }
                      value={ signer?.identification_number || "" }
                      id={ getInputId(formName, "identification_number") }
                      name={ getInputName(formName, "identification_number") }
                      required
                    />
                  </div>
                </FieldWrapper>
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <FieldWrapper errors={ signer?.errors?.first_name || [] }>
                  <label
                    className="required"
                    htmlFor={ getInputId(formName, "first_name") }
                  >
                    Nombre
                  </label>

                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nombre"
                      aria-label="Nombre"
                      aria-describedby="addon"
                      onChange={ e => handleSigner("first_name", e) }
                      value={ signer?.first_name || "" }
                      id={ getInputId(formName, "first_name") }
                      name={ getInputName(formName, "first_name") }
                      required
                    />
                  </div>
                </FieldWrapper>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <FieldWrapper errors={ signer?.errors?.first_surname || [] }>
                  <label
                    className="required"
                    htmlFor={ getInputId(formName, "first_surname") }
                  >
                    Primer Apellido
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Primer Apellido"
                      aria-label="Primer Apellido"
                      aria-describedby="addon"
                      onChange={ e => handleSigner("first_surname", e) }
                      value={ signer?.first_surname || "" }
                      id={ getInputId(formName, "first_surname") }
                      name={ getInputName(formName, "first_surname") }
                      required
                    />
                  </div>
                </FieldWrapper>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <FieldWrapper errors={ signer?.errors?.last_surname || [] }>
                  <label
                    className="required"
                    htmlFor={ getInputId(formName, "last_surname") }
                  >
                    Segundo Apellido
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Segundo Apellido"
                      aria-label="Segundo Apellido"
                      aria-describedby="addon"
                      onChange={ e => handleSigner("last_surname", e) }
                      value={ signer?.last_surname || "" }
                      id={ getInputId(formName, "last_surname") }
                      name={ getInputName(formName, "last_surname") }
                      required
                    />
                  </div>
                </FieldWrapper>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const handleSigner = (key, event) => {
    let value = event?.target?.value;

    let _signer = { ... signer };

    if(key == 'require_identity_verification' && value && _.isFunction(props?.callbackOpenRequireIdentityVerificationModal)){
      props?.callbackOpenRequireIdentityVerificationModal(true)
      value = false;
    }

    _signer[key] = value

    if(_.isFunction(props?.handleSigner)){
      props.handleSigner(_signer);
    }
  }

  const handleDestroySigner = () => {
    let _signer = { ... signer };
    _signer._destroy = 1;

    if(_.isFunction(props?.handleSigner)){
      props.handleSigner(_signer);
    }
  }

  // MOVE
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'signer-card',
    hover(item, monitor){
      if(!ref.current || item.index === index){
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if(_.isFunction(props?.handleMoveSigner)){
        item.index = hoverIndex
        props.handleMoveSigner(dragIndex, hoverIndex)
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'signer-card',
      id: signer.order,
      index: index
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(drop(ref));

  // END MOVE

  const drawRequireIdentityVerification = () => {
    if(enableFAO == false){
      return(
        <div className={`${document.signers_order_required ? "col-6 d-flex justify-content-end" : "col-12 d-flex justify-content-end" }`}>
          <div className="custom-switch">
            <input
              className="custom-control-input signs_require_identity_verification"
              type="checkbox"
              onChange={ e => handleSigner("require_identity_verification", e) }
              checked={ signer.require_identity_verification || false }
              id={ `${ getInputId(formName, `require_identity_verification`) }` }
              name={ getInputName(formName, "require_identity_verification") }
              value="1"
              style={{ display: "contents" }}
              // disabled={ isPersisted }
              gtm-customer-id={ dataCustomer?.currentCustomer?.id || '' }
              gtm-user-id={ dataCustomer?.currentUser?.id || '' }
            />

            <label
              className="custom-control-label"
              htmlFor={ getInputId(formName, `require_identity_verification`) }
            >
              <i className="fas fa-lock gradient-text-4t mr-2"></i>
              { I18n.t("activerecord.attributes.signer.require_identity_verification") }
              <span className="badge badge-primary ms-2 bg-gradient-4t">{ I18n.t('actions.new') }</span>
            </label>
          </div>
        </div>
      )
    }
  }

  return(
    <div
      className={ `row mb-2 signer-card ${ signer._destroy ? 'd-none' : '' }`}
      ref={ ref }
    >
      <div className="col-12">
        <div
          className="card"
          style={ document.signers_order_required ? orderRequiredStyle : {} }
        >
          <div className="card-body">
            <div className="row">
              { drawOrder() }
              { drawRequireIdentityVerification() }
            </div>
            <div className="row">
              { drawSignerTypeSelect() }
              { drawEmailInput() }
              { drawFeaInputs() }
              { drawLabelInput() }
              { drawDestroyButton() }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}


export default SignerForm;
