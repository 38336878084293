import React, { Fragment, useEffect, useState, useRef } from "react";
import Select from "react-select";
import { Document, Page, pdfjs } from "react-pdf";
import { Label } from "reactstrap";
import _ from "lodash";
import BounceLoader from "react-spinners/BounceLoader";
import { generateTemplatePreview } from "./Axios";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentBulkAssignmentPreview = ({ documentBulkData, data }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [document, setDocument] = useState(documentBulkData.file);
  const [loading, setLoading] = useState(false)
  const [person, setPerson] = useState(null)

  const documentRef = useRef(null)

  const { isTemplate, selectedByPeople, selectedByDistributionList, template } =
    documentBulkData;

  useEffect(() => {
    setDocument(documentBulkData.file);
  }, [documentBulkData.file]);

  useEffect(() => {
    setDocument(null)
    setPerson(null)
  }, [documentBulkData.documentType, documentBulkData.template])

  const handleSubmitTemplatePreview = (data) => {
    setDocument(null)
    setLoading(true)
    setPerson(data)
    documentRef.current.renderLoader
    let formData = new FormData();
    formData.append("template", template);
    formData.append("object_id", data.value);
    formData.append("selected_by_people", documentBulkData.selectedByPeople)

    generateTemplatePreview(formData, (response) => {
      setDocument(response.data)
      setLoading(false)
    });
  };

  const renderPagination = () => {
    return (
      <div>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                type="button"
                className="page-link"
                disabled={pageNumber == 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                aria-label="Anterior"
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="ml-1">Anterior</span>
              </button>
            </li>

            <li className="page-item">
              <button
                type="button"
                className="page-link"
                disabled={pageNumber == numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                aria-label="Siguiente"
              >
                <span className="mr-1">Siguiente</span>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
        <div className="text-center text-primary">
          <small>
            Página {pageNumber} de {numPages}
          </small>
        </div>
      </div>
    );
  };

  const drawOptionsPeopleSelect = () => {
    if (!_.isEmpty(documentBulkData.personSigners)) {
      return documentBulkData.personSigners;
    } else {
      return _.map(data?.people || [], (person) => {
        return { label: `${person.name}`, value: person.id };
      });
    }
  };

  const renderPeopleSelect = () => {
    return (
      <Fragment>
        <Label>Seleccione un Trabajador para previsualizar la Plantilla</Label>
        <Select
          options={drawOptionsPeopleSelect()}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="-- Seleccione un Trabajador --"
          onChange={handleSubmitTemplatePreview}
          value={person}
        />
      </Fragment>
    );
  };

  const drawOptionsDistributionListSelect = () => {
    if (!_.isEmpty(documentBulkData.distributionLists)) {
      return documentBulkData.distributionLists;
    } else {
      return _.map(data?.distributionLists, function (distributionList) {
        return {
          label: distributionList?.name,
          value: distributionList?.id,
        };
      });
    }
  };

  const renderDistributionListSelect = () => {
    return (
      <Fragment>
        <Label>
          Seleccione una Lista de Distribución para previsualizar la Plantilla
        </Label>
        <Select
          options={drawOptionsDistributionListSelect()}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="-- Seleccione un Lista de Distribución --"
          onChange={handleSubmitTemplatePreview}
        />
        <p className="text-muted small mt-1">
          Al seleccionar una lista de distribución se creará una
          previsualización de la plantilla con el primer trabajador en la lista.
        </p>
      </Fragment>
    );
  };

  const drawNoDataText = () => {
    if (!isTemplate) return "Debe seleccionar un documento";
    if (isTemplate && selectedByPeople)
      return "Debe seleccionar una plantilla y un trabajador.";
    if (isTemplate && selectedByDistributionList)
      return "Debe seleccionar una plantilla y una lista de distribución.";
  };

  return (
    <div>
      {isTemplate && selectedByPeople && renderPeopleSelect()}
      {isTemplate &&
        selectedByDistributionList &&
        renderDistributionListSelect()}
      <div className="text-center">
        <BounceLoader loading={loading} size={50} />
      </div>
      <Document
        ref={documentRef}
        className="text-center mb-3 mt-5 ml-5"
        file={document}
        onLoadSuccess={(params) => setNumPages(params.numPages)}
        noData={drawNoDataText()}
        error="No se puede previsualizar el documento adjuntado."
      >
        <Page pageNumber={pageNumber} />
      </Document>

      <div className="d-flex justify-content-center align-items-center mt-5">
        {renderPagination()}
      </div>
    </div>
  );
};

export default DocumentBulkAssignmentPreview;
