import React from "react";
import { Alert, Col, Label, Row } from "reactstrap";
import _ from "lodash";

const DocumentBulkAssignmentInformation = ({ documentBulkData, data }) => {
  const {
    documentType,
    isTemplate,
    template,
    file,
    selectedByPeople,
    selectedByDistributionList,
    onlyForPerson,
    onlyForCompany,
    companySigners,
    personSigners,
    distributionLists,
    consent,
    exclude,
    groups,
    expiresAt,
    signatureExpiresRequired,
    signatureExpiresAt,
    signersList,
    signersOrderRequired
  } = documentBulkData;

  const drawSelectedList = () => {
    if (selectedByPeople && _.isEmpty(personSigners)) {
      return <p>Todos</p>;
    } else if (selectedByPeople && personSigners.length == data.people.length) {
      return <p>Todos</p>;
    } else if (selectedByPeople) {
      return (
        <ul>
          {_.map(personSigners, (signer) => {
            return <li key={signer.value}>{signer.label}</li>;
          })}
        </ul>
      );
    } else if (selectedByDistributionList && _.isEmpty(distributionLists)) {
      return <p>Todos</p>;
    } else if (
      selectedByDistributionList &&
      distributionLists.length == data.distributionLists.length
    ) {
      return <p>Todos</p>;
    } else if (selectedByDistributionList) {
      return (
        <ul>
          {_.map(distributionLists, (list) => {
            return <li key={list.value}>{list.label}</li>;
          })}
        </ul>
      );
    }
  };

  const drawSignersList = () => {
    if (
      _.isEmpty(signersList)
    ) {
      return <p>Debe seleccionar firmantes de la empresa!</p>;
    } else {
      return (
        <ul>
          {_.map(signersList, (signer, index) => {
            return <li key={signer.value}>{signersOrderRequired ? `${index + 1}° Firma -` : null} {signer.label} - {signer.rol || "Firmante"}</li>;
          })}
        </ul>
      );
    }
  };

  return (
    <div>
      <Alert color="warning">Por favor revise la información ingresada!</Alert>
      <Row>
        <Col className="mt-2" md="6">
          <label className="label-bold">Tipo de Documento</label>
          <div>{documentType || "Debe seleccionar un tipo de documento"}</div>
        </Col>
        <Col className="mt-2" md="6">
          <label className="label-bold">Usar Plantilla</label>
          <div>{isTemplate ? "Si" : "No"}</div>
        </Col>
        {isTemplate ? (
          <Col className="mt-2" md="6">
            <label className="label-bold">Plantilla</label>
            <div>{template || "Debe seleccionar una plantilla"}</div>
          </Col>
        ) : (
          <Col className="mt-2" md="6">
            <label className="label-bold">Documento a enviar</label>
            <div>{file?.name || "Debe adjuntar un documento"}</div>
          </Col>
        )}
        <Col className="mt-2" md="6">
          <label className="label-bold">Fecha de Vencimiento</label>
          <div>{expiresAt || "Sin fecha de vencimiento"}</div>
        </Col>
        <Col className="mt-2" md="6">
          <label className="label-bold">Seleccionado por (Trabajadores o Lista de Distribución)</label>
          <div>
            {selectedByPeople && "Trabajadores"}
            {selectedByDistributionList && "Lista de Distribución"}
          </div>
        </Col>
        <Col className="mt-2" md="6">
          <label className="label-bold">
            {selectedByPeople && "Trabajadores Seleccionados"}
            {selectedByDistributionList &&
              "Listas de Distribución Seleccionadas"}
          </label>
          <div>{drawSelectedList()}</div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2" md="6">
          <label className="label-bold">Firma solo el Trabajador</label>
          <div>{onlyForPerson ? "Si" : "No"}</div>
        </Col>
        <Col className="mt-2" md="6">
          <label className="label-bold">Firma solo el Empleador</label>
          <div>{onlyForCompany ? "Si" : "No"}</div>
        </Col>
      </Row>
      <Row>
        {!onlyForPerson && !onlyForCompany && (
          <Col md="6">
            <Label className="label-bold">Requiere un orden de firma?</Label>
            <div>{signersOrderRequired ? "Si" : "No"}</div>
          </Col>
        )}
        {!onlyForPerson && (
          <Col md="6">
            <label className="label-bold">Firmantes del Documento</label>
            <div>{drawSignersList()}</div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="6">
          <Label className="label-bold">Requiere fecha y hora límite para firmar?</Label>
          <div>{signatureExpiresRequired ? "Si" : "No"}</div>
        </Col>
        {signatureExpiresRequired && (
          <Col>
            <Label className="label-bold">Fecha y hora límite para firmar</Label>
            <div>{signatureExpiresAt || "Debe ingresar una fecha y hora límite para firmar."}</div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md="6">
          <label className="label-bold">
            Excluir a quienes ya tienen este documento?
          </label>
          <div>{exclude ? "Si" : "No"}</div>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="label-bold">Grupos Seleccionados</label>
          <div>
            {_.isEmpty(groups) ? (
              "Ninguno"
            ) : (
              <ul>
                {_.map(groups, (group) => (
                  <li key={group.value}>{group.label}</li>
                ))}
              </ul>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DocumentBulkAssignmentInformation;
