import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import FieldWrapper from "../commons/FiledWrapper";
import ButterToast, { Cinnamon, POS_BOTTOM, POS_RIGHT } from "butter-toast";
import { saveAdpConfig } from "./Axios";

const AdpConfigForm = ({ customer }) => {
  const [adpHost, setAdpHost] = useState(customer.adp_host || "");
  const [adpKey, setAdpKey] = useState("");
  const [adpPort, setAdpPort] = useState(customer.adp_port || "");
  const [adpUser, setAdpUser] = useState(customer.adp_user || "");
  const [errors, setErrors] = useState({});

  const handleSubmit = e => {
    e.preventDefault();
    let data = new FormData();
    data.append("customer[adp_host]", adpHost);
    data.append("customer[adp_key]", adpKey);
    data.append("customer[adp_user]", adpUser);
    data.append("customer[adp_port]", adpPort);

    saveAdpConfig(data, response => {
      if (response.status === 200) {
        const icon = <i className="fas fa-check" />;

        ButterToast.raise({
          content: (
            <Cinnamon.Crisp
              scheme={Cinnamon.Crisp.SCHEME_BLUE}
              content={() => <span>Configuración guardada exitosamente.</span>}
              title="Excelente!"
              icon={icon}
            />
          ),
        });
        setErrors({});
      } else if (response.status === 422) {
        setErrors(response.data);
      }
    });
  };

  return (
    <Container>
      <div>
        <ButterToast
          position={{ vertical: POS_BOTTOM, horizontal: POS_RIGHT }}
        />
      </div>
      <Row>
        <Col md="12">
          <Card className="bg-white mb-3">
            <CardBody>
              <Row>
                <Col lg="10" className="d-flex align-items-center">
                  <div className="d-block">
                    <h5 className="text-primary font-weight-bold mb-1">
                      <i className="fas fa-cogs mr-2" aria-hidden="true" />
                      Configuración integración ADP
                    </h5>
                  </div>
                </Col>
                <Col>
                  <div className="text-right">
                    <Button type="button" href="/" color="secondary">
                      Volver
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <p className="text-muted">
                    <i className="fas fa-info-circle mr-1" />
                    Aqui encontrará el formulario para ingresar sus credenciales
                    de integración con ADP.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <FieldWrapper errors={errors.adp_host}>
                        <Label for="adpHost" className="required label-bold">
                          Host
                        </Label>
                        <span className="text-muted small-text ml-2">
                          (Ej. algo.adp.com)
                        </span>

                        <Input
                          id="adpHost"
                          type="text"
                          value={adpHost}
                          onChange={e => setAdpHost(e.target.value)}
                          className="form-control"
                        />
                      </FieldWrapper>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <FieldWrapper errors={errors.adp_port}>
                        <Label for="adpPort" className="required label-bold">
                          Puerto
                        </Label>
                        <Input
                          id="adpPort"
                          type="number"
                          value={adpPort}
                          onChange={e => setAdpPort(e.target.value)}
                          className="form-control"
                        />
                      </FieldWrapper>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <FieldWrapper errors={errors.adp_user}>
                        <Label for="adpUser" className="required label-bold">
                          Usuario
                        </Label>
                        <Input
                          id="adpUser"
                          type="text"
                          value={adpUser}
                          onChange={e => setAdpUser(e.target.value)}
                          className="form-control"
                        />
                      </FieldWrapper>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <FieldWrapper errors={errors.adp_key}>
                        <Label for="adpKey" className="required label-bold">
                          Key
                        </Label>
                        <Input
                          id="adpKey"
                          type="file"
                          onChange={e => setAdpKey(e.target.files[0])}
                          className="form-control"
                        />
                      </FieldWrapper>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="text-center">
                    <Button type="submit">Guardar</Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdpConfigForm;
