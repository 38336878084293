import axios from "axios";

export const saveDocumentBulkReuqest = (data, callback) => {
  const promise = axios({
    method: "post",
    url: "/api/v1/document_bulk_requests",
    data: data,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};

export const reviewBulkRequest = (id, callback) => {
  const promise = axios({
    method: "put",
    url: `/api/v1/document_bulk_requests/${id}/review_bulk_request`,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
};
