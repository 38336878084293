import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Alert,
  Card,
  CardBody
} from "reactstrap";
import _ from "lodash";
import { reviewBulkRequest } from "./Axios";

const DocumentBulkRequest = ({ bulkRequest, updateBulkRequest }) => {
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState([]);

  const toggle = () => setModal(!modal);

  const {
    requestedBy,
    createdAt,
    documentType,
    bulkErrors,
    reviewed,
    expiresAt
  } = bulkRequest;

  useEffect(() => {
    if (modal && !reviewed) {
      reviewBulkRequest(bulkRequest.id, response => {
        updateBulkRequest(response.data);
      });
    }
  }, [modal]);

  return (
    <div>
      <Button
        size="sm"
        color="primary"
        onClick={toggle}
        className="mr-3 mt-md-0"
      >
        Ver
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Solicitud Masiva de Documentos
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col size="12" md="6" className="mt-3">
              <Label className="label-bold">Creado por</Label>
              <div>{requestedBy.name}</div>
            </Col>
            <Col size="12" md="6" className="mt-3">
              <Label className="label-bold">Solicitado el</Label>
              <div>{createdAt}</div>
            </Col>
          </Row>
          <Row>
            <Col size="12" md="6" className="mt-3">
              <Label className="label-bold">Tipo de Documento</Label>
              <div>{documentType.name}</div>
            </Col>
            <Col size="12" md="6" className="mt-3">
              <Label className="label-bold">Fecha de vencimiento</Label>
              <div>{documentType.expiresAt || "No ingresó"}</div>
            </Col>
          </Row>
          <hr />
          <Alert color="danger" className="text-center">
            Solicitud masiva con errores
          </Alert>
          <Card>
            <CardBody>
              <ul>
                {_.map(bulkErrors, e => (
                  <li key={e.number}>
                    {e.name} - {e.number}
                    <ul>
                      {_.map(e.errors, error => (
                        <li key={`errors-${e.number}`}>{error}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentBulkRequest;
