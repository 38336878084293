import React, { Fragment, useState, useEffect } from "react";
import CompanySelect from './CompanySelect';
import CostCenterSelect from './CostCenterSelect';

const CompanyCostCenterSelect = (
  // {
  //   // personSending,
  //   // companyList,
  //   // companyErrors,
  //   // canCreate,
  //   // hasCostCenters,
  //   // costCenterErrors,
  //   // groups,
  //   // assignGroupToCostCenter
  //   companies
  // },
  props
) => {
  const {companies, hasSignedDocuments, addresseeName} = props;
  // const [companies, setCompanies] = useState(companyList)
  // const [companyId, setCompanyId] = useState(personSending?.company_id)
  // const [costCenterId, setCostCenterId] = useState(personSending?.cost_center_id)

    const drawCompanySelect = () =>{
      if (hasSignedDocuments){
          return(
              <div className="row">
                  <div className="col-md-6">
                      <div className="form-group">
                          <label htmlFor="">
                              { I18n.t('activerecord.attributes.person_sending.addressee_company') }
                          </label>

                          <p>{ addresseeName } </p>
                      </div>
                  </div>
              </div>
          )
      }else{
          return(
              <CompanySelect
                  companies={ companies }
                  callbackHandlePersonSending={ props?.callbackHandlePersonSending }
                  canCreate={ props?.canCreate || false }
                  setCompanies={ props?.setCompanies }
                  companyId={ props?.companyId }
                  companyErrors={ props?.companyErrors }
                  // companyId={ companyId }
                  // callbackHandleCompanyId={ setCompanyId }
                  // errors={ companyErrors }
                  // canCreate={ canCreate }
                  // callbackHandleCostCenterId={ setCostCenterId }
              />
          )
      }
    }
  // let companyName = _.find(companies, function(company){
  //   return String(company.id) == companyId;
  // })?.name;

  const selectedCompany = _.find(companies, function(_company){
    return String(_company.id) == String(props?.companyId)
  })

  return(
    <div className="row">
      <div className={ "col-md-6 col-12" }>
          {drawCompanySelect()}
      </div>


      <div className={ "col-md-6 col-12" }>
        <CostCenterSelect
          canCreate={ props?.canCreate || false }
          companyId={ props?.companyId }
          costCenterId={ props?.costCenterId }
          costCenters={ selectedCompany?.cost_centers || [] }
          callbackHandlePersonSending={ props?.callbackHandlePersonSending }
          setCompanies={ props?.setCompanies }
          companies={ companies }
          costCenterErrors={ props?.costCenterErrors }
          // costCenterId={ costCenterId }
          // companyId={ companyId }
          // companyName={ companyName }
          // canCreate={ canCreate }
          // errors={ costCenterErrors }
          // callbackHandleCostCenterId={ setCostCenterId }
          // groups={ groups }
          // assignGroupToCostCenter={ assignGroupToCostCenter }
        />
      </div>
    </div>
  )
}

export default CompanyCostCenterSelect;
