import axios from "axios";

export const sendEmailRequireIdentityVerification = (formData, callback) => {
  var promise = axios({
    method: 'post',
    url: "/signers/send_email_require_identity_verification.json",
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}