import React, { useState, useEffect } from "react";

const CompanyTemplate = ({company}) => {

  const [header, setHeader] = useState(company.header_brand_template || "")
  const [footer, setFooter] = useState(company.footer_brand_template || "")

  useEffect(() => {
    initTinyMce();
  });

  const initTinyMce = () => {
    tinymce.init(tinyceConfig("#templateHeader"))
    tinymce.init(tinyceConfig("#templateFooter"))
  }

  const tinyceConfig = (selector) => {
    return {
      selector: selector,
      language: "es",
      menubar: false,
      height: '350',
      plugins: 'preview paste autolink visualblocks visualchars fullscreen image link media table charmap hr  anchor toc advlist lists  imagetools textpattern noneditable help charmap quickbars emoticons',
      menubar: 'file edit format',
      toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap | fullscreen | image ',
      menu: {
        file: { title: 'File', items: 'newdocument' },
        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat' },
      },
      init_instance_callback: function(editor){
        editor.on("Change", function(e){
          const value = e.target.getContent().replace(/&nbsp;/gi, " ");
          const element = $(e.target.targetElm).attr("data-type")

          if(element == "header"){
            setHeader(value)
          } else if(element == "footer"){
            setFooter(value)
          }
        })
      },
    }
  }


  const drawTemplate = () => {
    return(
      <div className="row">
        <div className="col-12">
          <label htmlFor="">
            { I18n.t("activerecord.attributes.company.header_brand_template")}
          </label>
          <div
            id="templateHeader"
            data-type="header"
            dangerouslySetInnerHTML={{ __html: header || "Contenido del encabezado" }}
          />
        </div>

        <div className="col-12 mt-5">
          <label htmlFor="">
            { I18n.t("activerecord.attributes.company.footer_brand_template")}
          </label>
          <div
            id="templateFooter"
            data-type="footer"
            dangerouslySetInnerHTML={{ __html: footer || "Contenido del pie de página" }}
          />
        </div>
      </div>
    )
  }

  return(
    <div className="row">
      <div className="col-12">
        <input
          type="hidden"
          name="company[header_brand_template]"
          value={ header }
        />
        <input
          type="hidden"
          name="company[footer_brand_template]"
          value={ footer }
        />
        { drawTemplate() }
      </div>
    </div>
  )
}

export default CompanyTemplate;
