import React, { useState, useEffect } from "react";

const FieldWrapper = ({ errors, children, isVisible, styleMargin }) => {
  const [componentErrors, setComponentErrors] = useState(errors);

  useEffect(() => {
    setComponentErrors(errors);
  }, [errors]);

  let display = isVisible ? "block" : isVisible == false ? "none" : "block";

  if (componentErrors && componentErrors.length > 0) {
    return (
      <div style={{ display }}>
        {children}
        <ul className="errors_list clearfix" style={styleMargin}>
          {componentErrors.map(function (error, index) {
            return <li key={index}>{error}</li>;
          })}
        </ul>
      </div>
    );
  } else {
    return <div style={{ display }}>{children}</div>;
  }
};

export default FieldWrapper;
