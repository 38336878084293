import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import _ from "lodash";
import { ThreeCircles } from "react-loader-spinner";
import SignImage from "../images/landing/sign.png";
import { savePurchase } from "./Axios";

const SignPlans = props => {
  const { plans } = props;

  const [selectedPlan, setSelectedPlan] = useState({});
  const [purchaseEmail, setPurchaseEmail] = useState("");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [hasValidId, setHasValidId] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [purchaseCreated, setPurchaseCreated] = useState(false);

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setTermsAndConditions(true);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (
      purchaseEmail.trim() == "" ||
      identificationNumber.trim() == "" ||
      address.trim() == "" ||
      buyerName.trim() == "" ||
      phoneNumber.trim() == ""
    ) {
      setError(true);
      setErrorMessage("Debe ingresar todos los datos!");
      return;
    } else if (!termsAndConditions) {
      setError(true);
      setErrorMessage("Debe aceptar los términos y condiciones!");
      return;
    } else if (!hasValidId) {
      setError(true);
      setErrorMessage("Debe declarar que posee cédula vigente y clave única!");
      return;
    }

    setSubmitting(true);
    setError(false);
    let formData = new FormData();
    formData.append("purchase[email]", purchaseEmail);
    formData.append("purchase[plan_id]", selectedPlan.id);
    formData.append("purchase[address]", address);
    formData.append("purchase[identification_number]", identificationNumber);
    formData.append("purchase[buyer_name]", buyerName);
    formData.append("purchase[terms_conditions_accepted]", termsAndConditions);
    formData.append("purchase[has_valid_id]", hasValidId);
    formData.append("purchase[phone_number]", phoneNumber);

    savePurchase(formData, response => {
      if ([201].includes(response.status)) {
        setPurchaseCreated(true);
      } else {
        console.log("Hubo un error");
      }
      setTimeout(() => {
        setSubmitting(false);
      }, 3000);
    });
  };

  const handleSelectedPlan = plan => {
    setPurchaseEmail("");
    setIdentificationNumber("");
    setAddress("");
    setBuyerName("");
    setPhoneNumber("");
    setTermsAndConditions(false);
    setHasValidId(false);
    setError(false);
    setSubmitting(false);
    setPurchaseCreated(false);
    setSelectedPlan(plan);
  };

  const drawPlans = () => {
    return _.map(plans, plan => (
      <Col md="6" lg="3" key={plan.id}>
        <Card className="text-primary text-center mb-3">
          <CardBody className="px-2">
            <h2 className="h5">
              <span className="d-block font-weight-light">Plan</span>
              {plan.name}
            </h2>
            <h3 className="h1 font-weight-bold d-flex justify-content-center align-items-center">
              <span className="h5 font-weight-bold mb-0">$</span>
              {plan.price}
            </h3>
            <p className="small">IVA Incluido</p>
            <Button
              color="secondary"
              type="button"
              data-toggle="modal"
              data-target="#offcanvasPurchase"
              aria-controls="offcanvasPurchase"
              onClick={() => handleSelectedPlan(plan)}
              className="offcanvas-purchase-btn"
            >
              Comprar
            </Button>
          </CardBody>
        </Card>
      </Col>
    ));
  };

  const drawConditionsContent = () => {
    return (
      <React.Fragment>
        <ModalHeader>
          <span className="font-weight-bold">
            TÉRMINOS Y CONDICIONES DE SERVICIO PARA USUARIOS CONTROLDOC SIGN.
          </span>
        </ModalHeader>
        <ModalBody>
          <div>
            <p>
              Lea detenidamente los presentes términos y condiciones de servicio
              (los “Términos”), pues regulan los derechos y obligaciones de
              quienes se encuentren registrados (el “Usuario” o los “Usuarios”,
              según corresponda) en la plataforma denominada ControlDoc Sign (la
              “Plataforma”), ya sea que se acceda a través de un ordenador o de
              cualquier clase de dispositivo móvil, la que es propiedad de
              CONTROLDOC SpA (en adelante, “CONTROLDOC””).
            </p>
            <p>
              Al registrarse en la Plataforma, usted acepta cumplir y someterse
              a estos Términos, los que constituyen un acuerdo jurídicamente
              vinculante entre el Usuario y CONTROLDOC. Cualquier persona que no
              acepte estos Términos deberá abstenerse de utilizar la Plataforma.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">
              I. DESCRIPCIÓN GENERAL DE LOS SERVICIOS PRESTADOS POR CONTROLDOC.
            </p>
            <p>
              La Plataforma tiene por objetivo poner a disposición de sus
              Usuarios, una plataforma virtual que les permita firmar documentos
              mediante firma electrónica avanzada de un solo uso (en adelante
              “FEA”), la cual es ofrecida en colaboración con e-certchile, uno
              de los principales proveedores del sector público y las
              instituciones privadas en el mercado de la certificación
              electrónica (en adelante, el “Servicio” o los “Servicios”, según
              corresponda).
            </p>
          </div>
          <div>
            <p className="font-weight-bold">
              II. PERSONAS QUE PUEDEN HACER USO DE LA PLATAFORMA.
            </p>
            <p>
              Para utilizar la Plataforma, el Usuario debe ser una persona
              natural mayor de edad. En ese contexto, al aceptar los presentes
              Términos, el Usuario declara que tiene al menos 18 años de edad al
              momento de acceder a la Plataforma.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">
              III. PROCESO DE REGISTRO DE USUARIOS.
            </p>
            <p>
              Para convertirse en Usuario, quienes ingresen a la Plataforma
              deben realizar un proceso de registro, lo que les permitirá crear
              una cuenta personal (la “Cuenta”) y utilizar la Plataforma en
              conformidad a los Términos expresados en el presente instrumento.
            </p>
            <p>
              Al momento de registrarse en la Plataforma, además de completar la
              información solicitada, al Usuario se le exigirá la creación de
              una clave de seguridad (el “PIN”), la cual se le solicitará para
              acceder a su Cuenta.
            </p>
            <p>
              Es responsabilidad de quien se registra proporcionar información
              veraz, exacta e íntegra respecto de todos los datos que le sean
              solicitados durante el proceso de registro. Por otra parte, será
              obligación del Usuario informar acerca de cualquier cambio
              respecto de la información entregada, que pudiera ocurrir durante
              la vigencia de su Cuenta.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">IV. TARIFA Y MÉTODO DE PAGO.</p>
            <p>
              Para efectos de utilizar la Plataforma, el Usuario deberá escoger
              entre uno de los planes anuales que se encuentra disponibles para
              la contratación de los Servicios en función de la cantidad de
              firmas o transacciones que podrá realizar durante dicho año, para
              lo cual se contemplan actualmente las siguientes opciones:
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Unidades</th>
                  <th>2</th>
                  <th>3</th>
                  <th>5</th>
                  <th>10</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Precio IVA inc</th>
                  <td>$2190</td>
                  <td>$2990</td>
                  <td>$4850</td>
                  <td>$8590</td>
                </tr>
              </tbody>
            </table>
            <p>
              Una vez que haya escogido el plan que desea contratar, el Usuario
              deberá realizar inmediatamente el pago a través de transferencia
              electrónica bancaria a la cuenta corriente bancaria de CONTROLDOC
              o bien mediante algún otro medio de pago que se encuentre
              disponible en la Plataforma.
            </p>
            <p>
              CONTROLDOC se reserva el derecho a modificar eventualmente el
              monto de las tarifas por la contratación de los planes ofrecidos
              en la Plataforma, la cantidad de firmas que comprende cada plan
              y/o los medios de pagos contemplados en la Plataforma, lo que será
              debidamente notificado a los Usuarios.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">V. UTILIZACIÓN DE LOS SERVICIOS.</p>
            <p>
              El Usuario podrá utilizar la Plataforma para firmar uno o más
              documentos, durante la cantidad de oportunidades que se contemplen
              en el plan que haya contratado, para lo cual se emitirá un
              certificado de FEA temporal previa verificación de identidad del
              Usuario, el cual quedará sin efecto una vez que el Usuario haya
              finalizado el proceso de firma del respectivo documento.
            </p>
            <p>
              El Usuario tendrá también la posibilidad de invitar a un tercero a
              firmar un documento cargado a la Plataforma por el Usuario, previa
              verificación de la identidad del respectivo tercero, ya sea que
              ese documento se firme también por el Usuario o bien solamente por
              el tercero. En caso de utilizar esta posibilidad, la firma por
              parte del tercero se descontará de la cantidad de firmas de un
              solo uso de las que dispone el Usuario en función del plan que
              haya contratado.
            </p>
            <p>
              Una vez que un documento haya sido firmado por el Usuario y/o por
              un tercero, el mismo será almacenado en la Plataforma durante un
              plazo de 1 año contado a partir de la firma del respectivo
              documento, plazo durante el cual el Usuario podrá acceder a
              visualizar y descargar una copia firmada de documento desde su
              Cuenta. Al cumplirse el referido plazo de 1 año, CONTROLDOC
              procederá a eliminar permanentemente el documento de la
              Plataforma, no siendo posible en consecuencia solicitar una copia
              de este.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">
              VI. MODIFICACIÓN DE ESTOS TÉRMINOS.
            </p>
            <p>
              CONTROLDOC se reserva el derecho a modificar los presentes
              Términos en cualquier momento, de acuerdo con lo dispuesto en este
              capítulo. Si realiza algún cambio, se publicarán en esta sección
              junto a una leyenda que así lo exprese, indicando además la fecha
              de su última actualización. Adicionalmente, se le enviará al
              Usuario una notificación a través de la cual podrá acceder al
              contenido de los nuevos Términos al correo electrónico indicado al
              momento de registrar su Cuenta, con una anticipación de 30 días
              contados desde la fecha en que entren en vigencia.
            </p>
            <p>
              En caso de que no esté conforme con los nuevos Términos, el
              Usuario podrá dejar sin efecto el presente acuerdo. Si no deja sin
              efecto este acuerdo antes de la fecha en que entren en vigencia
              los nuevos Términos, la utilización de la Plataforma constituirá
              su aceptación de los mismos para todos los efectos legales.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">VII. TRATAMIENTO DE DATOS.</p>
            <p className="font-weight-bold">i. DECLARACIÓN.</p>
            <p>
              CONTROLDOC se preocupa por la protección de los datos de carácter
              personal del Usuario. El derecho de cada Usuario para tomar
              decisiones informadas respecto al uso de sus datos es muy
              importante para nosotros. Esta política de tratamiento de datos
              explica las directrices de CONTDOLDOC sobre la recolección,
              utilización, almacenamiento, comunicación y protección de datos
              personales.
            </p>
            <p>
              Al registrarse en la Plataforma, CONTROLDOC queda autorizada para
              almacenar todos los datos personales entregados por el Usuario,
              con el único objeto de prestar correctamente los Servicios y/o
              usarlos para fines estadísticos para sí misma o sus empresas
              relacionadas.
            </p>
            <p className="font-weight-bold">
              ii. RECOLECCIÓN Y REGISTRO DE DATOS.
            </p>
            <p>
              CONTROLDOC también podrá almacenar automáticamente datos del
              Usuario cuando éste se registre en la Plataforma, a través de la
              dirección IP, el sistema operativo y/o el navegador utilizado.
            </p>
            <p>
              Igualmente, el Usuario queda informado que, al registrarse en la
              Plataforma, le otorga a CONTROLDOC una autorización indefinida,
              gratuita, tanto en Chile, como a nivel mundial, para utilizar
              dichos datos y así mantener informado al Usuario de nuevas
              promociones, ofertas, publicidad o cualquiera otra comunicación
              que CONTROLDOC considere pertinente.
            </p>
            <p className="font-weight-bold">
              iii. TRATAMIENTO DE DATOS PERSONALES.{" "}
            </p>
            <p>
              CONTROLDOC no vende, arrienda, comunica o transmite datos
              personales del Usuario a persona alguna, salvo en situaciones
              excepcionales, como en el caso en que se vea requerida en
              cumplimiento de órdenes emanadas de autoridad judicial o
              administrativa.
            </p>
            <p>
              El Usuario dispondrá en todo momento de los derechos de
              información, rectificación y cancelación de los datos personales
              conforme a la Ley Nº19.628 sobre Protección de Datos de Carácter
              Personal
            </p>
          </div>
          <div>
            <p className="font-weight-bold">VIII. PROHIBICIONES.</p>
            <p>
              Además de las prohibiciones que expresamente se contengan en otras
              secciones de los presentes Términos, se prohíbe al Usuario
              utilizar la Plataforma de cualquier forma que pudiere dañar,
              deshabilitar, afectar o sobrecargar la misma; cargar, hacer avisos
              o de cualquier otra forma, transmitir o distribuir en esta
              Plataforma cualquier ítem, incluyendo, sin que el siguiente
              listado signifique limitación alguna, virus computacionales,
              caballos troyanos, gusanos, mecanismos de apagado automático o
              cualquier otro sistema, programa o archivo disruptivo; interferir
              con la seguridad de esta Plataforma; infringir patentes
              comerciales, marcas registradas, secretos comerciales e
              industriales, derechos de publicidad o cualquier otros derechos
              propietarios de cualquier persona natural o jurídica,
              colectividades, etc.; impedir o interrumpir el uso de la
              Plataforma por parte de terceros; usar programas automáticos,
              mecanismos o procesos manuales para monitorear, copiar, resumir, o
              extraer información de cualquier otra forma desde esta Plataforma;
              usar las cuentas y claves de terceros Usuarios, o cualquier otra
              información sin el consentimiento previo y por escrito de su
              titular; crear cuentas o utilizar la Plataforma proporcionando
              datos falsos; transmitir SPAM, cadenas, correo basura o cualquier
              otro tipo de correo masivo no solicitado; cargar, distribuir o
              diseminar desde la Plataforma, material o información amenazante,
              de acoso, difamatorio, obsceno, pornográfico, fraudulento,
              engañador, o que de cualquier otra forma pudiere ser atentatorio
              contra la ley, el orden público, la moral y las buenas costumbres
              o que transgreda o perturbe los derechos de cualquier otra
              persona, o que contenga solicitudes de recaudación de fondos,
              entre otras.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">
              IX. FUNCIONAMIENTO DE LA PLATAFORMA.
            </p>
            <p>
              CONTROLDOC no garantiza el acceso y uso continuado e
              ininterrumpido a la Plataforma. El sistema puede eventualmente no
              estar disponible debido a dificultades técnicas, fallas de
              internet, interrupción del servicio proveído por e-cert chile o a
              cualquier otra circunstancia ajena a CONTROLDOC. En tales casos,
              se procurará restablecer el sistema con la mayor celeridad
              posible, sin que por ello pueda imputársele algún tipo de
              responsabilidad.
            </p>
            <p>
              CONTROLDOC tampoco se responsabiliza por cualquier daño, perjuicio
              o pérdida al Usuario causada por fallas en la Plataforma, en el
              servidor o en internet, ni será responsable por los daños
              generados por cualquier virus introducido por un tercero que
              pudiera infectar el equipo del Usuario como consecuencia del
              acceso, uso o examen de la Plataforma o a raíz de cualquier
              transferencia de datos, archivos, imágenes, textos o audio
              contenidos en el mismo.{" "}
            </p>
            <p>
              Los Usuarios no podrán imputarle a CONTROLDOC responsabilidad
              alguna ni exigir pago por lucro cesante, en virtud de perjuicios
              resultantes de dificultades técnicas o fallas en el software, en
              los sistemas o en internet.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">
              X. ACUERDO ÚNICO Y FORMALIZACIÓN POR ESCRITO.
            </p>
            <p>
              Los presentes Términos prefieren y reemplazan a cualquier acuerdo
              previo, escrito o verbal, entre el Usuario y CONTROLDOC con
              respecto a las materias reguladas en el mismo, y se considerará
              para todos los efectos como el único acuerdo vigente entre ambos.
              Para efectos de dar cumplimiento a la Ley 19.496 sobre Protección
              de los Derechos de los Consumidores, una vez aceptados por el
              Usuario, por medios electrónicos, los presente Términos,
              CONTROLDOC se obliga a enviar al Usuario una confirmación escrita
              del hecho de haber quedado perfeccionado el acuerdo. La referida
              confirmación podrá ser enviada por vía electrónica o por cualquier
              otro medio adecuado, y deberá contener una copia íntegra, clara y
              legible de los Términos.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">
              XI. JURISDICCIÓN Y LEY APLICABLE.
            </p>
            <p>
              Este acuerdo estará regido por las leyes de la República de Chile.
              Cualquier controversia derivada del presente acuerdo, su
              existencia, validez, interpretación, alcance o cumplimiento, será
              sometida a sus leyes aplicables y a los Tribunales competentes de
              la ciudad de Santiago de Chile, y los procedimientos se llevarán a
              cabo en idioma castellano.
            </p>
          </div>
          <div>
            <p className="font-weight-bold">XII. COMUNICACIONES.</p>
            <p>
              En el caso que un Usuario tenga dudas acerca de estos Términos,
              sobre la Plataforma de los mismos, y en todo caso, cuando deba dar
              noticia o aviso relacionado, deberá ponerse en contacto con
              CONTROLDOC vía correo electrónico a la siguiente dirección:
              hola@controldoc.cl.
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={toggleNested}>
              Aceptar Términos
              <i className="fas fa-arrow-right ml-2"></i>
            </Button>
          </div>
        </ModalBody>
      </React.Fragment>
    );
  };

  const drawCartForm = () => {
    return (
      <div
        id="offcanvasPurchase"
        className="offcanvas offcanvas-end modal modal-offcanvas right"
        tabIndex={-1}
        aria-labelledby="offcanvasPurchaseLabel"
        aria-hidden="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="offcanvas-header modal-header">
              <h3
                className="h4 font-weight-bold mb-0"
                id="offcanvasPurchaseLabel"
              >
                <img
                  src={SignImage}
                  className="img-fluid mr-2"
                  alt="Controldoc SIGN"
                  title="Controldoc SIGN"
                  style={{ width: 40, height: "auto" }}
                />
                Compra Firma Avanzada
              </h3>
              <Button
                className="close navbar-toggle border-0"
                aria-label="close"
                data-dismiss="modal"
                type="button"
                color="link"
                id="offcanvasPurchaseClose"
              >
                <i className="fas fa-times fa-lg" />
              </Button>
            </div>
            <div className="offcanvas-body modal-body">
              <div className="preview-cart">
                <Card className="mb-3 material-shadow">
                  <CardBody className="bg-light">
                    <Row className="d-flex align-items-center">
                      <Col md="6">
                        <h3 className="h1 font-weight-bold d-flex justify-content-center align-items-center">
                          <span className="h5 font-weight-bold mb-0">$</span>
                          {selectedPlan.price}
                        </h3>
                      </Col>
                      <Col md="6">
                        <h3 className="h4">
                          <span className="h6 mb-0">Plan</span>
                          <span className="d-block font-weight-bold">
                            {selectedPlan.name}
                          </span>
                        </h3>
                      </Col>
                    </Row>
                    <p className="small mb-0">IVA Incluido. Vigencia un año</p>
                  </CardBody>
                </Card>
                <Form onSubmit={handleSubmit} id="purchaseRequestForm">
                  {error && <Alert color="danger">{errorMessage}</Alert>}
                  <div className="mb-3">
                    <Label
                      className="form-label required"
                      htmlFor="businessName"
                    >
                      Nombre o Razón social
                    </Label>
                    <Input
                      type="text"
                      id="businessName"
                      className="form-control"
                      onChange={e => setBuyerName(e.target.value)}
                      disabled={submitting || purchaseCreated}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label required" htmlFor="businessId">
                      RUT
                    </Label>
                    <Input
                      placeholder="11222333-4"
                      type="text"
                      id="businessId"
                      className="form-control"
                      onChange={e => setIdentificationNumber(e.target.value)}
                      disabled={submitting || purchaseCreated}
                    />
                  </div>
                  <div className="mb-3">
                    <Label
                      className="form-label required"
                      htmlFor="purchaseEmail"
                    >
                      Email para facturación
                    </Label>
                    <Input
                      type="email"
                      value={purchaseEmail}
                      id="purchaseEmail"
                      className="form-control"
                      onChange={e => setPurchaseEmail(e.target.value)}
                      disabled={submitting || purchaseCreated}
                    />
                  </div>
                  <div className="mb-3">
                    <Label
                      className="form-label required"
                      htmlFor="businessAddress"
                    >
                      Dirección completa
                    </Label>
                    <Input
                      placeholder="Calle 123, Comuna"
                      type="text"
                      id="businessAddress"
                      className="form-control"
                      onChange={e => setAddress(e.target.value)}
                      disabled={submitting || purchaseCreated}
                    />
                  </div>
                  <div className="mb-3">
                    <Label
                      className="form-label required"
                      htmlFor="phoneNumber"
                    >
                      Teléfono
                    </Label>
                    <Input
                      placeholder="+56912345678"
                      type="text"
                      id="phoneNumber"
                      className="form-control"
                      onChange={e => setPhoneNumber(e.target.value)}
                      disabled={submitting || purchaseCreated}
                    />
                  </div>
                  <div className="mb-3 form-check">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="SalesConditionsCheck"
                      onChange={e => setTermsAndConditions(e.target.checked)}
                      checked={termsAndConditions}
                      disabled={submitting || purchaseCreated}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="SalesConditionsCheck"
                    >
                      Acepto los{" "}
                      <Button
                        color="link"
                        className="p-0"
                        onClick={toggleNested}
                      >
                        términos y condiciones de venta
                      </Button>
                    </label>
                  </div>
                  <Modal
                    isOpen={nestedModal}
                    toggle={toggleNested}
                    size="lg"
                    scrollable={true}
                  >
                    {drawConditionsContent()}
                  </Modal>
                  <div className="mb-3 form-check">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="SalesEssentialsCheck"
                      onChange={e => setHasValidId(e.target.checked)}
                      checked={hasValidId}
                      disabled={submitting || purchaseCreated}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="SalesEssentialsCheck"
                    >
                      Declaro poseer clave única y cédula de identidad vigente{" "}
                      <small>
                        (Indispensable para utilizar el servicio firma
                        electrónica avanzada)
                      </small>
                    </label>
                  </div>
                  <Row className="text-center mt-3">
                    <Col>
                      {!submitting && purchaseCreated && (
                        <Alert color="success" id="purchaseRequestSuccess">
                          Listo!. Recibirás un correo con las instrucciones para
                          la confirmación del pago y creación de tu cuenta
                        </Alert>
                      )}
                      {submitting && drawSpinner()}
                      {!submitting && !purchaseCreated && (
                        <Button
                          color="primary"
                          id="purchaseRequestBtn"
                          type="submit"
                        >
                          Confirmar Compra
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const drawSpinner = () => {
    return (
      <ThreeCircles
        height="60"
        width="60"
        color="#5a3ee0e"
        wrapperStyle={{ display: "inherit" }}
        visible={true}
        ariaLabel="three-circles-rotating"
      />
    );
  };

  return (
    <>
      <section className="section bg-dark text-white pb-0 scrollify" id="plans">
        <Container>
          <Row className="d-flex align-items-center">
            <Col lg="3">
              <h2 className="h3 font-weight-bold mb-5 lg-0 text-shadow">
                Compra tu Firma Electrónica Avanzada de forma rápida y segura.
              </h2>
            </Col>
            <Col lg="9">
              <Row className="d-flex align-items-center justify-content-center">
                {drawPlans()}
                <Col md="12" className="text-center">
                  <p className="small mb-1">
                    * Precios IVA Incluido. Vigencia un año desde la activación
                    del Plan.
                  </p>
                  <p className="small mb-1">
                    Consulta términos y condiciones de venta y uso de firma
                    avanzada
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="mt-3">
          <svg viewBox="0 0 1440 209" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1,86l40-5.3C81,75,161,65,241,86s160,75,240,80,160-37,240-53.3,160-5.7,240,16S1121,182,1201,166s160-80,200-112l40-32V231H1Z"
              style={{ fill: "#ffffff", transform: "translate(-1 -22)" }}
            />
          </svg>
        </div>
      </section>
      {drawCartForm()}
    </>
  );
};

export default SignPlans;
