import axios from "axios";

export const save = (endpoint, formData, callback) => {
  var promise = axios({
    method: endpoint.method,
    url: endpoint.url,
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.ForDocs.token
    }
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(err => {
      callback(err.response);
    });
}
